import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalDiasEspeciales extends Component {

    constructor(props) {
        super(props);
        this.user = this.props.user


        this.inputDate = React.createRef();
        this.idUserRecord = React.createRef();
        this.inputValueObservacion = React.createRef();
        this.idRecord = React.createRef();
        this.inputTimeStart = React.createRef();
        this.inputTimeFinish = React.createRef();

    }

    componentDidMount() {

    }


    render() {
        return (
            <div class="modal fade" id="modalFormDiasEspeciales" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Formulario dias especiales</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <input value="" id="idUserRecord" ref={this.idUserRecord} hidden readOnly />
                            <input value="" id="idDiasEspeciales" ref={this.idRecord} hidden readOnly />
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Día</label>
                                <div class="col-8">
                                    <input class="form-control" type="date" id="inputDateGo" ref={this.inputDate} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Hora entrada</label>
                                <div class="col-8">
                                    <input class="form-control" type="time" id="hourIni" ref={this.inputTimeStart} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Hora salida</label>
                                <div class="col-8">
                                    <input class="form-control" type="time" id="hourFin" ref={this.inputTimeFinish} />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Ultima Modificación</label>
                                <div class="col-4">
                                    <input class="form-control" type="text" id="inputAdminModifier" ref={this.inputAdminModifier} readOnly />
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" id="inputDateModified" ref={this.inputDateModified} readOnly />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div>
                                <p id="msgErrorDiasEspeciales" style={{ color: 'red', display: 'none' }}>* Error, algunos campos estan vacíos, compruebe los datos</p>
                                <p id="msgErrorServidorDiasEspeciales" style={{ color: 'red', display: 'none' }}>* Error, no se han podido crear el registro</p>
                            </div>
                            <div>
                                <button type="button" id="btn-deleteMVL" class="btn btn-danger" data-dismiss="modal" onClick={this.props.deleteDiasEspeciales}>Eliminar</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="button" class="btn btn-primary" id="botonGuardarDiasEspeciales" onClick={this.props.saveNew}>Guardar cambios</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalDiasEspeciales;
