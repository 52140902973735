import React, { Component } from "react";
import ReactDOM from 'react-dom';
import DailyRecord from '../../DailyRecord'


class TableHorasAcumuladasDay extends Component {

    constructor(props) {
        super(props);
        this.calculateHours = this.calculateHours.bind(this);
        this.lista = props.param

        this.tablaRecord = React.createRef()

        this.horasTot = 0
        this.horasLab = 0
        this.horasExt = 0

    }
    componentDidMount() {
        if (Array.isArray(this.lista)) {
           this.renderizarRecords()
        }
        console.log("a " + typeof(this.horasTot))
        ReactDOM.unmountComponentAtNode(document.getElementById('totales'))
        ReactDOM.render(
            <p>{"Horas acumuladas: " + this.calculateHoursString(parseInt(this.horasTot)) + " Horas laborables: "+ this.calculateHoursString(this.horasLab) + " Horas extra: " + this.calculateHoursString(this.horasExt)} </p>,
            document.getElementById('totales')
        );

    }

    componentDidUpdate(){
        if (Array.isArray(this.lista)) {
            this.renderizarRecords()
          }

    }

    renderizarRecords() {
        const listItems = this.lista.map((r) => <DailyRecord stompClient={this.props.stompClient} users={this.props.users} item={r} admin={this.props.admin} user={this.props.users[r.iduser]} fecha={r.daterecord} hacu={this.calculateHours(r.cumulative_minutesWorked)} htrab={this.calculateHours(450)} hext={this.calculateHours(r.cumulative_minutesWorked - 450)} > </DailyRecord>);
        ReactDOM.unmountComponentAtNode(this.tablaRecord.current)
        ReactDOM.render(listItems, this.tablaRecord.current)
      }
    

    componentWillUnmount() {
    }

    calculateHoursString(minutes) {
        let negativo = false
        if (minutes < 0) {
            minutes = minutes * -1
            negativo = true
        }

        this.horasDiff = Math.floor(minutes / 60);
        this.minutosDiff = Math.floor(minutes % 60);
        if (this.minutosDiff <= 9) {

            if (negativo) {
                return "-" + this.horasDiff + "h 0" + this.minutosDiff + "m"
            }

            return this.horasDiff + "h 0" + this.minutosDiff + "m"
        }
        if (negativo) {
            return"-" + this.horasDiff +  "h " +  this.minutosDiff + "m"
        }

        return this.horasDiff + "h "+ this.minutosDiff + "m"
    }

    
    calculateHours(minutes) {
        let negativo = false
        if (minutes < 0) {
            minutes = minutes * -1
            negativo = true
        }

        this.horasDiff = Math.floor(minutes / 60);
        this.minutosDiff = Math.floor(minutes % 60);
        if (this.minutosDiff <= 9) {

            if (negativo) {
                return <th>-{this.horasDiff}h 0{this.minutosDiff}m</th>
            }

            return <th>{this.horasDiff}h 0{this.minutosDiff}m</th>
        }
        if (negativo) {
            return <th>-{this.horasDiff}h {this.minutosDiff}m</th>
        }

        return <th>{this.horasDiff}h {this.minutosDiff}m</th>
    }

    render() {
        this.lista.forEach(element => {
            this.horasTot = this.horasTot + element.cumulative_minutesWorked
            this.d = new Date(element.daterecord);
            if(this.d.getDay() != 5 || this.d.getDay() != 6){
                this.horasLab = this.horasLab + 450
            }
        });
        this.horasExt = this.horasTot - this.horasLab
        return (
            <div class="table-wrapper" id="tableDirecciones">
                <table class="table">
                    <thead class="table-dark">
                        <tr>
                            <th>Nombre</th>
                            <th>Día</th>
                            <th>Horas Acumuladas</th>
                            <th>Horas Laborables</th>
                            <th>Horas extras</th>
                        </tr>
                    </thead>
                    <tbody ref={this.tablaRecord}>
                        {/*JSON.parse(this.lista).map(item => (

                            <tr>
                                <th>{this.props.users[item.iduser]}</th>
                                <th>{item.daterecord}</th>
                                {this.calculateHours(item.cumulative_minutesWorked)}
                                {this.calculateHours(450)}
                                {this.calculateHours(item.cumulative_minutesWorked - 450)}
                            </tr>
                        ))
                        */}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableHorasAcumuladasDay;
