import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class TablaDepartamentos extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div class="table-wrapper" id="tableFestivo">
                <table class="table">
                    <thead class="table-dark">
                        <tr >
                            <th>Día</th>
                            <th>Tipo de festivo</th>
                            <th>Descripción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.param.map(item => (
                            <tr onDoubleClick={() => this.props.modifyFestivo(item)}>
                                <th style={{ width: '25%' }}>{item.dateFirstDay}</th>
                                <td style={{ width: '25%' }}>{item.reason}</td>
                                <td style={{ width: '50%' }}>{item.description}</td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TablaDepartamentos;
