import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';
import '../../css/formPersonal/DiasEspeciales.css';
import ModalDiasEspeciales from './modalesForm/ModalDiasEspeciales';

class DiasEspeciales extends Component {

    constructor(props) {
        super(props);

        this.user = this.props.user
        this.admin = this.props.adminInfo

        this.datesDefault = this.datesDefault.bind(this);
        this.daysInMonth = this.daysInMonth.bind(this);
        this.sendUserInfo = this.sendUserInfo.bind(this);
        this.returnName = this.returnName.bind(this);
        this.returnLocalDate = this.returnLocalDate.bind(this);
        this.createNewDay = this.createNewDay.bind(this)
        this.search = this.search.bind(this);
        this.changeToCustom = this.changeToCustom.bind(this);
        this.saveNewDay = this.saveNewDay.bind(this);

        this.dateTargetAfterRef = React.createRef(this);
        this.dateTargetBeforeRef = React.createRef(this);
        this.selectOption = React.createRef(this);

    }

    componentDidMount() {
        ReactDOM.render(
            <ModalDiasEspeciales user={this.props.user} adminInfo={this.props.adminInfo} saveNew={this.saveNewDay} />,
            document.getElementById('modalDiasEspeciales')
        );
        $('#modalFormDiasEspeciales').on('hide.bs.modal', function () {
            document.getElementById('btn-deleteMVL').style.display = "none"
        })

        this.subsciptionN_CR = this.props.stompClient.subscribe('/user/queue/getAllUsersNameAndIdSubscribe', this.returnName, { id: "subsciptionN_CR" });
        this.props.stompClient.send("/app/getAllUsersNameAndId", {});
    }

    createNewDay() {
        document.getElementById("msgErrorDiasEspeciales").style.display = "none"
        $('#modalFormDiasEspeciales').modal('show')

    }

    search() {
        this.subsciptionTO_OU = this.props.stompClient.subscribe('/user/queue/returnTableDiasEspecialesSubscribe', (msg) =>{
            console.log(msg.body)
        } , { id: "subsciptionTO_RTDE" });
        this.props.stompClient.send("/app/returnTableDiasEspeciales", {}, JSON.stringify({
            "iduser": this.user.id,
            "dateBefore": this.dateTargetBeforeRef.current.value,
            "dateAfter": this.dateTargetAfterRef.current.value
        }));
        this.subsciptionLD_HA.unsubscribe("subsciptionTO_RTDE");
    }

    changeToCustom() {

    }

    saveNewDay() {

        if (document.getElementById("hourIni").value == "" || document.getElementById("hourFin").value == "" || document.getElementById("inputDateGo").value == "") {
            document.getElementById("msgErrorDiasEspeciales").style.display = "block"

        } else {
            this.subsciptionTO_SNSD = this.props.stompClient.subscribe('/user/queue/saveNewSpecialDaySubscribe', (msg) => {
                
                this.subsciptionTO_SNSD.unsubscribe("subsciptionTO_SNSD");
                $('#modalFormDiasEspeciales').modal('hide')

            }, { id: "subsciptionTO_SNSD" });
            this.props.stompClient.send("/app/newSpecialDay", {}, JSON.stringify({
                "iduser": this.user.id,
                "specialDay": document.getElementById("inputDateGo").value,
                "hourIni": document.getElementById("hourIni").value,
                "hourFin": document.getElementById("hourFin").value,
            }));
        }
    }

    returnName(msg) {
        this.JsonUsers = JSON.parse(msg.body)
        this.subsciptionN_CR.unsubscribe("subsciptionN_CR");

        this.subsciptionLD_HA = this.props.stompClient.subscribe('/user/queue/localdateSubscribe', this.returnLocalDate, { id: "subsciptionLD_HA" });
        this.props.stompClient.send("/app/localdate", {});
    }
    returnLocalDate(msg) {
        this.localDate = msg.body

        this.localDate = this.localDate.replaceAll('"', "")
        this.splitDate = this.localDate.split("-")
        this.anyo = this.splitDate[0]
        this.mes = this.splitDate[1]
        this.dia = this.splitDate[2]

        this.dateTargetBeforeRef.current.value = (parseInt(this.anyo)) + "-01-01";
        this.dateTargetAfterRef.current.value = (parseInt(this.anyo)) + "-12-31";
        this.selectOption.current.value = "ThisY"

    }

    componentWillUnmount() {

    }
    sendUserInfo() {
        this.userInfo = {
            "id": this.user.id,
        }
        return this.userInfo;
    }
    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    datesDefault(e) {
        this.date = new Date()

        this.mesMesPasado = this.date.getMonth()
        this.anyoMesPasado = this.date.getFullYear()

        if (this.mesMesPasado == 0) {
            this.mesMesPasado = 11
            this.anyoMesPasado = this.date.getFullYear() + 1
        }

        this.lenghtMes = new Date(this.anyoMesPasado, this.anyoMesPasado, 0).getDate();
        switch (e.target.value) {
            case 'ThisM':
                var d = new Date();

                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-01";
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + (this.daysInMonth(d.getMonth() + 1, d.getFullYear())).toString();
                break;

            case 'LastD':
                var d = new Date();
                d.setDate(d.getDate() - 1)
                if (d.getDate() < 10) {
                    var diaAnterior = "0" + d.getDate().toString()
                }
                else {
                    var diaAnterior = d.getDate().toString()
                }
                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + diaAnterior;
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + diaAnterior;

                break;

            case 'Today':
                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                break;

            case 'All':
                this.dateTargetBeforeRef.current.value = "1980-01-01";
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                break;

            case 'LastW':

                var dateWeekBefore = new Date();
                var dateWeekAfter = new Date();

                var pastWeekDate = dateWeekAfter.getDate() - this.date.getDay();
                dateWeekAfter.setDate(pastWeekDate);
                dateWeekAfter.setMonth(dateWeekAfter.getMonth() + 1)

                var pastWeekDate = dateWeekBefore.getDate() - this.date.getDay() - 6;
                dateWeekBefore.setDate(pastWeekDate);
                dateWeekBefore.setMonth(dateWeekBefore.getMonth() + 1)

                var diaLastWeek1 = dateWeekBefore.getDate().toString()
                var diaLastWeek2 = dateWeekAfter.getDate().toString()

                var mesLastWeek1 = dateWeekBefore.getMonth().toString()
                var mesLastWeek2 = dateWeekAfter.getMonth().toString()

                var anyoLastWeek1 = dateWeekBefore.getFullYear().toString()
                var anyoLastWeek2 = dateWeekAfter.getFullYear().toString()

                if (diaLastWeek1 <= 9) {
                    diaLastWeek1 = "0" + diaLastWeek1
                }

                if (diaLastWeek2 <= 9) {
                    diaLastWeek2 = "0" + diaLastWeek2
                }

                if (mesLastWeek1 <= 9) {
                    mesLastWeek1 = "0" + mesLastWeek1
                }

                if (mesLastWeek2 <= 9) {
                    mesLastWeek2 = "0" + mesLastWeek2
                }

                this.dateTargetBeforeRef.current.value = anyoLastWeek1 + "-" + mesLastWeek1 + "-" + diaLastWeek1
                this.dateTargetAfterRef.current.value = anyoLastWeek2 + "-" + mesLastWeek2 + "-" + diaLastWeek2


                break;

            case 'LastM':
                var d = new Date();
                d.setMonth(d.getMonth() - 1);

                if ((parseInt(this.mes) - 1) != 0) {
                    if ((parseInt(this.mes) - 1) <= 9) {
                        this.dateTargetBeforeRef.current.value = this.anyo + "-0" + (parseInt(this.mes) - 1) + "-01";
                        this.dateTargetAfterRef.current.value = this.anyo + "-0" + (parseInt(this.mes) - 1) + "-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                    } else {

                        this.dateTargetBeforeRef.current.value = this.anyo + "-" + (parseInt(this.mes) - 1) + "-01";
                        this.dateTargetAfterRef.current.value = this.anyo + "-" + (parseInt(this.mes) - 1) + "-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                    }
                } else {

                    this.dateTargetBeforeRef.current.value = (parseInt(this.anyo) - 1) + "-12-01";
                    this.dateTargetAfterRef.current.value = (parseInt(this.anyo) - 1) + "-12-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                }
                break;

            case 'LastY':
                this.dateTargetBeforeRef.current.value = (parseInt(this.anyo) - 1) + "-01-01";
                this.dateTargetAfterRef.current.value = (parseInt(this.anyo) - 1) + "-12-31";

                break;
            case 'ThisY':
                this.dateTargetBeforeRef.current.value = (parseInt(this.anyo)) + "-01-01";
                this.dateTargetAfterRef.current.value = (parseInt(this.anyo)) + "-12-31";


            default:
                break;

        }

        this.search()

    }
    render() {
        return (
            <div id="formDiasEspeciales">
                <div id="divFormDiasEspeciales">
                    <div id="optionsDiasEspecialesForm" className="form-group row">
                        <div className="col-sm-1 form-group row">
                            <button type="button" class="btn-success" data-toggle="modal" onClick={this.createNewDay}>
                                Alta
                            </button>
                        </div>
                        <div className="col-sm-3 form-group row">
                            <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Inicio</label>
                            <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetBeforeRef} onClick={this.changeToCustom} />
                        </div>
                        <div className="col-sm-3 form-group row">
                            <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Fin</label>
                            <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetAfterRef} onClick={this.changeToCustom} />
                        </div>

                        <div className="col-sm-1.5 form-group row">
                            <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" onChange={this.datesDefault} ref={this.selectOption}>
                                <option value="Today">Hoy</option>
                                <option value="ThisM">Este mes</option>
                                <option value="ThisY">Este año</option>
                                <option value="All">Todos los Tiempos</option>
                                <option value="LastD">Ayer</option>
                                <option value="LastW">La semana Pasada</option>
                                <option value="LastM">El mes Pasado</option>
                                <option value="LastY">Año Pasado</option>
                                <option disabled>Custom</option>
                            </select>
                        </div>

                        <div className="col-sm-1 form-group row">
                            <button type="button" className="btn btn-primary" onClick={this.search}>Buscar</button>
                        </div>

                        <div id="modalDiasEspeciales">
                        </div>
                    </div>
                    <div id="tableDiasEspeciales">
                    </div>

                </div>

            </div>
        );
    }
}

export default DiasEspeciales;