import React, { Component } from "react";
import Alert from '../img/alert.png';
import Warning from '../img/warning.png';
import Ok from '../img/ok.png';
import Record from './Record'
import ReactDOM from 'react-dom';

class CurrentRecords extends Component {

  constructor(props) {
    super(props);
    this.retraso = this.retraso.bind(this);
    this.lista = props.param
    this.tablaRecord = React.createRef()

    this.state = { listaRecords: props.param }

  }

  componentDidMount() {
    if (Array.isArray(this.lista)) {
      this.renderizarRecords()
    }

    if(document.getElementById('totalesFichajesAdmin') != null){
      ReactDOM.unmountComponentAtNode(document.getElementById('totalesFichajesAdmin'))
      ReactDOM.render(
          <p>{"Total fichajes: " + this.props.totalRecordscont} </p>,
          document.getElementById('totalesFichajesAdmin')
      );
  
    }
  }

  componentDidUpdate() {
    if (Array.isArray(this.lista))
      this.renderizarRecords()
  }

  renderizarRecords() {
    const listItems = this.lista.map((r) => <Record stompClient={this.props.stompClient} canMod={this.props.canMod} admin={this.props.admin} item={r} user={this.props.users[r.iduser]} userModifier={this.props.users[r.id_user_modifier]} date={r.date} entry={r.timeEntry} exit={r.timeExit} time={this.timerHours(r.timeEntry, r.timeExit)} reason={r.reason} retraso={this.retraso(r)} > </Record>);
    ReactDOM.unmountComponentAtNode(this.tablaRecord.current)
    ReactDOM.render(listItems, this.tablaRecord.current)
  }

  timerHours(timeEntry, timeExit) {
    if (timeExit != null) {
      this.timeDiff = ((parseInt(timeExit.split(":")[0]) * 3600) + (parseInt(timeExit.split(":")[1]) * 60) + (parseInt(timeExit.split(":")[2]))) - ((parseInt(timeEntry.split(":")[0]) * 3600) + (parseInt(timeEntry.split(":")[1]) * 60) + (parseInt(timeEntry.split(":")[2])));

      this.horasDiff = Math.floor(this.timeDiff / 3600);
      this.minutosDiff = Math.floor((this.timeDiff - (this.horasDiff * 3600)) / 60);
      this.secondsDiff = this.timeDiff - (this.horasDiff * 3600) - (this.minutosDiff * 60);


      if (this.horasDiff < 10) { this.horasDiff = "0" + this.horasDiff; }
      if (this.minutosDiff < 10) { this.minutosDiff = "0" + this.minutosDiff; }
      if (this.secondsDiff < 10) { this.secondsDiff = "0" + this.secondsDiff; }
      return this.horasDiff + ":" + this.minutosDiff + ":" + this.secondsDiff;
    }
  }

  retraso(item) {
    if (item.retraso) {
      if (item.tipo_retraso == "Justificado") {
        return <img src={Warning} width="30" height="30" />
      } else {
        return <img src={Alert} width="20" height="20" />

      }
    } else {
      return <img src={Ok} width="20" height="20" />
    }
  }


  render() {
    return (
      <div class="table-wrapper" id="tableRecords2">
        <table class="table" >
          <thead>
            <tr>
              <th scope="col" style={{ width: '16%' }}>Nombre</th>
              <th scope="col" style={{ width: '16%' }}>Fecha</th>
              <th scope="col" style={{ width: '16%' }}>Entrada</th>
              <th scope="col" style={{ width: '16%' }}>Salida</th>
              <th scope="col" style={{ width: '16%' }}>Horas</th>
              <th scope="col" style={{ width: '16%' }}>Motivo</th>
              <th scope="col" style={{ width: '4%' }}></th>
            </tr>
          </thead>
          <tbody ref={this.tablaRecord}>
          </tbody>
        </table>
      </div>
    );
  }
}

export default CurrentRecords;
