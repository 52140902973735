import React, { Component } from "react";
import ReactDOM from 'react-dom';

class TableHorasAcumuladas extends Component {

    constructor(props) {
        super(props);
        this.calculateHours = this.calculateHours.bind(this);
        this.segDescTot = 0
        this.segSalidaTot = 0
        this.segEntradaTot = 0

    }
    componentDidMount() {
        ReactDOM.unmountComponentAtNode(document.getElementById('totales'))
        ReactDOM.render(
            <p >{"Entrada: " + this.calculateHoursString(this.segEntradaTot) + " Descanso: "+ this.calculateHoursString(this.segDescTot) + " Salida: " + this.calculateHoursString(this.segSalidaTot)} </p>,
            document.getElementById('totales')
        );

        
    }

    componentWillUnmount() {
    }

    calculateHours(segundos) {
        let negativo = false
        if (segundos < 0) {
            segundos = segundos * -1
            negativo = true
        }

        this.horasDiff = Math.floor(segundos / 3600);
        this.minutosDiff = Math.floor(segundos % 3600 / 60);

        if (segundos > 0) {
            this.segDiff = Math.floor(segundos % 3600  % 60);
        }
        else {
            this.segDiff = segundos
        }

        if (this.minutosDiff <= 9 && this.segDiff <= 9) {

            if (negativo) {
                return <th style={{ color: 'red' }}>-{this.horasDiff}:0{this.minutosDiff}:0{this.segDiff}</th>
            }

            return <th style={{ color: 'green' }}>{this.horasDiff}:0{this.minutosDiff}:0{this.segDiff}</th>
        }
        if (this.minutosDiff <= 9) {

            if (negativo) {
                return <th style={{ color: 'red' }}>-{this.horasDiff}:0{this.minutosDiff}:{this.segDiff}</th>
            }

            return <th style={{ color: 'green' }}>{this.horasDiff}:0{this.minutosDiff}:{this.segDiff}</th>
        }

        if (this.segDiff <= 9) {

            if (negativo) {
                return <th style={{ color: 'red' }}>-{this.horasDiff}:{this.minutosDiff}:0{this.segDiff}</th>
            }

            return <th style={{ color: 'green' }}>{this.horasDiff}:{this.minutosDiff}:0{this.segDiff}</th>
        }


        if (negativo) {
            return <th style={{ color: 'red' }}>-{this.horasDiff}:{this.minutosDiff}:{this.segDiff}</th>
        }

        return <th style={{ color: 'green' }}>{this.horasDiff}:{this.minutosDiff}:{this.segDiff}</th>
    }

    calculateHoursString(segundos) {
        let negativo = false
        if (segundos < 0) {
            segundos = segundos * -1
            negativo = true
        }

        this.horasDiff = Math.floor(segundos / 3600);
        this.minutosDiff = Math.floor(segundos % 3600 / 60);

        if (segundos > 0) {
            this.segDiff = Math.floor(segundos % 3600  % 60);
        }
        else {
            this.segDiff = segundos
        }

        if (this.minutosDiff <= 9 && this.segDiff <= 9) {

            if (negativo) {
                return "-" + this.horasDiff +":0" + this.minutosDiff + ":0" + this.segDiff
            }

            return this.horasDiff +":0" + this.minutosDiff + ":0" + this.segDiff
        }
        if (this.minutosDiff <= 9) {

            if (negativo) {
                return "-" + this.horasDiff + ":0" + this.minutosDiff + ":" + this.segDiff
            }

            return this.horasDiff + ":0" + this.minutosDiff + ":" + this.segDiff
        }

        if (this.segDiff <= 9) {

            if (negativo) {
                return "-" + this.horasDiff + ":" + this.minutosDiff +  ":0" + this.segDiff
            }

            return this.horasDiff + ":" + this.minutosDiff +  ":0" + this.segDiff
        }


        if (negativo) {
            return "-" + this.horasDiff + ":" + this.minutosDiff + ":" + this.segDiff
        }

        return this.horasDiff + ":" + this.minutosDiff + ":" + this.segDiff
    }



    render() {
        this.props.param.forEach(element => {
            this.segDescTot = this.segDescTot + element.minutosRetrasoDescanso
            this.segSalidaTot = this.segSalidaTot + element.minutosRetrasoSalida
            this.segEntradaTot = this.segEntradaTot + element.minutosRetrasoEntrada

        });

        var totales = document.getElementById("totales")
        console.log("this.segDescTot " + this.calculateHoursString(this.segDescTot))
        console.log("this.segSalidaTot " + this.calculateHoursString(this.segSalidaTot))
        console.log("this.segEntradaTot " + this.calculateHoursString(this.segEntradaTot))

        return (
            <div class="table-wrapper" id="tableDirecciones">
                <table class="table">
                    <thead class="table-dark">
                        <tr>
                            <th>Nombre</th>
                            <td>Fecha</td>
                            <td>Entrada</td>
                            <td>Descanso</td>
                            <td>Salida</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.param.map(item => (
                            <tr>
                                <th>{this.props.users[item.idUser]}</th>
                                <td>{item.fecha}</td>
                                {this.calculateHours(item.minutosRetrasoEntrada)}
                                {this.calculateHours(item.minutosRetrasoDescanso)}
                                {this.calculateHours(item.minutosRetrasoSalida)}
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableHorasAcumuladas;
