import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class TablaVacacionesSon extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        if (this.props.param != "") {
            return (
                <div class="table-wrapper" id="tableDirecciones">
                    <table class="table" id="tableVacacionesSon">
                        <thead class="table-dark">
                            <tr>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody >
                            {JSON.parse(this.props.param).map(item => (

                                <tr id={"tableVacacionesSon" + item.id}>
                                    <th>{item.dateFirstDay}</th>
                                    <td> <button class="btn btn-secundary" onClick={() => this.props.borrarDiaVacacionesSon(item)}>Borrar</button></td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                </div>
            );

        } else {
            return (
                <div class="table-wrapper" id="tableDirecciones">
                    <table class="table" id="tableVacacionesSon">
                        <thead class="table-dark">
                            <tr>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            )
        }
    }
}

export default TablaVacacionesSon;
