import React, { Component } from "react";

class TableHorasAcumuladas extends Component {

    constructor(props) {
        super(props);
        this.calculateMonth = this.calculateMonth.bind(this);
        this.calculateHours = this.calculateHours.bind(this);

    }
    componentDidMount() {
    }

    componentWillUnmount() {
    }
    
    calculateMonth(month) {
        this.mes = "";
        switch (month) {
            case 1:
                this.mes = "Enero";

                break;
            case 2:
                this.mes = "Febrero";

                break;
            case 3:
                this.mes = "Marzo";

                break;
            case 4:
                this.mes = "Abril";

                break;
            case 5:
                this.mes = "Mayo";

                break;
            case 6:
                this.mes = "Junio";

                break;
            case 7:
                this.mes = "Julio";

                break;
            case 8:
                this.mes = "Agosto";

                break;
            case 9:
                this.mes = "Septiembre";

                break;
            case 10:
                this.mes = "Octubre";

                break;
            case 11:
                this.mes = "Noviembre";

                break;
            case 12:
                this.mes = "Diciembre";

                break;

            default:
                break;
        }
        return <th>{this.mes}</th>
    }

    calculateHours(minutes) {
        let negativo = false
        if (minutes < 0) {
            minutes = minutes * -1
            negativo = true
        }

        this.horasDiff = Math.floor(minutes / 60);
        this.minutosDiff = Math.floor(minutes % 60);
        if (this.minutosDiff <= 9) {

            if (negativo) {
                return <th>-{this.horasDiff}h0{this.minutosDiff}m</th>
            }

            return <th>{this.horasDiff}h0{this.minutosDiff}m</th>
        }
        if (negativo) {
            return <th>-{this.horasDiff}h{this.minutosDiff}m</th>
        }

        return <th>{this.horasDiff}h{this.minutosDiff}m</th>
    }

    render() {
        return (
            <div class="table-wrapper" id="tableDirecciones">
                <table class="table">
                    <thead class="table-dark">
                        <tr>
                            <th>Nombre</th>
                            <th>Mes</th>
                            <th>Horas Acumuladas</th>
                            <th>Horas Laborable</th>
                            <th>Horas extras</th>
                            <th>Descanso Acumulado</th>
                            <th>Descanso Laborable</th>
                            <th>Descanso Restante</th>
                        </tr>
                    </thead>
                    <tbody>

                        {JSON.parse(this.props.param).map(item => (

                            <tr>
                                <th>{this.props.users[item.iduser]}</th>
                                {this.calculateMonth(item.month)}
                                {this.calculateHours(item.cumulative_minutesWorked)}
                                {this.calculateHours(item.minutesToWork)}
                                {this.calculateHours(item.cumulative_minutesWorked - item.minutesToWork)}
                                {this.calculateHours(item.cumulative_minutesRested)}
                                {this.calculateHours(item.minutesToRest)}
                                {this.calculateHours(item.cumulative_minutesRested - item.minutesToRest)}
                            </tr>

                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableHorasAcumuladas;
