import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalAuditoria extends Component {

    constructor(props) {
        super(props);
        this.returnUserModifiedState = this.returnUserModifiedState.bind(this);

        this.admin = this.props.adminInfo
        this.user = this.props.user

        this.inputTipoContacto = React.createRef();
        this.inputValueContacto = React.createRef();
        this.idItemContact = React.createRef();
        this.inputEstado = React.createRef();
        this.estado = React.createRef();

        this.changeStateOff = this.changeStateOff.bind(this)
        this.isOff = this.isOff.bind(this)

    }

    isOff() {
        if (this.user.off == true) {
            return true
        }
        else {
            return false
        }
    }

    changeStateOff() {

        let estadoOff
        if (document.getElementById("inputState").value == "true") {
            estadoOff = true
        } else {
            estadoOff = false
        }

        this.subsciptionMSU_FU = this.props.stompClient.subscribe('/user/queue/modifierStateUserSubscribe', () => {
            this.subsciptionMSU_FU.unsubscribe("subsciptionMSU_FU")

        }, { id: "subsciptionMSU_FU" });

        this.props.stompClient.send("/app/modifierStateUser", {}, JSON.stringify({
            "off": estadoOff,
            "id": this.user.id,
        }));
    }

    componentDidMount() {

    }

    returnUserModifiedState() {
        if (this.inputEstado.current.value == "true") {
            this.estado = true
        } else {
            this.estado = false
        }
        this.props.stateChanged(this.estado)
    }

    render() {
        return (
            <div class="modal fade" id="modalFormAuditoria" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Auditoria</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Creación</label>
                                <div class="col-sm-9">
                                    <input type="date" class="form-control" id="colFormLabel" placeholder="col-form-label" defaultValue={this.user.date_create} readOnly />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Última modificación</label>
                                <div class="col-sm-9">
                                    <input type="date" class="form-control" id="colFormLabel" placeholder="col-form-label" defaultValue={this.user.date_modify} readOnly />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Estado</label>
                                <div class="col-sm-9">
                                    <select id="inputState" class="form-control" ref={this.inputEstado} defaultValue={this.isOff()}>
                                        <option value="true">Desactivado</option>
                                        <option value="false">Activo</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary" onClick={this.changeStateOff} data-dismiss="modal">Guardar cambios</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalAuditoria;
