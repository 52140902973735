import React, { Component } from "react";
import ReactDOM from 'react-dom';
import TablaVacacionesLocal from './formPersonalComponents/tablasForm/TablaVacacionesLocal';
import ModalVacacionesLocal from './formPersonalComponents/modalesForm/ModalVacacionesLocal';
import $ from 'jquery';

class AjustesAdmin extends Component {

    constructor(props) {
        super(props);

        this.returnJSONModalVacaciones = this.returnJSONModalVacaciones.bind(this);
        this.modifyVacaciones = this.modifyVacaciones.bind(this);
        this.modifyContact = this.modifyContact.bind(this);
        this.returnVacaciones = this.returnVacaciones.bind(this);
        this.returnNewVacaciones = this.returnNewVacaciones.bind(this);
        this.saveNewVacaciones = this.saveNewVacaciones.bind(this);
        this.returnVacacionesModified = this.returnVacacionesModified.bind(this);
        this.deleteVacacionLocal = this.deleteVacacionLocal.bind(this);
        this.deleteVacacion = this.deleteVacacion.bind(this);

    }

    componentDidMount() {


        this.subsciptionRTNW_AA = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordGroupSubscribe', this.returnVacaciones, { id: "subsciptionRTNW_AA" });
        this.props.stompClient.send("/app/returnTableNonWorkRecordGroup", {}, JSON.stringify({
            "iduser": 0
        }));
        ReactDOM.render(
            <ModalVacacionesLocal adminInfo={this.props.adminInfo} saveNew={this.saveNewVacaciones} deleteVacacionLocal={this.deleteVacacionLocal} doJson={this.returnJSONModalVacaciones} />,
            document.getElementById('modalVacacionesLocal')
        );
        $('#modalVacacionesLocal').on('hide.bs.modal', function () {
            document.getElementById('btn-deleteMVL').style.display = "none"
        })

    }

    modifyContact(item) {
        alert(item)
        $('#modalFormVacaciones').modal('show')
    }

    componentWillUnmount() {

    }

    returnVacacionesModified() {
        this.subsciptionMNW_AA.unsubscribe("subsciptionMNW_AA");

        this.subsciptionRTNW_AA = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordGroupSubscribe', this.returnVacaciones, { id: "subsciptionRTNW_AA" });
        this.props.stompClient.send("/app/returnTableNonWorkRecordGroup", {}, JSON.stringify({
            "iduser": 0
        }));
    }

    modifyVacaciones(item) {
        document.getElementById('inputDateGo').value = item.dateFirstDay
        document.getElementById('inputReason').value = item.reason
        document.getElementById('inputAdminModifier').value = item.id_user_modifier
        document.getElementById('inputDateModified').value = item.date_modify
        document.getElementById('btn-deleteMVL').style.display = "block"
        this.idUserModifycator = this.props.adminInfo.id
        this.idItem = item.id
        $('#modalFormVacacionesLocal').modal('show')

    }

    deleteVacacionLocal() {
        this.Json = this.jsonModalVacaciones()
        this.Json.id = this.idItem
        this.subsciptionDNWR_AA = this.props.stompClient.subscribe('/user/queue/deleteNonWorkRecordByIdSubscribe', this.deleteVacacion, { id: "subsciptionDNWR_AA" });
        this.props.stompClient.send("/app/deleteNonWorkRecordById", {}, JSON.stringify(this.Json));

        $('#modalFormVacacionesLocal').modal('show')
    }
    deleteVacacion() {

        this.subsciptionDNWR_AA.unsubscribe("subsciptionDNWR_AA");
        this.subsciptionRTNW_AA = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordGroupSubscribe', this.returnVacaciones, { id: "subsciptionRTNW_AA" });
        this.props.stompClient.send("/app/returnTableNonWorkRecordGroup", {}, JSON.stringify({
            "iduser": 0
        }));
        $('#modalFormVacacionesLocal').modal('hide')
    }

    returnVacaciones(msg) {

        this.subsciptionRTNW_AA.unsubscribe("subsciptionRTNW_AA");
        this.componentWillUnmount(document.getElementById('tableVacacionesLocal'))
        ReactDOM.render(
            <TablaVacacionesLocal param={msg.body} modifyVacaciones={this.modifyVacaciones} />,
            document.getElementById('tableVacacionesLocal')
        );

    }

    returnNewVacaciones(msg) {

        this.subsciptionCNW_AA.unsubscribe("subsciptionCNW_AA");
        this.subsciptionRTNW_AA = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordGroupSubscribe', this.returnVacaciones, { id: "subsciptionRTNW_AA" });
        this.props.stompClient.send("/app/returnTableNonWorkRecordGroup", {}, JSON.stringify({
            "iduser": 0
        }));
    }

    saveNewVacaciones() {
        this.Json = this.jsonModalVacaciones()

        if (this.Json.error == undefined) {
            if (this.idUserModifycator != null) {
                this.Json.id_user_modifier = this.props.adminInfo.id
                this.Json.id = this.idItem
                this.subsciptionMNW_AA = this.props.stompClient.subscribe('/user/queue/modifyNonWorkRecordSubscribe', this.returnVacacionesModified, { id: "subsciptionMNW_AA" });
                this.props.stompClient.send("/app/modifyNonWorkRecord", {}, JSON.stringify(this.Json));
            } else {

                this.Json.id_user_creator = this.props.adminInfo.id

                this.subsciptionCNW_AA = this.props.stompClient.subscribe('/user/queue/createNonWorkRecordSubscribe', this.returnNewVacaciones, { id: "subsciptionCNW_AA" });
                this.props.stompClient.send("/app/createNonWorkRecord", {}, JSON.stringify(this.Json));
            }
        } else {
            alert(this.Json.error)
        }
        $('#modalFormVacacionesLocal').modal('hide')
        this.idUserModifycator = null

    }

    returnJSONModalVacaciones(callbackModalVacaciones) {
        this.jsonModalVacaciones = callbackModalVacaciones
    }

    render() {
        return (
            <div id="formAjustes">
                <h1>Ajustes</h1>
                <div id="divFormVacacionesLocal">
                    <div id="optionsVacacionesLocalForm">
                        <button type="button" class="btn-success btn-Form" data-toggle="modal" data-target="#modalFormVacacionesLocal">
                            Alta
                        </button>

                        <div id="modalVacacionesLocal">

                        </div>
                    </div>
                    <div id="tableVacacionesLocal">

                    </div>

                </div>
            </div>
        );
    }
}

export default AjustesAdmin;
