import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';
import TablaHorasAcumuladas from '../formPersonalComponents/tablasForm/TablaHorasAcumuladas';
import ModalSearchUser from '../formPersonalComponents/modalesForm/ModalSearchUser';
import '../../css/HistorialAdmin.css';

class HistorialCumulativeHours extends Component {

    constructor(props) {
        super(props);

        this.selectUser = this.selectUser.bind(this);
        this.datesDefault = this.datesDefault.bind(this);
        this.returnCurrentRecords = this.returnCurrentRecords.bind(this);
        this.search = this.search.bind(this);
        this.returnName = this.returnName.bind(this);

        this.iduserSelected = React.createRef();
        this.nameSelected = "";
        this.inputYear = React.createRef();
        this.selectMonth = React.createRef();

        this.returnYear = this.returnYear.bind(this);
        this.returnMonth = this.returnMonth.bind(this);
        this.printCumulativeHours = this.printCumulativeHours.bind(this);
        this.showDivCumulativeHours = this.showDivCumulativeHours.bind(this);
        this.cerrarImprimir = this.cerrarImprimir.bind(this);

        this.imprimir = false

    }

    componentDidMount() {

        document.getElementById("cargarDepartamentos").style.backgroundColor = "inherit"
        document.getElementById("cargarHistorial").style.backgroundColor = "#2F6D6C"
        document.getElementById("cargarHistorial").style.borderRadius = "22px"
        document.getElementById("cargarHistorial").style.paddingLeft = "10%"
        document.getElementById("cargarHistorial").style.paddingRight = "10%"
        document.getElementById("cargarFestivos").style.backgroundColor = "inherit"
        document.getElementById("cargarPersonal").style.backgroundColor = "inherit"
        document.getElementById("cargarIncidencias").style.backgroundColor = "inherit"


        ReactDOM.unmountComponentAtNode(document.getElementById('modalSearch'))
        ReactDOM.render(
            <ModalSearchUser stompClient={this.props.stompClient} selectUser={this.selectUser} />,
            document.getElementById('modalSearch')
        );
        this.subsciptionN_CR = this.props.stompClient.subscribe('/user/queue/getAllUsersNameAndIdSubscribe', this.returnName, { id: "subsciptionN_CR" });
        this.props.stompClient.send("/app/getAllUsersNameAndId", {});


    }
    componentWillUnmount() {
    }

    showDivCumulativeHours() {
        if (this.imprimir == false) {
            document.getElementById("divImprimirTabla").style.visibility = "visible"
            var divContents = document.getElementById("tableRecords").innerHTML;
            document.getElementById("tablaRecordsMensualesImprimir").innerHTML = divContents
            document.getElementById("botonImprimirDiv").onclick = this.printCumulativeHours
            document.getElementById("botonCerrarDiv").onclick = this.cerrarImprimir
        }

        this.imprimir = true
    }

    printCumulativeHours() {

        var divContents = document.getElementById("tableRecords").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<head>');
        a.document.write('<style>');
        a.document.write('table,th{border:1px solid black;}');
        a.document.write('thead{color:white; background-color:black}');
        a.document.write('</style>');
        a.document.write('</head>');
        a.document.write("<body>");
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();

    }

    cerrarImprimir() {
        document.getElementById("divImprimirTabla").style.visibility = "hidden"
        this.imprimir = false
    }

    returnCurrentRecords(msg) {
        this.subsciptionTMH_HCH.unsubscribe("subsciptionTMH_HCH");
        ReactDOM.unmountComponentAtNode(document.getElementById('tableRecords'))
        ReactDOM.render(
            <TablaHorasAcumuladas param={msg.body} users={this.JsonUsers[0]}  name={this.nameSelected} selectUser={this.selectUser} stompClient={this.props.stompClient} />,
            document.getElementById('tableRecords')
        );
    }

    returnName(msg) {
        this.JsonUsers = JSON.parse(msg.body)
        this.subsciptionN_CR.unsubscribe("subsciptionN_CR");

        this.subsciptionTMH_HCH = this.props.stompClient.subscribe('/user/queue/returnTableMonthlyHoursSubscribe', this.returnCurrentRecords, { id: "subsciptionTMH_HCH" });
        this.props.stompClient.send("/app/returnTableMonthlyHours", {}, JSON.stringify({
            "iduser": this.iduserSelected.current.value,
            "month": this.selectMonth.current.value,
            "year": this.inputYear.current.value,
        }));

    }
    selectUser(item) {
        this.iduserSelected.current.value = item.id
        this.nameSelected = item.nombre

        this.subsciptionTMH_HCH = this.props.stompClient.subscribe('/user/queue/returnTableMonthlyHoursSubscribe', this.returnCurrentRecords, { id: "subsciptionTMH_HCH" });
        this.props.stompClient.send("/app/returnTableMonthlyHours", {}, JSON.stringify({
            "iduser": this.iduserSelected.current.value,
            "month": this.selectMonth.current.value,
            "year": this.inputYear.current.value,
        }));
        $('#modalSearchUser').modal('hide')
    }

    search() {
        this.subsciptionTMH_HCH = this.props.stompClient.subscribe('/user/queue/returnTableMonthlyHoursSubscribe', this.returnCurrentRecords, { id: "subsciptionTMH_HCH" });
        this.props.stompClient.send("/app/returnTableMonthlyHours", {}, JSON.stringify({
            "iduser": this.iduserSelected.current.value,
            "month": this.selectMonth.current.value,
            "year": this.inputYear.current.value,
        }));
    }

    datesDefault(e) {
        this.subsciptionTMH_HCH = this.props.stompClient.subscribe('/user/queue/returnTableMonthlyHoursSubscribe', this.returnCurrentRecords, { id: "subsciptionTMH_HCH" });
        this.props.stompClient.send("/app/returnTableMonthlyHours", {}, JSON.stringify({
            "iduser": this.iduserSelected.current.value,
            "month": this.selectMonth.current.value,
            "year": this.inputYear.current.value,
        }));
    }

    returnYear() {
        let date = new Date()
        return date.getFullYear()
    }

    returnMonth() {
        let date = new Date()
        return date.getMonth() + 1
    }


    render() {
        return (
            <div>
                <div id="divImprimirTabla">
                    <div className="form-group row">
                        <div className="col-sm-3 form-group row">
                            <button type="button" className="btn btn-primary botonImprimir" id="botonImprimirDiv">Imprimir</button>

                        </div>
                        <div className="col-sm-1 form-group row">
                            <button type="button" className="btn btn-primary botonImprimir" id="botonCerrarDiv" >Cerrar</button>
                        </div>
                        <div id="tablaRecordsMensualesImprimir">

                        </div>
                    </div>
                </div>

                <h1 id="divPersonal">Historial</h1>
                <div class="form-group row" id="searchHistorialAdmin">
                    <div className="col-sm-3 form-group row">
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalSearchUser">Seleccionar Empleado</button>
                        <label for="colFormLabelSm" class="col-sm-2 col-form-label ">Id</label>
                        <input type="number" class="form-control dateSearchHistorial col-sm-3" ref={this.iduserSelected} defaultValue={0} />


                    </div>
                    <div className="col-sm-2 form-group row">
                        <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" defaultValue={this.returnMonth()} onChange={this.datesDefault} ref={this.selectMonth}>
                            <option value="13">Todos</option>
                            <option value="1">Enero</option>
                            <option value="2">Febrero</option>
                            <option value="3">Marzo</option>
                            <option value="4">Abril</option>
                            <option value="5">Mayo</option>
                            <option value="6">Junio</option>
                            <option value="7">Julio</option>
                            <option value="8">Agosto</option>
                            <option value="9">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                        </select>
                    </div>

                    <div className="col-sm-3 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Año</label>
                        <input type="Number" defaultValue={this.returnYear()} class="form-control col-sm-6" ref={this.inputYear} placeholder="2021" minLength="4" maxLength="4" min="2000" />
                    </div>

                    <div className="col-sm-1 form-group row">
                        <button type="button" className="btn btn-primary" onClick={this.showDivCumulativeHours}>Imprimir</button>
                    </div>

                    <div className="col-sm-1 form-group row">
                        <button type="button" className="btn btn-primary" onClick={this.search}>Buscar</button>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div id="tableRecords">

                </div>
                <div id="modalSearch">

                </div>

            </div>
        );
    }
}

export default HistorialCumulativeHours;
