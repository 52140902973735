import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalContactos extends Component {

    constructor(props) {
        super(props);
        this.returnTypeTable = this.returnTypeTable.bind(this)
        this.returnContactTable = this.returnContactTable.bind(this)

    }

    returnTypeTable(item) {
        if (item.principal) {
            return (
                <th>{item.type}</th>
            )
        } else {
            return (
                <td>{item.type}</td>
            )
        }
    }

    returnContactTable(item) {

        if (item.principal) {
            return (
                <th>{item.value}</th>
            )
        } else {
            return (
                <td>{item.value}</td>
            )
        }
    }

    render() {
        return (
            <div class="table-wrapper" id="tableDirecciones">
                <table class="table">
                    <thead class="table-dark">
                        <tr >
                            <th>Tipo</th>
                            <th>Contacto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {JSON.parse(this.props.param).map(item => (
                            <tr onDoubleClick={() => this.props.modifyContact(item)}>
                                {this.returnTypeTable(item)}
                                {this.returnContactTable(item)}
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ModalContactos;
