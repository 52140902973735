import React, { Component } from "react";
import ReactDOM from 'react-dom';
import DatosUser from './formPersonalComponents/DatosUser';
import DireccionUser from './formPersonalComponents/DireccionUser';
import ObservacionesUser from './formPersonalComponents/ObservacionesUser';
import DiasEspeciales from './formPersonalComponents/DiasEspeciales'
import VacacionesUser from './formPersonalComponents/VacacionesUser';
import ModalErrorModify from './formPersonalComponents/modalesForm/ModalErrorModify'
import $ from 'jquery';
import '../css/User.css';
import '../css/FormUser.css';

class FormUser extends Component {

    constructor(props) {
        super(props);
        this.onClickNav = this.onClickNav.bind(this);
        this.modifyUser = this.modifyUser.bind(this);
        this.modifyUserState = this.modifyUserState.bind(this);
        this.returnUserMod = this.returnUserMod.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.user = this.props.user

        this.inputDescripcionUser = React.createRef();



    }

    doHomework(callback) {
        this.metCall = callback
    }

    onClickNav(e) {
        switch (e.target.textContent) {
            case "Datos":
                this.htmlContent = <DatosUser user={this.user} stompClient={this.props.stompClient} adminInfo={this.props.adminInfo} modifyUser={this.modifyUser} modifyUserState={this.modifyUserState} deleteUser={this.deleteUser} cargarPersonal={this.props.cargarPersonal} />

                break;
            case "Direcciones":

                this.htmlContent = <DireccionUser user={this.user} stompClient={this.props.stompClient} adminInfo={this.props.adminInfo} />
                break;
            case "Observaciones":

                this.htmlContent = <ObservacionesUser user={this.user} stompClient={this.props.stompClient} adminInfo={this.props.adminInfo} />
                break;
            case "Vacaciones / Absencias programadas":

                this.htmlContent = <VacacionesUser user={this.user} stompClient={this.props.stompClient} adminInfo={this.props.adminInfo} />
                break;

            case "Dias especiales":
                this.htmlContent = <DiasEspeciales user={this.user} stompClient={this.props.stompClient} adminInfo={this.props.adminInfo}/>

            default:
                break;
        }
        ReactDOM.render(
            this.htmlContent,
            document.getElementById('cuerpoForm')
        );
        this.navElements = document.getElementsByClassName("navLinksForm")
        Array.prototype.filter.call(this.navElements, function (element) {
            element.style.border = "1px solid black";
        });
        e.target.style.borderBottom = "0px";

    }

    deleteUser(userInfoModified) {

        this.subsciptionGU_DU = this.props.stompClient.subscribe('/user/queue/deleteUserSubscribe', (msg) => {
            this.subsciptionGU_DU.unsubscribe("subsciptionGU_DU")

            this.props.cargarPersonal();

        }, { id: "subsciptionGU_DU" });
        this.props.stompClient.send("/app/deleteUser", {}, JSON.stringify(userInfoModified));

    }


    componentDidMount() {

        ReactDOM.render(
            <DatosUser user={this.user} stompClient={this.props.stompClient} adminInfo={this.props.adminInfo} modifyUser={this.modifyUser} modifyUserState={this.modifyUserState} deleteUser={this.deleteUser} cargarPersonal={this.props.cargarPersonal} />,
            document.getElementById('cuerpoForm')
        );
        document.getElementsByClassName("navLinksForm")[0].style.borderBottom = "0px";
    }

    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(document.getElementById('cuerpoForm'))
    }


    modifyUser(userInfoModified) {

        this.subsciptionMU_FU = this.props.stompClient.subscribe('/user/queue/modifyUserSubscribe', this.returnUserMod, { id: "subsciptionMU_FU" });
        this.subsciptionMSU_FU = this.props.stompClient.subscribe('/user/queue/modifierStateUserSubscribe', this.returnUserMod, { id: "subsciptionMSU_FU" });
        this.props.stompClient.send("/app/modifyUser", {}, JSON.stringify(userInfoModified));

    }
    modifyUserState(userInfoModified) {
        $('#modalFormAuditoria').modal('hide')
        this.subsciptionMSU_FU = this.props.stompClient.subscribe('/user/queue/modifierStateUserSubscribe', this.returnUserMod, { id: "subsciptionMSU_FU" });
        this.subsciptionMU_FU = this.props.stompClient.subscribe('/user/queue/modifyUserSubscribe', this.returnUserMod, { id: "subsciptionMU_FU" });
        this.props.stompClient.send("/app/modifierStateUser", {}, JSON.stringify(userInfoModified));

    }
    returnUserMod(msg) {
        this.subsciptionMU_FU.unsubscribe("subsciptionMU_FU");
        this.subsciptionMSU_FU.unsubscribe("subsciptionMSU_FU");
        this.user = JSON.parse(msg.body)
        this.inputDescripcionUser.current.value = this.user.nombre + " " + this.user.apellidos;

        if (JSON.parse(msg.body).id == 0) {
            ReactDOM.render(
                <ModalErrorModify textoError="Error, no se han podido guardar los cambios" />,
                document.getElementById('errorAlModificarServer')
            );
            $('#modalFormErrorModify').modal('show');

        } else {
            ReactDOM.unmountComponentAtNode(document.getElementById('cuerpoForm'))
            ReactDOM.render(
                <DatosUser user={JSON.parse(msg.body)} stompClient={this.props.stompClient} adminInfo={this.props.adminInfo} modifyUser={this.modifyUser} modifyUserState={this.modifyUserState} deleteUser={this.deleteUser} cargarPersonal={this.props.cargarPersonal} />,
                document.getElementById('cuerpoForm')
            );

        }

    }

    render() {
        return (
            <div>
                <h1 id="divPersonal">Personal</h1>
                <div id="descripcionFormUser">
                    <div id="">
                        <label>Código</label>
                        <input type="text" value={this.user.id} readOnly />
                    </div>
                    <div id="divnombreForm" >
                        <label>Descripción</label>
                        <input type="text" id="nombreForm" value={this.user.nombre + " " + this.user.apellidos} ref={this.inputDescripcionUser} readOnly />
                    </div>
                </div>
                <div id="divNavForm">
                    <nav id="navForm">
                        <a onClick={this.onClickNav} className="navLinksForm">Datos</a>
                        <a onClick={this.onClickNav} className="navLinksForm">Direcciones</a>
                        <a onClick={this.onClickNav} className="navLinksForm">Observaciones</a>
                        <a onClick={this.onClickNav} className="navLinksForm">Vacaciones / Absencias programadas</a>
                    {/*    <a onClick={this.onClickNav} className="navLinksForm">Dias especiales</a> */}
                    </nav>
                </div>
                <div id="cuerpoForm">

                </div>

                <div id="errorAlModificarServer">

                </div>


            </div>
        );
    }
}


export default FormUser;
