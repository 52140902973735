import React, { Component } from "react";
import ReactDOM from 'react-dom';
import TablaObservaciones from './tablasForm/TablaObservaciones';
import ModalObservaciones from './modalesForm/ModalObservaciones';
import $ from 'jquery';
import '../../css/formPersonal/ObservacionesUser.css';

class ObservacionesUser extends Component {

    constructor(props) {
        super(props);
        this.user = this.props.user
        this.admin = this.props.adminInfo

        this.returnJSONModalObservation = this.returnJSONModalObservation.bind(this);
        this.modifyObservaciones = this.modifyObservaciones.bind(this);
        this.modifyContact = this.modifyContact.bind(this);
        this.returnObservaciones = this.returnObservaciones.bind(this);
        this.sendUserInfo = this.sendUserInfo.bind(this);
        this.returnNewObservation = this.returnNewObservation.bind(this);
        this.saveNewObservation = this.saveNewObservation.bind(this);
        this.returnObservationModified = this.returnObservationModified.bind(this);

        this.deleteObservaciones = this.deleteObservaciones.bind(this);
        this.loadAllObservations = this.loadAllObservations.bind(this)
        this.altaNewObservaciones = this.altaNewObservaciones.bind(this)
        this.renderizarObservaciones = this.renderizarObservaciones.bind(this)

    }

    componentDidMount() {

        this.subsciptionTO_OU = this.props.stompClient.subscribe('/user/queue/returnTableObservationsSubscribe', this.returnObservaciones, { id: "subsciptionTO_OU" });
        this.props.stompClient.send("/app/returnTableObservations", {}, JSON.stringify({
            "iduser": this.user.id
        }));

        ReactDOM.render(
            <ModalObservaciones user={this.props.user} adminInfo={this.props.adminInfo} saveNew={this.saveNewObservation} doJson={this.returnJSONModalObservation} deleteObservaciones={this.deleteObservaciones} />,
            document.getElementById('modalObservaciones')
        );
        $('#modalObservaciones').on('hide.bs.modal', function () {
            document.getElementById('btn-deleteMVL').style.display = "none"
        })

    }

    altaNewObservaciones() {
        document.getElementById("botonGuardarObservacion").disabled = false

        this.renderizarObservaciones()

        document.getElementById('idObservacion').value = ""
        document.getElementById('tipoObservacion_id').value = "Positiva"
        document.getElementById('observacion_id').value = ""
        document.getElementById('btn-deleteMVL').style.display = "none"
        this.idUserModifycator = null
        $('#modalFormObservaciones').modal('show')


    }

    renderizarObservaciones() {

        ReactDOM.render(
            <ModalObservaciones user={this.props.user} adminInfo={this.props.adminInfo} saveNew={this.saveNewObservation} doJson={this.returnJSONModalObservation} deleteObservaciones={this.deleteObservaciones} />,
            document.getElementById('modalObservaciones')
        );
        $('#modalObservaciones').on('hide.bs.modal', function () {
            document.getElementById('btn-deleteMVL').style.display = "none"
        })


    }

    deleteObservaciones(event) {
        this.Json = this.jsonModalObservation()

        this.props.stompClient.send("/app/deleteObservationById", {}, JSON.stringify({
            "id": this.Json.id
        }));
        this.subsciptionTC_DO = this.props.stompClient.subscribe('/user/queue/deleteObservationByIdSubscribe', () => {
            this.subsciptionTC_DO.unsubscribe("subsciptionTC_DO")
            this.loadAllObservations()

        }, { id: "subsciptionTC_DO" });
    }

    loadAllObservations() {
        this.subsciptionTO_OU = this.props.stompClient.subscribe('/user/queue/returnTableObservationsSubscribe', this.returnObservaciones, { id: "subsciptionTO_OU" });
        this.props.stompClient.send("/app/returnTableObservations", {}, JSON.stringify({
            "iduser": this.user.id
        }));

    }

    modifyContact(item) {
        alert(item)
        $('#modalFormContacto').modal('show')
    }

    componentWillUnmount() {


    }

    returnObservationModified(msg) {

        if (msg.body == null) {
            document.getElementById('msgErrorObservacionNoValida').style.display = "block"
        } else {

            if (JSON.parse(msg.body).statusCodeValue == 500) {
                document.getElementById('msgErrorObservacionNoValida').style.display = "none"
                document.getElementById('msgErrorObservacionServidor').style.display = "block"
                document.getElementById("botonGuardarObservacion").disabled = false
            }

            this.subsciptionMO_OU.unsubscribe("subsciptionMO_OU");
            this.subsciptionTO_OU = this.props.stompClient.subscribe('/user/queue/returnTableObservationsSubscribe', this.returnObservaciones, { id: "subsciptionTO_OU" });
            this.props.stompClient.send("/app/returnTableObservations", {}, JSON.stringify({
                "iduser": this.user.id
            }));
        }
    }

    sendUserInfo() {
        this.userInfo = {
            "id": this.user.id,
        }

        return this.userInfo;
    }

    modifyObservaciones(item) {
        document.getElementById("botonGuardarObservacion").disabled = false
        document.getElementById('idObservacion').value = item.id
        document.getElementById('tipoObservacion_id').value = item.type_observation
        document.getElementById('observacion_id').value = item.observation
        document.getElementById('btn-deleteMVL').style.display = "block"
        this.idUserModifycator = this.admin.id
        this.idItem = item.id
        $('#modalFormObservaciones').modal('show')
    }

    returnObservaciones(msg) {

        this.subsciptionTO_OU.unsubscribe("subsciptionTO_OU");
        this.componentWillUnmount(document.getElementById('tableObservaciones'))
        ReactDOM.render(
            <TablaObservaciones param={msg.body} modifyObservaciones={this.modifyObservaciones} />,
            document.getElementById('tableObservaciones')
        );
    }

    returnNewObservation(msg) {

        if (msg.body == null) {
            document.getElementById('msgErrorObservacionNoValida').style.display = "block"
        } else {
            if (JSON.parse(msg.body).statusCodeValue == 500) {
                document.getElementById('msgErrorObservacionNoValida').style.display = "none"
                document.getElementById('msgErrorObservacionServidor').style.display = "block"
                document.getElementById("botonGuardarObservacion").disabled = false
            }
            this.subsciptionCO_OU.unsubscribe("subsciptionCO_OU");
            this.subsciptionTO_OU = this.props.stompClient.subscribe('/user/queue/returnTableObservationsSubscribe', this.returnObservaciones, { id: "subsciptionTO_OU" });
            this.props.stompClient.send("/app/returnTableObservations", {}, JSON.stringify({
                "iduser": this.user.id
            }));
        }
    }

    saveNewObservation() {
        document.getElementById("botonGuardarObservacion").disabled = true
        this.Json = this.jsonModalObservation()

        if (this.Json.error == undefined) {
            $('#modalFormObservaciones').modal('hide')
            document.getElementById('msgErrorObservacionNoValida').style.display = "none"
            if (this.idUserModifycator != null) {
                this.Json.id_user_modifier = this.idUserModifycator
                this.Json.id = this.idItem
                this.subsciptionMO_OU = this.props.stompClient.subscribe('/user/queue/modifyObservationSubscribe', this.returnObservationModified, { id: "subsciptionMO_OU" });
                this.props.stompClient.send("/app/modifyObservations", {}, JSON.stringify(this.Json));
            } else {

                this.subsciptionCO_OU = this.props.stompClient.subscribe('/user/queue/createObservationSubscribe', this.returnNewObservation, { id: "subsciptionCO_OU" });
                this.props.stompClient.send("/app/createObservations", {}, JSON.stringify(this.Json));
            }
        } else {
            document.getElementById("botonGuardarObservacion").disabled = false
            document.getElementById('msgErrorObservacionNoValida').style.display = "block"

        }
        this.idUserModifycator = null

    }

    returnJSONModalObservation(callbackModalObservation) {
        this.jsonModalObservation = callbackModalObservation
    }

    render() {
        return (
            <div id="formObservaciones">

                <div id="divFormObservaciones">
                    <div id="optionsObservacionesForm">
                        <button type="button" class="btn-success btn-Form" data-toggle="modal" onClick={this.altaNewObservaciones}>
                            Alta
                        </button>

                        <div id="modalObservaciones">

                        </div>
                    </div>
                    <div id="tableObservaciones">

                    </div>

                </div>
            </div>
        );
    }
}

export default ObservacionesUser;
