import React, { Component } from "react";
import ReactDOM from 'react-dom';
import CurrentUsers from './CurrentUsers';
import AjustesAdmin from './AjustesAdmin';
import FormUser from './FormUser';
import Login from './Login';
import HistorialAdmin from './HistorialsAdmin/HistorialAdmin';
import HistorialCumulativeHours from './HistorialsAdmin/HistorialCumulativeHours';
import $ from 'jquery';
import '../css/Admin.css';
import ModalUser from "./formPersonalComponents/modalesForm/ModalUser";
import DepartamentosAdmin from "./formPersonalComponents/DepartamentosAdmin"
import FestivosAdmin from "./formPersonalComponents/FestivosAdmin";
import HistorialCumulativeHoursDay from "./HistorialsAdmin/HistorialCumulativeHoursDay";
import User from "./User";
import IncidenciasAdmin from "./IncidenciasAdmin";
import HistorialCumulativeHoursDays from './HistorialsAdmin/HistorialCumulativeHoursDays';
import HistorialCalculoDeRetraso from './HistorialsAdmin/HistorialCalculoDeRetraso';

class AdminPage extends Component {

  constructor(props) {
    super(props);
    this.returnRecordsAdmin = this.returnRecordsAdmin.bind(this);
    this.modificarUsuario = this.modificarUsuario.bind(this);
    this.cargarPersonal = this.cargarPersonal.bind(this);
    this.cargarHistorial = this.cargarHistorial.bind(this);
    this.altaUsuario = this.altaUsuario.bind(this);
    this.returnAltaUsuario = this.returnAltaUsuario.bind(this);
    this.returnUsersByName = this.returnUsersByName.bind(this);
    this.searchUserByName = this.searchUserByName.bind(this);
    this.cargarHistorialCumulativeHours = this.cargarHistorialCumulativeHours.bind(this);
    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.cargarAjustes = this.cargarAjustes.bind(this);
    this.offValueChanged = this.offValueChanged.bind(this)
    this.adminValueChanged = this.adminValueChanged.bind(this)
    this.unmountTableUsers = this.unmountTableUsers.bind(this)
    this.hideSearchUsersOptions = this.hideSearchUsersOptions.bind(this)
    this.showSearchUsersOptions = this.showSearchUsersOptions.bind(this)
    this.onChangeNameValue = this.onChangeNameValue.bind(this)
    this.createNewUser = this.createNewUser.bind(this)
    this.valideAndCreateNewUser = this.valideAndCreateNewUser.bind(this)
    this.cargarDepartamentos = this.cargarDepartamentos.bind(this)
    this.cargarFestivos = this.cargarFestivos.bind(this)
    this.cargarHistorialDailyHours = this.cargarHistorialDailyHours.bind(this)
    this.cargarUsuario = this.cargarUsuario.bind(this)
    this.cargarIncidencias = this.cargarIncidencias.bind(this)
    this.cargarCalculoDeHoras = this.cargarCalculoDeHoras.bind(this)
    this.cargarCalculoDeRetrasos = this.cargarCalculoDeRetrasos.bind(this)

    this.inputNombre = React.createRef();
    this.inputOff = React.createRef();
    this.inputAdmin = React.createRef();

  }

  offValueChanged(event) {
    this.inputOff.current.checked = document.getElementById('cbOff').checked

    this.searchUserByName(this.inputNombre.current.value, this.inputOff.current.checked, this.inputAdmin.current.checked)
  }

  cargarCalculoDeRetrasos(){
      ReactDOM.render(
      <HistorialCalculoDeRetraso adminInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('tableUsers')
    );
    this.hideSearchUsersOptions()
  }

  cargarHistorialDailyHours() {
    ReactDOM.render(
      <HistorialCumulativeHoursDay adminInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('tableUsers')
    );
    this.hideSearchUsersOptions()

  }

  cargarFestivos() {
    ReactDOM.render(
      <FestivosAdmin adminInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('tableUsers')
    );

    this.hideSearchUsersOptions()
  }

  cargarUsuario() {
    ReactDOM.render(
      <User userInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('root')
    );
  }

  cargarCalculoDeHoras(){
    console.log("cargarCalculoDeHoras()")
    ReactDOM.render(
      <HistorialCumulativeHoursDays adminInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('tableUsers')
    );
    this.hideSearchUsersOptions()

  }

  onChangeNameValue() {

    clearInterval(this.timer1)

    this.timer1 = setTimeout(() => {
      this.searchUserByName(this.inputNombre.current.value, this.inputOff.current.checked, this.inputAdmin.current.checked)

    }, 400)
  }

  cargarDepartamentos() {
    ReactDOM.render(
      <DepartamentosAdmin adminInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('tableUsers')
    );

    this.hideSearchUsersOptions()

  }

  cargarIncidencias() {
    ReactDOM.render(
      <IncidenciasAdmin adminInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('tableUsers')
    );
    this.hideSearchUsersOptions()
  }

  valideAndCreateNewUser() {

    document.getElementById("botonCrearUsuario").disabled = true

    if (document.getElementById('nameNewUser').value == "" || document.getElementById('SubnameNewUser').value == "" || document.getElementById('usernameNewUser').value == "") {

      document.getElementById("botonCrearUsuario").disabled = false

      document.getElementById('msgErrorCrearUsuario').style.display = "block"
      document.getElementById("msgErrorUsuarioDuplicado").style.display = "none"
      document.getElementById("msgErrorUsuarioServidor").style.display = "none"
    } else {
      this.subsciptionUNC_FUBNU = this.props.stompClient.subscribe('/user/queue/findUserByUsernameSubscribe', (msg) => {
        this.subsciptionUNC_FUBNU.unsubscribe("subsciptionUNC_FUBNU")

        if (JSON.parse(msg.body).statusCodeValue == 417) {
          document.getElementById("botonCrearUsuario").disabled = false
          document.getElementById('msgErrorCrearUsuario').style.display = "none"
          document.getElementById("msgErrorUsuarioDuplicado").style.display = "block"
          document.getElementById("msgErrorUsuarioServidor").style.display = "none"
        }
        else if (JSON.parse(msg.body).statusCodeValue == 500) {
          document.getElementById("botonCrearUsuario").disabled = false
          document.getElementById('msgErrorCrearUsuario').style.display = "none"
          document.getElementById("msgErrorUsuarioDuplicado").style.display = "none"
          document.getElementById("msgErrorUsuarioServidor").style.display = "block"

        }
        else {
          this.subsciptionUNC_CNU = this.props.stompClient.subscribe('/user/queue/createUserNameDescriptionSubscribe', (msg) => {
            this.subsciptionUNC_CNU.unsubscribe("subsciptionUNC_CNU")

            if (msg.body != "null") {

              if (JSON.parse(msg.body).statusCodeValue == 500) {
                document.getElementById("botonCrearUsuario").disabled = false
                document.getElementById('msgErrorCrearUsuario').style.display = "none"
                document.getElementById("msgErrorUsuarioDuplicado").style.display = "none"
                document.getElementById("msgErrorUsuarioServidor").style.display = "block"

              } else {
                this.userItem = JSON.parse(msg.body).body

                $('#modalFormCreateUser').modal('toggle');
                ReactDOM.render(
                  <FormUser user={this.userItem} adminInfo={this.props.userInfo} stompClient={this.props.stompClient} cargarPersonal={this.cargarPersonal} />,
                  document.getElementById('tableUsers')
                );
                this.hideSearchUsersOptions()

              }

            }
          }, { id: "subsciptionUNC_CNU" });
          this.props.stompClient.send("/app/createUserNameDescription", {}, JSON.stringify({
            "id_user_creator": this.props.userInfo.id,
            "nombre": document.getElementById('nameNewUser').value,
            "apellidos": document.getElementById('SubnameNewUser').value,
            "nombre_usuario": document.getElementById('usernameNewUser').value,
          }));
        }
      }, { id: "subsciptionUNC_FUBNU" });
      this.props.stompClient.send("/app/findUserByUsername", {}, JSON.stringify({
        "nombre_usuario": document.getElementById('usernameNewUser').value,
      }));
    }
  }

  createNewUser(event) {
    document.getElementById("botonCrearUsuario").disabled = false
    $('#modalFormCreateUser').modal('show')
  }

  adminValueChanged(event) {
    this.inputAdmin.current.checked = document.getElementById('cbAdmin').checked

    this.searchUserByName(this.inputNombre.current.value, this.inputOff.current.checked, this.inputAdmin.current.checked)

  }

  componentWillUnmount() {
  }

  componentDidMount() {

    document.getElementById("cargarHistorial").style.backgroundColor = "inherit"
    document.getElementById("cargarPersonal").style.backgroundColor = "#2F6D6C"
    document.getElementById("cargarPersonal").style.borderRadius = "22px"
    document.getElementById("cargarPersonal").style.paddingLeft = "1%"
    document.getElementById("cargarPersonal").style.paddingRight = "1%"
    document.getElementById("cargarPersonal").style.position = "1%"
    document.getElementById("cargarDepartamentos").style.backgroundColor = "inherit"
    document.getElementById("cargarFestivos").style.backgroundColor = "inherit"
    document.getElementById("cargarIncidencias").style.backgroundColor = "inherit"


    this.timer1 = setTimeout(() => {
      this.searchUserByName(this.inputNombre.current.value, this.inputOff.current.checked, this.inputAdmin.current.checked)
    }, 0);

    this.subsciptionUNC_AP = this.props.stompClient.subscribe('/user/queue/getUserByNameCointainingSubscribe', this.returnUsersByName, { id: "subsciptionUNC_AP" });
    this.props.stompClient.send("/app/getUserByNameCointaining", {}, JSON.stringify({
      "nombre": "",
      "off": false,
      "admin": false
    }));

  }

  unmountTableUsers() {
    document.getElementById('opcionesTableUsers').style.visibility = "hidden"
  }

  returnRecordsAdmin(msg) {
    this.subsciptionAU_AP.unsubscribe("subsciptionAU_AP");
    this.currentsUsers = JSON.parse(msg.body)
    ReactDOM.render(
      <div>
        <CurrentUsers param={msg.body} stompClient={this.props.stompClient} name={this.props.name} modUser={this.modificarUsuario} createUser={this.altaUsuario} searchUser={this.searchUserByName} cargarPersonal={this.cargarPersonal} />
        <ModalUser valideAndCreateNewUser={this.valideAndCreateNewUser} />
      </div>,
      document.getElementById('tableUsers')
    );
  }

  modificarUsuario(e) {
    this.idUserClicked = e.target.parentElement.firstElementChild.textContent
    this.currentsUsers.forEach(user => {
      if (this.idUserClicked == user.id) {
        this.userItem = user
      }
    });

    ReactDOM.render(
      <FormUser user={this.userItem} adminInfo={this.props.userInfo} stompClient={this.props.stompClient} cargarPersonal={this.cargarPersonal} />,
      document.getElementById('tableUsers')
    );
    this.hideSearchUsersOptions()

  }

  altaUsuario() {
    var opcion = window.confirm("Estas a punto de crear un usuario nuevo, quieres que sea de tipo administrador?");

    this.subsciptionCU_AP = this.props.stompClient.subscribe('/user/queue/createUserSubscribe', this.returnAltaUsuario, { id: "subsciptionCU_AP" });
    this.props.stompClient.send("/app/createUser", {}, JSON.stringify({
      "id_user_creator": JSON.parse(this.props.userInfo).id,
      "admin": opcion
    }));
  }

  returnAltaUsuario(msg) {
    this.subsciptionCU_AP.unsubscribe("subsciptionCU_AP");
    this.user = JSON.stringify(JSON.parse(msg.body).body)
    ReactDOM.unmountComponentAtNode(document.getElementById('tableUsers'))
    ReactDOM.render(
      <FormUser user={this.user} adminInfo={this.props.userInfo} stompClient={this.props.stompClient} cargarPersonal={this.cargarPersonal} />,
      document.getElementById('tableUsers')
    );
  }

  cargarPersonal() {

    document.getElementById("cargarDepartamentos").style.backgroundColor = "inherit"
    document.getElementById("cargarHistorial").style.backgroundColor = "inherit"
    document.getElementById("cargarFestivos").style.backgroundColor = "inherit"
    document.getElementById("cargarIncidencias").style.backgroundColor = "inherit"
    document.getElementById("cargarPersonal").style.backgroundColor = "#2F6D6C"

    this.subsciptionUNC_AP = this.props.stompClient.subscribe('/user/queue/getUserByNameCointainingSubscribe', this.returnUsersByName, { id: "subsciptionUNC_AP" });
    this.props.stompClient.send("/app/getUserByNameCointaining", {}, JSON.stringify({
      "nombre": "",
      "off": false,
      "admin": false
    }));
  }

  showSearchUsersOptions(event) {

    document.getElementById("opcionesTableUsers").style.display = "block"
  }

  hideSearchUsersOptions(event) {
    document.getElementById('cbAdmin').checked = false
    document.getElementById('cbOff').checked = false

    document.getElementById("opcionesTableUsers").style.display = "none"
  }

  cargarLogin() {
    ReactDOM.render(
      <Login showError={false} />, document.getElementById('root')
    );
  }

  cargarHistorial() {

    ReactDOM.render(
      <HistorialAdmin adminInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('tableUsers')
    );

    this.hideSearchUsersOptions()

  }
  cargarHistorialCumulativeHours() {

    ReactDOM.render(
      <HistorialCumulativeHours adminInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('tableUsers')
    );
    this.hideSearchUsersOptions()
  }

  searchUserByName(nombre, off, isAdmin) {
    this.subsciptionUNC_AP = this.props.stompClient.subscribe('/user/queue/getUserByNameCointainingSubscribe', this.returnUsersByName, { id: "subsciptionUNC_AP" });
    this.props.stompClient.send("/app/getUserByNameCointaining", {}, JSON.stringify({
      "nombre": nombre,
      "off": off,
      "admin": isAdmin,
    }));
  }

  returnUsersByName(msg) {
    this.showSearchUsersOptions()
    this.subsciptionUNC_AP.unsubscribe("subsciptionUNC_AP");
    ReactDOM.unmountComponentAtNode(document.getElementById('tableUsers'))
    this.currentsUsers = JSON.parse(msg.body)
    ReactDOM.render(
      <div>
        <CurrentUsers param={msg.body} stompClient={this.props.stompClient} name={this.props.name} modUser={this.modificarUsuario} createUser={this.altaUsuario} searchUser={this.searchUserByName} cargarPersonal={this.cargarPersonal} />
        <ModalUser createNewUser={this.createNewUser} valideAndCreateNewUser={this.valideAndCreateNewUser} />
      </div>,
      document.getElementById('tableUsers')
    );
  }

  hideDropdown() {
    $('.dropdown-menu').dropdown().hide()
  }

  showDropdown() {
    $('.dropdown-menu').dropdown().show()
  }

  cargarAjustes() {
    ReactDOM.render(
      <AjustesAdmin adminInfo={this.props.userInfo} stompClient={this.props.stompClient} />,
      document.getElementById('tableUsers')
    );
  }

  render() {
    return (
      <div id="generalAdmin">
        <div id="divHeader">
          <nav class="navbar navbar-dark bg-dark">
            <div class="container">
              <button onClick={this.cargarUsuario} type="button" class="btn font-weight-bold" style={{ backgroundColor: "green", marginLeft: "20px" }}>Vista usuario</button>
              <a class="navbar-brand">
                <button onClick={this.cargarLogin} type="button" class="btn btn-danger font-weight-bold">SALIR</button>
              </a>
              <a class="navbar-brand" id="cargarPersonal" onClick={this.cargarPersonal}>Personal</a>
              <div class="btn-group" id="divHistorial">
                <a class="navbar-brand" id="cargarHistorial" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown}>Historial ☰</a>
                <div class="dropdown-menu dropdown-menu-right" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown}>
                  <a class="dropdown-item" onClickCapture={this.cargarHistorial}>Historial h/Ordinarias</a>
                  <a class="dropdown-item" onClickCapture={this.cargarHistorialCumulativeHours}>Historial h/Mensuales</a>
                  <a class="dropdown-item" onClickCapture={this.cargarHistorialDailyHours}>Historial h/Diarias</a>
                  <a class="dropdown-item" onClickCapture={this.cargarCalculoDeHoras}>Historial h/Calculo</a>
                  <a class="dropdown-item" onClickCapture={this.cargarCalculoDeRetrasos}>Historial h/Retrasos</a>
                </div>
              </div>
              <a class="navbar-brand" id="cargarIncidencias" onClick={this.cargarIncidencias}>Incidencias</a>
              <a class="navbar-brand" id="cargarDepartamentos" onClick={this.cargarDepartamentos}>Departamentos</a>
              <a class="navbar-brand" id="cargarFestivos" onClick={this.cargarFestivos}>Festivos</a>
            </div>
          </nav>
        </div>
        <div id="opcionesTableUsers">
          <div id="btnUsers">
            <button class="btn-success btn-Form" onClick={this.createNewUser}>Alta</button>
          </div>
          <div id="search">
            <div id="searchAdmin">
              <label>Administradores</label>
              <input type="checkbox" ref={this.inputAdmin} id="cbAdmin" onChange={this.adminValueChanged} />
            </div>
            <div id="searchOff">
              <label>Desactivados</label>
              <input type="checkbox" ref={this.inputOff} id="cbOff" onChange={this.offValueChanged} />
            </div>
            <div>
              <input ref={this.inputNombre} onChange={this.onChangeNameValue} type="text" placeholder="Empleado" />
            </div>
            <div>
              <button class="btn-success btn-Form" id="btnSearchUser" onClick={() => this.searchUserByName(this.inputNombre.current.value, this.inputOff.current.checked, this.inputAdmin.current.checked)}>Buscar</button>
            </div>
          </div>
        </div>
        <div id="tableUsers">

        </div>

      </div>
    );
  }
}

export default AdminPage;