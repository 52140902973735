import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css'

class ModalUser extends Component {

    constructor(props) {
        super(props);

        this.username = React.createRef()
        this.nameUser = React.createRef()
        this.subnameUser = React.createRef()

    }
    render() {
        return (
            <div class="modal fade" id="modalFormCreateUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Creando Nuevo usuario</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Nombre de usuario</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="colFormLabel" id="usernameNewUser" ref={this.username} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Nombre</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="colFormLabel" id="nameNewUser" ref={this.nameUser} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Apellidos</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="colFormLabel" id="SubnameNewUser" ref={this.subnameUser} />
                                </div>
                            </div>

                            <div class="modal-footer">
                                <div>
                                    <p id="msgErrorCrearUsuario" style={{ color: 'red', display: 'none' }}>* Error, Alguno de los campos esta vacío, compruebe si los datos introducidos són correctos</p>
                                    <p id="msgErrorUsuarioDuplicado" style={{ color: 'red', display: 'none' }}>* Error, Este nombre de usuario ya está en uso</p>
                                    <p id="msgErrorUsuarioServidor" style={{ color: 'red', display: 'none' }}>* Error, no se ha podido crear el usuario</p>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-secundary" data-dismiss="modal">Cerrar</button>
                                    <button type="button" class="btn btn-primary" id="botonCrearUsuario" onClick={this.props.valideAndCreateNewUser}>Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalUser;
