import React, { Component } from "react";
import ReactDOM from 'react-dom';
import Login from './Login';
import Reloj from './Reloj';
import HistorialRecords from './HistorialRecords';
import CurrentRecords from './CurrentRecords';
import Incidencias from "./Incidencias";
import ModalRecord from './formPersonalComponents/modalesForm/ModalRecord';
import ModalConfirmacionFichaje from './formPersonalComponents/modalesForm/ModalConfirmacionFichaje';
import ModalChangePasswd from './formPersonalComponents/modalesForm/ModalChangePasswd';
import ModalGeneratePasswd from './formPersonalComponents/modalesForm/ModalGeneratePasswd';
import AdminPage from "./AdminPage";
import ModalTiempoDescanso from './formPersonalComponents/modalesForm/ModalRestTime';
import ModalInformatiu from "./formPersonalComponents/modalesForm/ModalInformatiu";
import $ from 'jquery';

import '../css/User.css';
import HistorialCumulativeHoursUser from "./HistorialCumulativeHoursUser";

function getCookie(key) {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const data = cookies[i].split("=");
    if (data[0] === key) {
      return data[1];
    }
  }
  return "";
}


class User extends Component {
  constructor(props) {
    super(props);

    this.userInfo = this.props.userInfo
    this.cargarLogin = this.cargarLogin.bind(this);
    this.changeReason = this.changeReason.bind(this);
    this.returnFichaje = this.returnFichaje.bind(this);
    this.cargarHistorial = this.cargarHistorial.bind(this);
    this.cargarIncidencias = this.cargarIncidencias.bind(this);
    this.confirmarFichaje = this.confirmarFichaje.bind(this);
    this.sumarSegundos = this.sumarSegundos.bind(this);
    this.returnCurrentRecords = this.returnCurrentRecords.bind(this);
    this.returnDateServer = this.returnDateServer.bind(this);
    this.modRecord = this.modRecord.bind(this);
    this.returnName = this.returnName.bind(this);
    this.returnGeneratePasswd = this.returnGeneratePasswd.bind(this);
    this.generatePasswd = this.generatePasswd.bind(this);
    this.checkPasswd = this.checkPasswd.bind(this);
    this.rellenarFichaje = this.rellenarFichaje.bind(this);
    this.changePasswd = this.changePasswd.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.returnTipoJornada = this.returnTipoJornada.bind(this);
    this.calcularDescanso = this.calcularDescanso.bind(this);
    this.cargarUsuario = this.cargarUsuario.bind(this);
    this.testOnFocus = this.testOnFocus.bind(this);
    this.cargarHistorialCalculo = this.cargarHistorialCalculo.bind(this);
    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);


    this.dateLogin = React.createRef();


    this.reason = "Descanso";

  }

  componentWillUnmount() {
    clearInterval(this.sumandoSegundos);
    clearInterval(this.deslog);

  }

  componentDidMount() {

    ReactDOM.unmountComponentAtNode(document.getElementById('modalPasswd'))
    ReactDOM.render(
      < ModalGeneratePasswd isAdmin={false} generatePasswd={this.generatePasswd} />,
      document.getElementById('modalPasswd')
    );

    ReactDOM.unmountComponentAtNode(document.getElementById('modalContraseña'))
    ReactDOM.render(
      <ModalChangePasswd checkPasswd={this.checkPasswd} isAdmin={false} />,
      document.getElementById('modalContraseña')
    );

    ReactDOM.unmountComponentAtNode(document.getElementById('modalRecord'))
    ReactDOM.render(
      <ModalRecord />,
      document.getElementById('modalRecord')
    );

    ReactDOM.unmountComponentAtNode(document.getElementById('modalConfirmacionFichaje'))
    ReactDOM.render(
      <ModalConfirmacionFichaje confirmarFichaje={this.confirmarFichaje} />,
      document.getElementById('modalConfirmacionFichaje')
    );

    ReactDOM.unmountComponentAtNode(document.getElementById('modalTiempoDescanso'))
    ReactDOM.render(
      <ModalTiempoDescanso calcularDescanso={this.calcularDescanso} />,
      document.getElementById('modalTiempoDescanso')
    );

    this.subsciptionN_CR = this.props.stompClient.subscribe('/user/queue/getAllUsersNameAndIdSubscribe', this.returnName, { id: "subsciptionN_CR" });
    this.props.stompClient.send("/app/getAllUsersNameAndId", {});

    this.subsciptionRDS_U = this.props.stompClient.subscribe('/user/queue/date', this.returnDateServer, { id: "subsciptionRDS_U" });
    this.props.stompClient.send("/app/date", {});

    if (this.props.userInfo.admin) {
      ReactDOM.render(
        <div class="container">
          <button onClick={this.cargarUsuario} type="button" class="btn font-weight-bold" style={{ backgroundColor: "green", marginLeft: "20px" }}>Vista admin</button>
          <button class="navbar-brand btn btn-danger font-weight-bold" onClick={this.cargarLogin} type="button">SALIR</button>
          <a class="navbar-brand" href="#" style={{ backgroundColor: '#2F6D6C', paddingLeft: '1%', paddingRight: '1%', borderRadius: '22px' }}>Fichaje</a>
          <div class="btn-group" id="divHistorial">
            <a class="navbar-brand" id="cargarHistorial" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown}>Historial ☰</a>
            <div class="dropdown-menu dropdown-menu-right" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown}>
              <a class="dropdown-item" onClickCapture={this.cargarHistorial}>Historial h/Diarias</a>
              <a class="dropdown-item" onClickCapture={this.cargarHistorialCalculo}>Historial h/Mensuales</a>
            </div>
          </div>
          <a class="navbar-brand" onClick={this.cargarIncidencias}>Incidencias</a>
          <a class="navbar-brand" data-toggle="modal" data-target="#modalFormChangePasswd">Cambiar Contraseña</a>
        </div>, document.getElementById("menuUser")
      );
    }

    ReactDOM.unmountComponentAtNode(document.getElementById('modalInformatiu'))
    ReactDOM.render(
      <ModalInformatiu msgInfo={"¡Feliz cumpleaños " + this.userInfo.nombre + " " + this.userInfo.apellidos + "!"} />,
      document.getElementById("modalInformatiu")
    )
    //this.testOnFocus()

  }

  hideDropdown() {
    $('.dropdown-menu').dropdown().hide()
  }

  showDropdown() {
    $('.dropdown-menu').dropdown().show()
  }

  cargarUsuario() {
    ReactDOM.render(
      <AdminPage userInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('root')
    );


  }

  calcularDescanso() {

  }

  generatePasswd(passwd, restablecer) {
    this.jsonInfo = {
      "id": this.userInfo.id,
      "passwd": passwd,
      "restablecerPasswd": restablecer
    };

    this.subsciptionGP_DU = this.props.stompClient.subscribe('/user/queue/generatePasswdSubscribe', this.returnGeneratePasswd, { id: "subsciptionGP_DU" });
    this.props.stompClient.send("/app/generatePasswd", {}, JSON.stringify(this.jsonInfo));
    $('#modalFormPasswd').modal('hide')
  }

  returnGeneratePasswd(msg) {
    this.userInfo = JSON.parse(msg.body);
    this.subsciptionGP_DU.unsubscribe("subsciptionGP_DU");
  }

  returnTipoJornada() {

    this.subsciptionRCR = this.props.stompClient.subscribe('/user/queue/getJornadaByUserSubscribe', (msg) => {

      if (document.getElementById("jornada") != null) {
        document.getElementById("jornada").innerHTML = "Jornada: " + JSON.parse(msg.body).tipo
      }

    });
    this.props.stompClient.send("/app/getJornadaByUser", {}, JSON.stringify({
      "jornada": this.userInfo.jornada,
    }));
  }

  returnName(msg) {
    this.JsonUsers = JSON.parse(msg.body)
    this.subsciptionN_CR.unsubscribe("subsciptionN_CR");

    this.subsciptionRCR = this.props.stompClient.subscribe('/user/queue/recordCurrentSubscribe', this.returnCurrentRecords);
    this.props.stompClient.send("/app/recordCurrent", {}, JSON.stringify({
      "iduser": this.userInfo.id,
      "daterecord": null,
      "dateEntry": null,
      "dateExit": null,
      "reason": this.reason,
    }));
    if (this.userInfo.restablecerPasswd == true) {
      $('#modalFormPasswd').modal('show')
    }
  }

  cargarLogin() {
    ReactDOM.render(
      <Login showError={false} />, document.getElementById('root')
    );
  }

  cargarHistorial() {
    console.log("test")
    ReactDOM.render(
      <HistorialRecords userInfo={this.userInfo} checkPasswd={this.checkPasswd} stompClient={this.props.stompClient} />,
      document.getElementById('root')
    );
  }

  cargarHistorialCalculo() {
    ReactDOM.render(
      <HistorialCumulativeHoursUser userInfo={this.userInfo} checkPasswd={this.checkPasswd} stompClient={this.props.stompClient} />,
      document.getElementById('root')
    );
  }


  cargarIncidencias() {
    ReactDOM.render(
      <Incidencias userInfo={this.userInfo} checkPasswd={this.checkPasswd} stompClient={this.props.stompClient} />,
      document.getElementById('root')
    );
  }

  changeReason(e) {
    this.reason = e.target.textContent
    Array.prototype.forEach.call(document.getElementsByClassName("reason"), function (el) {
      el.style.backgroundColor = ""
    });
    e.target.style.backgroundColor = "aqua"
  }

  returnFichaje(msg) {
    this.subsciptionRF.unsubscribe("subsciptionRF");
    if (msg.body == "Error") {
      document.getElementById("botonConfirmarFichar").disabled = false;
      document.getElementById("msgErrorRecordServidor2").style.display = "block"
    } else {
      $('#modalFormConfirmacionFichaje').modal('hide')
      document.getElementById("msgErrorRecordServidor2").style.display = "none"

      var todayDate = new Date()
      var monthDate = todayDate.getMonth() + 1
      var dayDate = todayDate.getDate()
      if (monthDate < 10) {
        monthDate = "0" + monthDate.toString()
      }
      if (dayDate < 10) {
        dayDate = "0" + dayDate.toString()
      }
      if (this.userInfo.fecha_nacimiento != null) {
        var stringToday = monthDate + "-" + dayDate
        var arraySupp = this.userInfo.fecha_nacimiento.split("-")
        var stringfchNac = arraySupp[1] + "-" + arraySupp[2]
        console.log(stringfchNac)
        console.log(stringToday)
        if (msg.body == "insertarI" && stringToday == stringfchNac) {
          $('#modalFormInfo').modal('show')

        }

      }

      this.subsciptionRCR = this.props.stompClient.subscribe('/user/queue/recordCurrentSubscribe', this.returnCurrentRecords);
      this.props.stompClient.send("/app/recordCurrent", {}, JSON.stringify({
        "id": null,
        "iduser": this.userInfo.id,
        "daterecord": null,
        "dateEntry": null,
        "dateExit": null,
        "reason": this.reason,
      }));
    }
  }

  testOnFocus() {
    console.log("test on focus")
  }

  calcularTiempo() {
    if (this.contarTiempoDescanso) {
      this.descansoSeg = this.descansoSeg - 1
      if (this.descansoSeg < 0) {
        this.descansoSeg = 59
        this.descansoMin = this.descansoMin - 1
      }
      if (this.descansoSeg == 0 && this.descansoMin == 0) {
        new Notification("El usuario " + this.userInfo.nombre + " " + this.userInfo.apellidos + " se ha quedado sin tiempo de descanso")
        this.contarTiempoDescanso = false
        $('#modalFormTiempoDescanso').modal('hide')
      }

      if (this.descansoSeg.toString().length == 1) {
        this.stringseg = "0" + this.descansoSeg.toString()
      } else {
        this.stringseg = this.descansoSeg.toString()
      }
      if (this.descansoMin.toString().length == 1) {
        this.stringmin = "0" + this.descansoMin.toString()
      } else {
        this.stringmin = this.descansoMin.toString()
      }

      if (this.contarTiempoDescanso) {
        ReactDOM.render(
          <div>
            <div for="colFormLabel" class="col-form-label"><h1 id="tiempoDescansoMin">{this.stringmin + ":" + this.stringseg}</h1></div>
          </div>,
          document.getElementById('tiempoDescanso')
        );
      }

      setTimeout(() => {
        this.calcularTiempo()
      }, 1000)
    }
  }

  rellenarFichaje() {

    document.getElementById("botonConfirmarFichar").disabled = false;

    this.subsciptionGRTS = this.props.stompClient.subscribe('/user/queue/getReasonToStopSubscribe', (msg) => {
      this.subsciptionGRTS.unsubscribe()
      if (msg.body == "Fin de jornada") {
        this.reason = msg.body
      }
      document.getElementById("nombre_id").value = this.userInfo.nombre;
      document.getElementById("fecha_id").value = this.timer;
      document.getElementById("motivo_id").value = this.reason;
    }, { id: "subsciptionGRTS" });
    this.props.stompClient.send("/app/getReasonToStop", {}, JSON.stringify({
      "jornada": this.userInfo.jornada,
    }));
  }

  returnCurrentRecords(msg) {
    ReactDOM.unmountComponentAtNode(document.getElementById('divFooter'))
    ReactDOM.render(
      <CurrentRecords canMod={true} param={JSON.parse(msg.body)} users={this.JsonUsers[0]} stompClient={this.props.stompClient} modRecord={this.modRecord} />,
      document.getElementById('divFooter')
    );
    this.subsciptionRCR.unsubscribe("subsciptionRCR");

  }

  modRecord(item) {
    this.tiempoEntrada = item.timeEntry.split(":")
    if (item.timeExit != null && item.timeExit != undefined) {

      this.tiempoSalida = item.timeExit.split(":")
      document.getElementById('inputTimeExit').value = this.tiempoSalida[0] + ":" + this.tiempoSalida[1] + ":" + this.tiempoSalida[2]
    } else {
      document.getElementById('inputTimeExit').value = ""
    }
    document.getElementById('inputTimeEntry').value = this.tiempoEntrada[0] + ":" + this.tiempoEntrada[1] + ":" + this.tiempoEntrada[2]
    document.getElementById('inputReason').value = item.reason
    document.getElementById('idUserRecord').value = item.iduser
    document.getElementById('idRecord').value = item.id
    document.getElementById('inputAdminModifier').value = item.id_user_modifier
    document.getElementById('inputDateModified').value = item.date_modify

    document.getElementById('inputJustificacion').value = item.tipo_retraso

    if (item.retraso) {
      document.getElementById('divJustificacion').style.display = "block";
      document.getElementById('inputRetraso').checked = true;
    } else {
      document.getElementById('divJustificacion').style.display = "none";
      document.getElementById('inputRetraso').checked = false;
    }

    document.getElementById('inputTimeEntry').readOnly = true;
    document.getElementById('inputTimeExit').readOnly = true;
    document.getElementById('inputReason').readOnly = true;
    document.getElementById('idUserRecord').readOnly = true;
    document.getElementById('idRecord').readOnly = true;
    document.getElementById('inputAdminModifier').readOnly = true;
    document.getElementById('inputDateModified').readOnly = true;
    document.getElementById('inputJustificacion').disabled = true;
    document.getElementById('inputRetraso').disabled = true;
    document.getElementById('btnModifierRecord').disabled = true;

    $('#modalFormRecord').modal('show')

  }

  returnDateServer(msg) {
    this.subsciptionRDS_U.unsubscribe("subsciptionRDS_U");
    var secs = msg.body;
    var time = secs.split(":");


    this.segundos = time[2];
    this.minutos = time[1];
    this.hora = time[0];

    this.dateLogin.current.textContent = time[3];
    this.sumarSegundos()
    clearInterval(this.sumandoSegundos);
    this.sumandoSegundos = setInterval(this.sumarSegundos, 1000);
  }

  sumarSegundos() {
    this.segundos++;
    if (this.segundos == 60) {
      this.segundos = 0;
      this.minutos++;
      if (this.minutos == 60) {
        this.minutos = 0;
        this.hora++;
        if (this.hora == 24) {
          this.hora = 0;
        }
      }
    }

    if (this.segundos.toString().length == 1) {
      this.stringS = "0" + this.segundos.toString()
    } else {
      this.stringS = this.segundos.toString()
    }

    if (this.minutos.toString().length == 1) {
      this.stringM = "0" + this.minutos.toString()
    } else {
      this.stringM = this.minutos.toString()
    }

    if (this.hora.toString().length == 1) {
      this.stringH = "0" + this.hora.toString()
    } else {
      this.stringH = this.hora.toString()
    }
    this.timer = this.hora + ":" + this.minutos + ":" + this.segundos;
    ReactDOM.render(
      <Reloj segundos={this.stringS} minutos={this.stringM} hora={this.stringH} />,
      document.getElementById('contenedor')
    );
  }

  confirmarFichaje() {

    document.getElementById("botonConfirmarFichar").disabled = true;

    this.subsciptionRF = this.props.stompClient.subscribe('/user/queue/createrecord', this.returnFichaje);
    this.props.stompClient.send("/app/record", {}, JSON.stringify({
      "iduser": this.userInfo.id,
      "reason": this.reason,
      "cookies": getCookie("pcid"),
    }));

    this.subsciptionRDS_U = this.props.stompClient.subscribe('/user/queue/date', this.returnDateServer, { id: "subsciptionRDS_U" });
    this.props.stompClient.send("/app/date", {});

    this.deslog = setInterval(() => {
      console.log(clearInterval(this.deslog));
      ReactDOM.render(
        <Login showError={false} />, document.getElementById('root')
      );
    }, 100000);

  }

  checkPasswd(passwdAnt, newPasswd) {
    this.newPasswd = newPasswd
    this.subsciptionVU_U = this.props.stompClient.subscribe('/user/queue/comprobarContraseñaSubscribe', this.changePasswd, { id: "subsciptionVU_U" });
    this.props.stompClient.send("/app/comprobarContraseña", {}, JSON.stringify({
      "id": this.userInfo.id,
      "nombre_usuario": this.userInfo.nombre_usuario,
      "passwd": passwdAnt,
    }));
  }

  changePasswd(msg) {
    this.subsciptionVU_U.unsubscribe("subsciptionVU_U");

    if (msg.body == "true") {
      this.subsciptionCP_U = this.props.stompClient.subscribe('/user/queue/changePasswdSubscribe', this.passwordChanged, { id: "subsciptionCP_U" });
      this.props.stompClient.send("/app/changePasswd", {}, JSON.stringify({
        "id": this.userInfo.id,
        "passwd": this.newPasswd,
      }));

    } else {
      document.getElementById("msgErrorContraseñaVacia").style.display = "none"
      document.getElementById('msgErrorContraseñaNoValida').style.display = "none";
      document.getElementById("msgErrorContraseñaNoCoincide").style.display = "none"
      document.getElementById('msgErrorContraseñaAnteriorNoValida').style.display = "block";
    }
  }

  passwordChanged() {
    $('#modalFormChangePasswd').modal('hide')
    this.subsciptionCP_U.unsubscribe("subsciptionCP_U");
  }

  render() {
    return (
      <div id="general" onFocusCapture={this.testOnFocus}>
        <div id="divHeader">
          <nav class="navbar navbar-dark bg-dark" id="menuUser">
            <div class="container">
              <button class="navbar-brand btn btn-danger font-weight-bold" onClick={this.cargarLogin} type="button">SALIR</button>
              <a class="navbar-brand" href="#" style={{ backgroundColor: '#2F6D6C', paddingLeft: '1%', paddingRight: '1%', borderRadius: '22px' }}>Fichaje</a>
              <div class="btn-group" id="divHistorial">
                <a class="navbar-brand" id="cargarHistorial" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown}>Historial ☰</a>
                <div class="dropdown-menu dropdown-menu-right" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown}>
                  <a class="dropdown-item" onClickCapture={this.cargarHistorial}>Historial h/Diarias</a>
                  <a class="dropdown-item" onClickCapture={this.cargarHistorialCalculo}>Historial h/Mensuales</a>
                </div>
              </div>
              <a class="navbar-brand" onClick={this.cargarIncidencias}>Incidencias</a>
              <a class="navbar-brand" data-toggle="modal" data-target="#modalFormChangePasswd">Cambiar Contraseña</a>
            </div>
          </nav>
          <div class="container">
            <div class="form-group row">
              <div class="col-md-4 col-form-label">
                <h2 id="jornada" onLoad={this.returnTipoJornada()}>Jornada: </h2>
              </div>
              <div class="col-md-4 col-form-label">
                <h2 id="empleado">Empleado: {this.userInfo.nombre}</h2>
              </div>
              <div class="col-md-4 col-form-label" id="fecha">
                <h2 id="fecha" ref={this.dateLogin} />
              </div>
            </div>
          </div>
        </div>
        <div id="divBody">
          <div id="motivos">
            <h3>Motivos</h3>
            <ul class="list-group" >
              <li class="list-group-item reason" value="Descanso" onClick={this.changeReason}>Descanso</li>
              <li class="list-group-item reason" onClick={this.changeReason}>Fin de jornada</li>
              <li class="list-group-item reason" onClick={this.changeReason}>Incidencia Medica</li>
              <li class="list-group-item reason" onClick={this.changeReason}>Asuntos Personales</li>
            </ul>
          </div>
          <div id="fichaje" data-toggle="modal" data-target="#modalFormConfirmacionFichaje" onClick={this.rellenarFichaje}>
            <a class="quicklink link1" href="#">
              <span class="ql_caption">
                <span class="outer">
                  <span class="inner">
                    <h2>Fichar</h2>
                  </span>
                </span>
              </span>
              <span class="ql_top"></span>
              <span class="ql_bottom"></span>
            </a>
          </div>
          <div class="contenedor" id="contenedor">

          </div>
        </div>

        <div id="divFooter">

        </div>
        <div id="modalRecord">

        </div>
        <div id="modalContraseña">

        </div>

        <div id="modalPasswd">

        </div>
        <div id="modalConfirmacionFichaje">

        </div>
        <div id="modalTiempoDescanso">

        </div>
        <div id="modalInformatiu">

        </div>

      </div>
    )
  }
}

export default User;
