import React, { Component } from "react";
import ReactDOM from 'react-dom';
import CurrentRecords from '../CurrentRecords';
import ModalSearchUser from '../formPersonalComponents/modalesForm/ModalSearchUser';
import ModalRecord from '../formPersonalComponents/modalesForm/ModalRecord';
import $ from 'jquery';
import '../../css/HistorialAdmin.css';

class HistorialAdmin extends Component {

    constructor(props) {
        super(props);

        this.selectUser = this.selectUser.bind(this);
        this.datesDefault = this.datesDefault.bind(this);
        this.changeToCustom = this.changeToCustom.bind(this);
        this.returnCurrentRecords = this.returnCurrentRecords.bind(this);
        this.search = this.search.bind(this);
        this.modRecord = this.modRecord.bind(this);
        this.modifiedRecord = this.modifiedRecord.bind(this);
        this.returnJSONModalRecord = this.returnJSONModalRecord.bind(this);
        this.returnRecordModified = this.returnRecordModified.bind(this);
        this.returnLocalDate = this.returnLocalDate.bind(this);
        this.returnName = this.returnName.bind(this);
        this.deleteRecord = this.deleteRecord.bind(this);
        this.deleteRecordReturn = this.deleteRecordReturn.bind(this);
        this.daysInMonth = this.daysInMonth.bind(this);
        this.crearRecord = this.crearRecord.bind(this);
        this.printRecords = this.printRecords.bind(this);
        this.showRecordsPrint = this.showRecordsPrint.bind(this);
        this.cerrarImprimir = this.cerrarImprimir.bind(this);

        this.iduserSelected = React.createRef();
        this.dateTargetBeforeRef = React.createRef();
        this.dateTargetAfterRef = React.createRef();
        this.selectOption = React.createRef();

        this.admin = this.props.adminInfo
        this.imprimir = false

    }

    componentDidMount() {
        this.componentWillUnmount(document.getElementById('modalSearchHistorial'))
        ReactDOM.render(
            <ModalSearchUser stompClient={this.props.stompClient} selectUser={this.selectUser} />,
            document.getElementById('modalSearchHistorial')
        );
        this.componentWillUnmount(document.getElementById('modalRecord'))
        ReactDOM.render(
            <ModalRecord adminInfo={this.props.adminInfo} stompClient={this.props.stompClient} selectUser={this.selectUser} modifiedRecord={this.modifiedRecord} deleteRecord={this.deleteRecord} doJson={this.returnJSONModalRecord} />,
            document.getElementById('modalRecord')
        );

        this.subsciptionN_CR = this.props.stompClient.subscribe('/user/queue/getAllUsersNameAndIdSubscribe', this.returnName, { id: "subsciptionN_CR" });
        this.props.stompClient.send("/app/getAllUsersNameAndId", {});

        document.getElementById("cargarDepartamentos").style.backgroundColor = "inherit"
        document.getElementById("cargarHistorial").style.backgroundColor = "#2F6D6C"
        document.getElementById("cargarHistorial").style.borderRadius = "22px"
        document.getElementById("cargarHistorial").style.paddingLeft = "10%"
        document.getElementById("cargarHistorial").style.paddingRight = "10%"
        document.getElementById("cargarFestivos").style.backgroundColor = "inherit"
        document.getElementById("cargarPersonal").style.backgroundColor = "inherit"
        document.getElementById("cargarIncidencias").style.backgroundColor = "inherit"


    }

    componentWillUnmount() {
    }

    crearRecord() {
        document.getElementById("inputDateRecord").value = ""
        document.getElementById("idRecord").value = ""
        document.getElementById("msgErrorRecordServidor").style.display = "none"
        document.getElementById('inputTimeExit').value = ""
        document.getElementById('inputTimeEntry').value = ""
        document.getElementById("idUserRecord").value = this.iduserSelected.current.value
        document.getElementById('inputReason').value = ""
        document.getElementById('inputAdminModifier').value = ""
        document.getElementById('inputDateModified').value = ""
        document.getElementById('inputJustificacion').value = ""
        document.getElementById('btn-deleteMVL').style.display = "none"
        document.getElementById('divJustificacion').style.display = "none";
        document.getElementById('inputRetraso').checked = false;

        $("#inputDateRecord").prop('readonly', false);
        $('#modalFormRecord').modal('show');

    }

    showRecordsPrint() {
        if (this.imprimir == false) {
            document.getElementById("divImprimirTabla").style.visibility = "visible"
            var divContents = document.getElementById("tableRecords2").innerHTML;
            document.getElementById("tablaRecordsImprimir").innerHTML = divContents
            document.getElementById("botonImprimirDiv").onclick = this.printRecords
            document.getElementById("botonCerrarDiv").onclick = this.cerrarImprimir
            this.imprimir = true
        }

    }

    printRecords() {

        var divContents = document.getElementById("tableRecords2").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<head>');
        a.document.write('<style>');
        a.document.write('table,th{border:1px solid black;}');
        a.document.write('thead{color:white; background-color:black}');
        a.document.write('</style>');
        a.document.write('</head>');
        a.document.write("<body>");
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();

    }

    cerrarImprimir() {
        document.getElementById("divImprimirTabla").style.visibility = "hidden"
        this.imprimir = false
    }

    returnLocalDate(msg) {
        this.localDate = msg.body

        this.localDate = this.localDate.replaceAll('"', "")
        this.splitDate = this.localDate.split("-")
        this.anyo = this.splitDate[0]
        this.mes = this.splitDate[1]
        this.dia = this.splitDate[2]

        this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
        this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
        this.subsciptionHD_HA = this.props.stompClient.subscribe('/user/queue/recordHistorialByDate', this.returnCurrentRecords, { id: "subsciptionHD_HA" });
        this.props.stompClient.send("/app/historialByDate", {}, JSON.stringify({
            "iduser": "",
            "dateBefore": this.dateTargetBeforeRef.current.value,
            "dateAfter": this.dateTargetAfterRef.current.value,
        }));
        this.subsciptionLD_HA.unsubscribe("subsciptionLD_HA");

    }

    returnCurrentRecords(msg) {

        this.subsciptionHD_HA.unsubscribe("subsciptionHD_HA");

        ReactDOM.unmountComponentAtNode(document.getElementById('tableRecords'))
        ReactDOM.render(
            <CurrentRecords param={msg.body} canMod={true} users={this.JsonUsers[0]} stompClient={this.props.stompClient} modRecord={this.modRecord} />,
            document.getElementById('tableRecords')
        );
        this.search()
    }

    returnName(msg) {
        this.JsonUsers = JSON.parse(msg.body)
        this.subsciptionN_CR.unsubscribe("subsciptionN_CR");

        this.subsciptionLD_HA = this.props.stompClient.subscribe('/user/queue/localdateSubscribe', this.returnLocalDate, { id: "subsciptionLD_HA" });
        this.props.stompClient.send("/app/localdate", {});
    }

    modRecord(item) {
        document.getElementById("msgErrorRecordServidor").style.display = "none"
        this.tiempoEntrada = item.timeEntry.split(":")
        if (item.timeExit != null && item.timeExit != undefined) {
            this.tiempoSalida = item.timeExit.split(":")
            document.getElementById('inputTimeExit').value = this.tiempoSalida[0] + ":" + this.tiempoSalida[1] + ":" + this.tiempoSalida[2]
        } else {
            document.getElementById('inputTimeExit').value = ""
        }
        document.getElementById('inputTimeEntry').value = this.tiempoEntrada[0] + ":" + this.tiempoEntrada[1] + ":" + this.tiempoEntrada[2]
        document.getElementById('inputReason').value = item.reason
        document.getElementById('idUserRecord').value = item.iduser
        document.getElementById('idRecord').value = item.id
        document.getElementById('inputAdminModifier').value = this.JsonUsers[0][item.id_user_modifier]
        document.getElementById('inputDateModified').value = item.date_modify
        document.getElementById('inputJustificacion').value = item.tipo_retraso
        document.getElementById('btn-deleteMVL').style.display = "block"

        if (item.retraso) {
            document.getElementById('divJustificacion').style.display = "block";
            document.getElementById('inputRetraso').checked = true;
        } else {
            document.getElementById('divJustificacion').style.display = "none";
            document.getElementById('inputRetraso').checked = false;
        }
        $('#modalFormRecord').modal('show')
    }

    returnJSONModalRecord(callbackModalRecord) {
        this.jsonModalRecord = callbackModalRecord
    }

    returnRecordModified(msg) {
        this.subsciptionMR_HA.unsubscribe("subsciptionMR_HA");

        if (JSON.parse(msg.body).statusCodeValue == 500) {
            document.getElementById("msgErrorRecordServidor").style.display = "block"
        } else {
            document.getElementById("msgErrorRecordServidor").style.display = "none"
            $('#modalFormRecord').modal('hide')

            this.subsciptionHD_HA = this.props.stompClient.subscribe('/user/queue/recordHistorialByDate', this.returnCurrentRecords, { id: "subsciptionHD_HA" });
            this.props.stompClient.send("/app/historialByDate", {}, JSON.stringify({
                "dateBefore": this.dateTargetBeforeRef.current.value,
                "dateAfter": this.dateTargetAfterRef.current.value,
                "iduser": this.iduserSelected.current.value,
            }));
        }
    }

    modifiedRecord() {
        this.Json = this.jsonModalRecord()

        if (this.Json.error == undefined) {
            this.Json.id_user_modifier = this.props.adminInfo.id
            this.Json.date_modify = this.localDate;
            document.getElementById("msgErrorRecordNoValido").style.display = "none"

            if (document.getElementById("idRecord").value == undefined || document.getElementById("idRecord").value == null || document.getElementById("idRecord").value == "") {
                this.subsciptionMR_HA = this.props.stompClient.subscribe('/user/queue/createNewRecordAdminSubscribe', (msg) => {

                    if (JSON.parse(msg.body).statusCodeValue == 500) {
                        document.getElementById("msgErrorRecordServidor").style.display = "block"
                    } else {
                        document.getElementById("msgErrorRecordServidor").style.display = "none"
                        $('#modalFormRecord').modal('hide')
                    }

                }, { id: "subsciptionMR_HA" });
                this.props.stompClient.send("/app/createNewRecordAdmin", {}, JSON.stringify(this.Json));

            } else {
                this.subsciptionMR_HA = this.props.stompClient.subscribe('/user/queue/modifyRecordsSubscribe', this.returnRecordModified, { id: "subsciptionMR_HA" });
                this.props.stompClient.send("/app/modifyRecords", {}, JSON.stringify(this.Json));
            }

        } else {
            document.getElementById("msgErrorRecordNoValido").style.display = "block"
        }
    }

    selectUser(item) {

        $('#modalSearchUser').modal('hide')
        this.iduserSelected.current.value = item.id
    }

    changeToCustom(e) {
        this.selectOption.current.value = "Custom"
    }

    async search() {

        if (this.dateTargetAfterRef.current.value === "" || this.dateTargetBeforeRef.current.value === "" || this.iduserSelected.current.value === "") {

        } else {
            ReactDOM.unmountComponentAtNode(document.getElementById('tableRecords'))
            const limit = 100
            var offset = 0
            var continuar = true
            var arrayRecords = []
            var arrayTemporal = []
            var totalRecordscont = 0

            do {
                await new Promise((resolve) => {
                    this.props.stompClient.send("/app/historialByDate", {}, JSON.stringify({
                        "dateBefore": this.dateTargetBeforeRef.current.value,
                        "dateAfter": this.dateTargetAfterRef.current.value,
                        "iduser": this.iduserSelected.current.value,
                        "valorInicial": offset,
                        "limit": limit,
                    }));
                    this.subsciptionRHD_HR = this.props.stompClient.subscribe('/user/queue/recordHistorialByDate', (msg) => {
                        arrayTemporal = []
                        arrayTemporal = JSON.parse(msg.body)
                        totalRecordscont = totalRecordscont + arrayTemporal.length

                        if (!arrayTemporal.length == 0) {
                            offset += arrayTemporal.length
                            if (continuar) {
                                arrayRecords = arrayRecords.concat(arrayTemporal)
                            }

                            if (arrayTemporal.length < limit) {
                                continuar = false
                                arrayTemporal = []
                            }
                        } else {
                            continuar = false
                            arrayTemporal = []
                        }

                        setTimeout(() => {
                            resolve()
                        }, this.subsciptionRHD_HR.unsubscribe())

                    }, { id: "subsciptionRHD_HR" });

                });

            } while (continuar)

            ReactDOM.unmountComponentAtNode(document.getElementById('tableRecords'))

            ReactDOM.render(
                <CurrentRecords admin={this.admin} canMod={true} param={arrayRecords} users={this.JsonUsers[0]} stompClient={this.props.stompClient} modRecord={this.modRecord} totalRecordscont={totalRecordscont} />,
                document.getElementById('tableRecords')
            );
        }
    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    datesDefault(e) {
        this.date = new Date()

        this.mesMesPasado = this.date.getMonth()
        this.anyoMesPasado = this.date.getFullYear()

        if (this.mesMesPasado == 0) {
            this.mesMesPasado = 11
            this.anyoMesPasado = this.date.getFullYear() + 1
        }

        this.lenghtMes = new Date(this.anyoMesPasado, this.anyoMesPasado, 0).getDate();
        switch (e.target.value) {
            case 'ThisM':
                var d = new Date();

                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-01";
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + (this.daysInMonth(d.getMonth() + 1, d.getFullYear())).toString();
                break;

            case 'LastD':
                var d = new Date();
                d.setDate(d.getDate() - 1)
                if (d.getDate() < 10) {
                    var diaAnterior = "0" + d.getDate().toString()
                }
                else {
                    var diaAnterior = d.getDate().toString()
                }
                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + diaAnterior;
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + diaAnterior;

                break;

            case 'Today':
                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                break;

            case 'All':
                this.dateTargetBeforeRef.current.value = "1980-01-01";
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                break;

            case 'LastW':

                var dateWeekBefore = new Date();
                var dateWeekAfter = new Date();

                var pastWeekDate = dateWeekAfter.getDate() - this.date.getDay();
                dateWeekAfter.setDate(pastWeekDate);
                dateWeekAfter.setMonth(dateWeekAfter.getMonth() + 1)

                var pastWeekDate = dateWeekBefore.getDate() - this.date.getDay() - 6;
                dateWeekBefore.setDate(pastWeekDate);
                dateWeekBefore.setMonth(dateWeekBefore.getMonth() + 1)

                var diaLastWeek1 = dateWeekBefore.getDate().toString()
                var diaLastWeek2 = dateWeekAfter.getDate().toString()

                var mesLastWeek1 = dateWeekBefore.getMonth().toString()
                var mesLastWeek2 = dateWeekAfter.getMonth().toString()

                var anyoLastWeek1 = dateWeekBefore.getFullYear().toString()
                var anyoLastWeek2 = dateWeekAfter.getFullYear().toString()

                if (diaLastWeek1 <= 9) {
                    diaLastWeek1 = "0" + diaLastWeek1
                }

                if (diaLastWeek2 <= 9) {
                    diaLastWeek2 = "0" + diaLastWeek2
                }

                if (mesLastWeek1 <= 9) {
                    mesLastWeek1 = "0" + mesLastWeek1
                }

                if (mesLastWeek2 <= 9) {
                    mesLastWeek2 = "0" + mesLastWeek2
                }

                this.dateTargetBeforeRef.current.value = anyoLastWeek1 + "-" + mesLastWeek1 + "-" + diaLastWeek1
                this.dateTargetAfterRef.current.value = anyoLastWeek2 + "-" + mesLastWeek2 + "-" + diaLastWeek2


                break;

            case 'LastM':
                var d = new Date();
                d.setMonth(d.getMonth() - 1);

                if ((parseInt(this.mes) - 1) != 0) {
                    if ((parseInt(this.mes) - 1) <= 9) {
                        this.dateTargetBeforeRef.current.value = this.anyo + "-0" + (parseInt(this.mes) - 1) + "-01";
                        this.dateTargetAfterRef.current.value = this.anyo + "-0" + (parseInt(this.mes) - 1) + "-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                    } else {

                        this.dateTargetBeforeRef.current.value = this.anyo + "-" + (parseInt(this.mes) - 1) + "-01";
                        this.dateTargetAfterRef.current.value = this.anyo + "-" + (parseInt(this.mes) - 1) + "-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                    }
                } else {

                    this.dateTargetBeforeRef.current.value = (parseInt(this.anyo) - 1) + "-12-01";
                    this.dateTargetAfterRef.current.value = (parseInt(this.anyo) - 1) + "-12-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                }
                break;

            case 'LastY':
                this.dateTargetBeforeRef.current.value = (parseInt(this.anyo) - 1) + "-01-01";
                this.dateTargetAfterRef.current.value = (parseInt(this.anyo) - 1) + "-12-31";

                break;

            default:
                break;

        }

        this.search()

    }

    deleteRecord() {
        this.Json = this.jsonModalRecord()
        this.subsciptionDNWR_HA = this.props.stompClient.subscribe('/user/queue/deleteRecordByIdSubscribe', this.deleteRecordReturn, { id: "subsciptideleteRecordReturnonDNWR_HA" });
        this.props.stompClient.send("/app/deleteRecordById", {}, JSON.stringify(this.Json));

        $('#modalFormRecord').modal('show')
    }

    deleteRecordReturn() {

        this.subsciptionDNWR_HA.unsubscribe("subsciptideleteRecordReturnonDNWR_HA");
        this.search()
        $('#modalFormRecord').modal('hide')
    }

    render() {
        return (
            <div>
                <div id="divImprimirTabla">
                    <div>
                        <div className="form-group row">
                            <div className="col-sm-3 form-group row">
                                <button type="button" className="btn btn-primary botonImprimir" id="botonImprimirDiv">Imprimir</button>
                            </div>
                            <div className="col-sm-1 form-group row">
                                <button type="button" className="btn btn-primary botonImprimir" id="botonCerrarDiv" >Cerrar</button>
                            </div>

                        </div>
                        <div id="tablaRecordsImprimir">

                        </div>
                    </div>
                </div>

                <h1 id="divPersonal">Historial</h1>
                <div class="form-group row" id="searchHistorialAdmin">
                    <div className="col-sm-3 form-group row">
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalSearchUser">Seleccionar Empleado</button>
                        <label for="colFormLabelSm" class="col-sm-2 col-form-label ">Id</label>
                        <input type="number" class="form-control dateSearchHistorial col-sm-3" ref={this.iduserSelected} defaultValue="0" />
                    </div>
                    <div className="col-sm-1.5 form-group row">
                        <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" onChange={this.datesDefault} ref={this.selectOption}>
                            <option value="Today">Hoy</option>
                            <option value="ThisM">Este mes</option>
                            <option value="All">Todos los Tiempos</option>
                            <option value="LastD">Ayer</option>
                            <option value="LastW">La semana Pasada</option>
                            <option value="LastM">El mes Pasado</option>
                            <option value="LastY">Año Pasado</option>
                            <option disabled>Custom</option>
                        </select>
                    </div>

                    <div className="col-sm-3 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Inicio</label>
                        <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetBeforeRef} onClick={this.changeToCustom} />
                    </div>
                    <div className="col-sm-3 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Fin</label>
                        <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetAfterRef} onClick={this.changeToCustom} />
                    </div>
                    <div className="col-sm-1 form-group row">
                        <button type="button" className="btn btn-primary" onClick={this.search}>Buscar</button>
                    </div>
                    <div className="col-sm-1 form-group row">
                        <button type="button" className="btn btn-primary" onClick={this.showRecordsPrint}>Imprimir</button>
                    </div>
                    <div className="col-sm-1 form-group row">
                        <button type="button" className="btn btn-primary" onClick={this.crearRecord}>Crear</button>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div id="tableRecords">

                </div>
                <div id="totalesFichajesAdmin">

                </div>
                <div id="modalRecord">

                </div>
                <div id="modalSearchHistorial">

                </div>
            </div>
        );
    }
}

export default HistorialAdmin;
