import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';
import ModalSearchUser from '../formPersonalComponents/modalesForm/ModalSearchUser';
import '../../css/HistorialAdmin.css';
import TableHorasAcumuladasDay from "../formPersonalComponents/tablasForm/TablaHorasAcumuladasDay";
import ModalDailyRecord from "../formPersonalComponents/modalesForm/ModalDailyRecord"

class HistorialCumulativeHoursDay extends Component {

    constructor(props) {
        super(props);

        this.returnName = this.returnName.bind(this);
        this.search = this.search.bind(this);
        this.changeToCustom = this.changeToCustom.bind(this);
        this.datesDefault = this.datesDefault.bind(this);
        this.daysInMonth = this.daysInMonth.bind(this);
        this.returnLocalDate = this.returnLocalDate.bind(this);
        this.nameSelected = "";
        this.selectUser = this.selectUser.bind(this);


        this.iduserSelected = React.createRef()
        this.dateTargetBeforeRef = React.createRef()
        this.dateTargetAfterRef = React.createRef()
        this.selectOption = React.createRef()

    }

    componentDidMount() {

        document.getElementById("cargarDepartamentos").style.backgroundColor = "inherit"
        document.getElementById("cargarHistorial").style.backgroundColor = "#2F6D6C"
        document.getElementById("cargarHistorial").style.borderRadius = "22px"
        document.getElementById("cargarHistorial").style.paddingLeft = "10%"
        document.getElementById("cargarHistorial").style.paddingRight = "10%"
        document.getElementById("cargarFestivos").style.backgroundColor = "inherit"
        document.getElementById("cargarPersonal").style.backgroundColor = "inherit"
        document.getElementById("cargarIncidencias").style.backgroundColor = "inherit"


        ReactDOM.unmountComponentAtNode(document.getElementById('modalSearch'))
        ReactDOM.render(
            <ModalSearchUser stompClient={this.props.stompClient} selectUser={this.selectUser} />,
            document.getElementById('modalSearch')
        );

        ReactDOM.unmountComponentAtNode(document.getElementById("modalRecords"))
        ReactDOM.render(
            <ModalDailyRecord stompClient={this.props.stompClient}/>,
            document.getElementById("modalRecords")
        )
        this.subsciptionN_CR = this.props.stompClient.subscribe('/user/queue/getAllUsersNameAndIdSubscribe', this.returnName, { id: "subsciptionN_CR" });
        this.props.stompClient.send("/app/getAllUsersNameAndId", {});

    }
    returnName(msg) {
        this.JsonUsers = JSON.parse(msg.body)
        this.subsciptionN_CR.unsubscribe("subsciptionN_CR");
    }
    componentWillUnmount() {
    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    async search() {
        if (this.dateTargetAfterRef.current.value === "" || this.dateTargetBeforeRef.current.value === "" || this.iduserSelected.current.value === "") {

        } else {
            ReactDOM.unmountComponentAtNode(document.getElementById('tableDailyWorked'))
            const limit = 100
            var offset = 0
            var continuar = true
            var arrayRecords = []
            var arrayTemporal = []
            do {
                await new Promise((resolve) => {
                    this.props.stompClient.send("/app/returnTableDailyHours", {}, JSON.stringify({
                        "dateBefore": this.dateTargetBeforeRef.current.value,
                        "dateAfter": this.dateTargetAfterRef.current.value,
                        "iduser": this.iduserSelected.current.value,
                        "valorInicial": offset,
                        "limit": limit,
                    }));
                    this.subsciptionTMH_GCHD = this.props.stompClient.subscribe('/user/queue/returnTableDailyHoursSubscribe', (msg) => {
                        arrayTemporal = []
                        arrayTemporal = JSON.parse(msg.body)

                        if (!arrayTemporal.length == 0) {
                            offset += arrayTemporal.length
                            if (continuar) {
                                arrayRecords = arrayRecords.concat(arrayTemporal)
                            }

                            if (arrayTemporal.length < limit) {
                                continuar = false
                                arrayTemporal = []
                            }
                        } else {
                            continuar = false
                            arrayTemporal = []
                        }

                        setTimeout(() => {
                            resolve()
                        }, this.subsciptionTMH_GCHD.unsubscribe())

                    }, { id: "subsciptionTMH_GCHD" });
                });
            } while (continuar)
            ReactDOM.unmountComponentAtNode(document.getElementById('tableDailyWorked'))
            ReactDOM.render(
                <TableHorasAcumuladasDay users={this.JsonUsers[0]} param={arrayRecords} name={this.nameSelected} selectUser={this.selectUser} stompClient={this.props.stompClient} />,
                document.getElementById('tableDailyWorked')
            );
        }
    }

    changeToCustom(e) {
        this.selectOption.current.value = "Custom"
    }


    returnName(msg) {
        this.JsonUsers = JSON.parse(msg.body)
        this.subsciptionN_CR.unsubscribe("subsciptionN_CR");

        this.subsciptionLD_HA = this.props.stompClient.subscribe('/user/queue/localdateSubscribe', this.returnLocalDate, { id: "subsciptionLD_HA" });
        this.props.stompClient.send("/app/localdate", {});


    }
    selectUser(item) {
        this.iduserSelected.current.value = item.id
        this.nameSelected = item.nombre


        $('#modalSearchUser').modal('hide')

    }

    returnLocalDate(msg) {
        this.localDate = msg.body

        this.localDate = this.localDate.replaceAll('"', "")
        this.splitDate = this.localDate.split("-")
        this.anyo = this.splitDate[0]
        this.mes = this.splitDate[1]
        this.dia = this.splitDate[2]

        this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
        this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
        this.subsciptionHD_HA = this.props.stompClient.subscribe('/user/queue/recordHistorialByDate', this.returnCurrentRecords, { id: "subsciptionHD_HA" });
        this.props.stompClient.send("/app/historialByDate", {}, JSON.stringify({
            "iduser": "",
            "dateBefore": this.dateTargetBeforeRef.current.value,
            "dateAfter": this.dateTargetAfterRef.current.value,
        }));
        this.subsciptionLD_HA.unsubscribe("subsciptionLD_HA");

        this.search()
    }

    datesDefault(e) {
        this.date = new Date()

        this.mesMesPasado = this.date.getMonth()
        this.anyoMesPasado = this.date.getFullYear()

        if (this.mesMesPasado == 0) {
            this.mesMesPasado = 11
            this.anyoMesPasado = this.date.getFullYear() + 1
        }

        this.lenghtMes = new Date(this.anyoMesPasado, this.anyoMesPasado, 0).getDate();
        switch (e.target.value) {
            case 'ThisM':
                var d = new Date();

                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-01";
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + (this.daysInMonth(d.getMonth() + 1, d.getFullYear())).toString();
                break;

            case 'LastD':
                var d = new Date();
                d.setDate(d.getDate() - 1)
                if (d.getDate() < 10) {
                    var diaAnterior = "0" + d.getDate().toString()
                }
                else {
                    var diaAnterior = d.getDate().toString()
                }
                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + diaAnterior;
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + diaAnterior;

                break;

            case 'Today':
                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                break;

            case 'All':
                this.dateTargetBeforeRef.current.value = "1980-01-01";
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                break;

            case 'LastW':

                var dateWeekBefore = new Date();
                var dateWeekAfter = new Date();

                var pastWeekDate = dateWeekAfter.getDate() - this.date.getDay();
                dateWeekAfter.setDate(pastWeekDate);
                dateWeekAfter.setMonth(dateWeekAfter.getMonth() + 1)

                var pastWeekDate = dateWeekBefore.getDate() - this.date.getDay() - 6;
                dateWeekBefore.setDate(pastWeekDate);
                dateWeekBefore.setMonth(dateWeekBefore.getMonth() + 1)

                var diaLastWeek1 = dateWeekBefore.getDate().toString()
                var diaLastWeek2 = dateWeekAfter.getDate().toString()

                var mesLastWeek1 = dateWeekBefore.getMonth().toString()
                var mesLastWeek2 = dateWeekAfter.getMonth().toString()

                var anyoLastWeek1 = dateWeekBefore.getFullYear().toString()
                var anyoLastWeek2 = dateWeekAfter.getFullYear().toString()

                if (diaLastWeek1 <= 9) {
                    diaLastWeek1 = "0" + diaLastWeek1
                }

                if (diaLastWeek2 <= 9) {
                    diaLastWeek2 = "0" + diaLastWeek2
                }

                if (mesLastWeek1 <= 9) {
                    mesLastWeek1 = "0" + mesLastWeek1
                }

                if (mesLastWeek2 <= 9) {
                    mesLastWeek2 = "0" + mesLastWeek2
                }

                this.dateTargetBeforeRef.current.value = anyoLastWeek1 + "-" + mesLastWeek1 + "-" + diaLastWeek1
                this.dateTargetAfterRef.current.value = anyoLastWeek2 + "-" + mesLastWeek2 + "-" + diaLastWeek2


                break;

            case 'LastM':
                var d = new Date();
                d.setMonth(d.getMonth() - 1);

                if ((parseInt(this.mes) - 1) != 0) {
                    if ((parseInt(this.mes) - 1) <= 9) {
                        this.dateTargetBeforeRef.current.value = this.anyo + "-0" + (parseInt(this.mes) - 1) + "-01";
                        this.dateTargetAfterRef.current.value = this.anyo + "-0" + (parseInt(this.mes) - 1) + "-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                    } else {

                        this.dateTargetBeforeRef.current.value = this.anyo + "-" + (parseInt(this.mes) - 1) + "-01";
                        this.dateTargetAfterRef.current.value = this.anyo + "-" + (parseInt(this.mes) - 1) + "-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                    }
                } else {

                    this.dateTargetBeforeRef.current.value = (parseInt(this.anyo) - 1) + "-12-01";
                    this.dateTargetAfterRef.current.value = (parseInt(this.anyo) - 1) + "-12-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                }
                break;

            case 'LastY':
                this.dateTargetBeforeRef.current.value = (parseInt(this.anyo) - 1) + "-01-01";
                this.dateTargetAfterRef.current.value = (parseInt(this.anyo) - 1) + "-12-31";

                break;

            default:
                break;

        }
        this.search()
    }

    render() {
        return (
            <div>
                <h1 id="divPersonal">Historial</h1>
                <div class="form-group row" id="searchHistorialAdmin">
                    <div className="col-sm-3 form-group row">
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalSearchUser">Seleccionar Empleado</button>
                        <label for="colFormLabelSm" class="col-sm-2 col-form-label ">Id</label>
                        <input type="number" class="form-control dateSearchHistorial col-sm-3" ref={this.iduserSelected} defaultValue={0} />
                    </div>
                    <div className="col-sm-1.5 form-group row">
                        <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" onChange={this.datesDefault} ref={this.selectOption}>
                            <option value="Today">Hoy</option>
                            <option value="ThisM">Este mes</option>
                            <option value="All">Todos los Tiempos</option>
                            <option value="LastD">Ayer</option>
                            <option value="LastW">La semana Pasada</option>
                            <option value="LastM">El mes Pasado</option>
                            <option value="LastY">Año Pasado</option>
                            <option disabled>Custom</option>
                        </select>
                    </div>

                    <div className="col-sm-3 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Inicio</label>
                        <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetBeforeRef} onClick={this.changeToCustom} />
                    </div>
                    <div className="col-sm-3 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Fin</label>
                        <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetAfterRef} onClick={this.changeToCustom} />
                    </div>

                    <div className="col-sm-1 form-group row">
                        <button type="button" className="btn btn-primary" onClick={this.search}>Buscar</button>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div id="tableDailyWorked">

                </div>
                <div id="totales">
                </div>
                <div id="modalSearch">

                </div>
                <div id="modalRecords">

                </div>


            </div>
        );
    }
}

export default HistorialCumulativeHoursDay;
