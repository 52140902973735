import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ModalAuditoria from './modalesForm/ModalAuditoria';
import ModalGeneratePasswd from './modalesForm/ModalGeneratePasswd';
import $ from 'jquery';
import '../../css/formPersonal/DatosUser.css';
import ModalBorrarUsuario from "./modalesForm/ModalBorrarUsuario";
import ModalErrorModify from "./modalesForm/ModalErrorModify";

class DatosUser extends Component {

    constructor(props) {
        super(props);
        this.user = this.props.user
        this.radioSelected = this.radioSelected.bind(this);
        this.sendUserInfo = this.sendUserInfo.bind(this);
        this.returnInfoUser = this.returnInfoUser.bind(this);
        this.generatePasswd = this.generatePasswd.bind(this);
        this.returnGeneratePasswd = this.returnGeneratePasswd.bind(this);

        this.calculateDepartaments = this.calculateDepartaments.bind(this)
        this.getAllDepartments = this.getAllDepartments.bind(this);
        this.guardarDepartamentos = this.guardarDepartamentos.bind(this);
        this.isUserAdmin = this.isUserAdmin.bind(this)
        this.getMainAddress = this.getMainAddress.bind(this)
        this.getJornadas = this.getJornadas.bind(this)
        this.seleccionarTipoJornada = this.seleccionarTipoJornada.bind(this)
        this.returnIdJornadaByName = this.returnIdJornadaByName.bind(this)

        this.inputNombre = React.createRef();
        this.inputNombreUsuario = React.createRef();
        this.inputApellidoP = React.createRef();
        this.inputApellidoS = React.createRef();
        this.inputFechaNac = React.createRef();
        this.inputFechaAnt = React.createRef();
        this.inputNif = React.createRef();
        this.inputSegSoc = React.createRef();
        this.inputSexo = React.createRef();
        this.inputDireccion = React.createRef();
        this.inputPais = React.createRef();
        this.inputTelefono = React.createRef();
        this.inputDepartamento = React.createRef();
        this.inputOff = React.createRef();
        this.radioButtonsDepartamentos = React.createRef();
        this.inputEmail = React.createRef();
        this.refSelectJornadas = React.createRef();

        this.idJornada = 0

        this.stateChanged = (estado) => {
            this.user.off = estado
        }

    }
    componentDidMount() {

        document.getElementById("botonModificarUsuario").disabled = false

        this.subsciptionGU_DU = this.props.stompClient.subscribe('/user/queue/getUserByIdSubscribe', this.returnInfoUser, { id: "subsciptionGU_DU" });
        this.props.stompClient.send("/app/getUserById", {}, JSON.stringify(this.sendUserInfo()));

        this.getAllDepartments()
        this.getJornadas()

        ReactDOM.render(
            <ModalBorrarUsuario deleteUser={this.props.deleteUser} sendUserInfo={this.sendUserInfo} nombreUsuario={this.user.nombre_usuario} />,
            document.getElementById('modalBorrarUsuario')
        );

        this.isUserAdmin()

    }

    isUserAdmin() {
        if (this.user.admin == true) {
            document.getElementById("administradorcb").checked = true
        }
        else {
            document.getElementById("administradorcb").checked = false
        }
    }

    componentWillUnmount() {

    }

    getMainAddress() {
        this.subsciptionGU_GMA = this.props.stompClient.subscribe('/user/queue/getMainAddressSubscribe', (msg) => {
            this.subsciptionGU_GMA.unsubscribe("subsciptionGU_GMA")

            let address = JSON.parse(msg.body)

            this.inputDireccion.current.value = address.address
            this.inputPais.current.value = address.pais

        }, { id: "subsciptionGU_GMA" });
        this.props.stompClient.send("/app/getMainAddress", {}, JSON.stringify({
            "id": this.user.id,
        }));

    }

    getAllDepartments() {

        this.subsciptionGU_GAD = this.props.stompClient.subscribe('/user/queue/getAllDepartmentsSubscribe', (msg) => {
            this.subsciptionGU_GAD.unsubscribe("subsciptionGU_GAD")

            this.listaDepartamentos = JSON.parse(msg.body)

            const listaItemsDepartamentos = JSON.parse(msg.body).map(item => (
                <div>
                    <input className="radioButtonListaDepartamentos" type="checkbox" value={item.nombre} />
                    <label>{item.nombre}</label>
                </div>
            ))

            ReactDOM.unmountComponentAtNode(this.radioButtonsDepartamentos.current)
            ReactDOM.render(listaItemsDepartamentos, this.radioButtonsDepartamentos.current)
            this.calculateDepartaments()

        }, { id: "subsciptionGU_GAD" });
        this.props.stompClient.send("/app/getAllDepartments", {}, JSON.stringify(this.sendUserInfo()));

    }

    calculateDepartaments() {

        this.subsciptionGU_GADBU = this.props.stompClient.subscribe('/user/queue/getAllDepartmentsSubscribeByUser', (msg) => {
            this.subsciptionGU_GADBU.unsubscribe("subsciptionGU_GADBU")
            this.listaDepartamentosDelUsuario = JSON.parse(msg.body)

            let elementosDepartamentos = document.getElementsByClassName('radioButtonListaDepartamentos')

            for (let i = 0; i < elementosDepartamentos.length; i++) {
                for (let x = 0; x < this.listaDepartamentosDelUsuario.length; x++) {
                    if (elementosDepartamentos[i].value == this.listaDepartamentosDelUsuario[x].nombre) {
                        elementosDepartamentos[i].checked = true;
                    }
                }
            }
        }, { id: "subsciptionGU_GADBU" });
        this.props.stompClient.send("/app/getAllDepartmentsByUser", {}, JSON.stringify(this.sendUserInfo()));
    }

    guardarDepartamentos() {

        document.getElementById("botonModificarUsuario").disabled = true

        if (this.inputNombre.current.value == "" || this.inputApellidoP.current.value == "" || this.inputNombreUsuario.current.value == "") {
            ReactDOM.render(
                <ModalErrorModify textoError="Ha ocurrido un error al modificar el usuario, compruebe los campos primarios" />,
                document.getElementById('modalErrorModificar')
            );
            $('#modalFormErrorModify').modal('show');
        } else {
            let elementosDepartamentos = document.getElementsByClassName('radioButtonListaDepartamentos')

            for (var i = 0; i < elementosDepartamentos.length; i++) {
                if (elementosDepartamentos[i].checked) {
                    this.subsciptionGU_SNDBY = this.props.stompClient.subscribe('/user/queue/saveNewDepartmentsByUserSubscribe', (msg) => {
                        this.subsciptionGU_SNDBY.unsubscribe("subsciptionGU_SNDBY")
                        this.props.modifyUser(this.sendUserInfo());
                    }, { id: "subsciptionGU_SNDBY" });
                    this.props.stompClient.send("/app/saveNewDepartmentsByUser", {}, JSON.stringify({
                        "iduser": this.user.id,
                        "iddepartamento": this.listaDepartamentos[i].id,
                    }));

                } else {
                    this.subsciptionGU_RDBY = this.props.stompClient.subscribe('/user/queue/removeDepartmentsByUserSubscribe', (msg) => {
                        this.subsciptionGU_RDBY.unsubscribe("subsciptionGU_SNDBY")
                        this.props.modifyUser(this.sendUserInfo());
                    }, { id: "subsciptionGU_RDBY" });
                    this.props.stompClient.send("/app/removeDepartmentsByUser", {}, JSON.stringify({
                        "iduser": this.user.id,
                        "iddepartamento": this.listaDepartamentos[i].id,
                    }));
                }
            }
        }
    }

    radioSelected(e) {
    }

    getJornadas() {

        this.subsciptionGU_GAJ = this.props.stompClient.subscribe('/user/queue/getAllJornadasSubscribe', (msg) => {
            this.subsciptionGU_GAJ.unsubscribe("subsciptionGU_GAJ")

            this.listaJornadas = JSON.parse(msg.body)

            const listaRenderJornadas = this.listaJornadas.map(item => <option value={item.tipo}>{item.tipo}</option>)

            ReactDOM.unmountComponentAtNode(this.refSelectJornadas.current)
            ReactDOM.render(listaRenderJornadas, this.refSelectJornadas.current)

            this.seleccionarTipoJornada()


        }, { id: "subsciptionGU_GAJ" });
        this.props.stompClient.send("/app/getAllJornadas", {}, JSON.stringify({}));
    }

    seleccionarTipoJornada() {

        this.subsciptionGU_GJBU = this.props.stompClient.subscribe('/user/queue/getJornadaByUserSubscribe', (msg) => {
            this.subsciptionGU_GJBU.unsubscribe("subsciptionGU_GJBU")
            this.jornadaActual = JSON.parse(msg.body)

            document.getElementById("selectJornadas").value = this.jornadaActual.tipo

        }, { id: "subsciptionGU_GJBU" });
        this.props.stompClient.send("/app/getJornadaByUser", {}, JSON.stringify({
            "jornada": this.user.jornada

        }));

    }

    returnInfoUser(msg) {
        this.userDatos = JSON.parse(msg.body)
        if (this.userDatos.apellidos != null) {

            this.apellidos = this.userDatos.apellidos.split(" ")
            this.apellidoP = this.apellidos[0]
            this.apellidoS = this.apellidos[1]

            if (this.apellidoS == undefined){
                this.apellidoS = ""
            }

        } else if (this.apellidos == null) {

            this.apellidoP = ""
            this.apellidoS = ""
        }
        this.inputNombre.current.value = this.userDatos.nombre
        this.inputNombreUsuario.current.value = this.userDatos.nombre_usuario
        this.inputApellidoP.current.value = this.apellidoP
        this.inputApellidoS.current.value = this.apellidoS
        this.inputFechaNac.current.value = this.userDatos.fecha_nacimiento
        this.inputFechaAnt.current.value = this.userDatos.fecha_antiguedad
        this.inputNif.current.value = this.userDatos.nif
        this.inputSegSoc.current.value = this.userDatos.num_seg_social
        this.inputSexo.current.value = this.userDatos.sexo

        ReactDOM.unmountComponentAtNode(document.getElementById('modalAuditoria'))
        ReactDOM.render(
            < ModalAuditoria stompClient={this.props.stompClient} stateChanged={this.stateChanged} user={this.userDatos} adminInfo={this.props.adminInfo} modifyUserState={this.props.modifyUserState} />,
            document.getElementById('modalAuditoria')
        );
        ReactDOM.unmountComponentAtNode(document.getElementById('modalPasswd'))
        ReactDOM.render(
            < ModalGeneratePasswd user={this.userDatos} adminInfo={this.props.adminInfo} isAdmin={true} generatePasswd={this.generatePasswd} />,
            document.getElementById('modalPasswd')
        );

    }
    generatePasswd(passwd, generar) {
        this.generarPasswd = generar
        this.jsonInfo = this.sendUserInfo();
        this.jsonInfo.passwd = passwd;

        this.subsciptionGP_DU = this.props.stompClient.subscribe('/user/queue/generatePasswdSubscribe', this.returnGeneratePasswd, { id: "subsciptionGP_DU" });
        this.props.stompClient.send("/app/generatePasswd", {}, JSON.stringify(this.jsonInfo));
        $('#modalFormPasswd').modal('hide')
    }
    returnGeneratePasswd(msg) {

        this.subsciptionGP_DU.unsubscribe("subsciptionGP_DU");
    }

    returnIdJornadaByName() {
        this.subsciptionGU_GIJ = this.props.stompClient.subscribe('/user/queue/getIdJornadaSubscribe', (msg) => {
            this.subsciptionGU_GIJ.unsubscribe("subsciptionGU_GIJ")

            this.idJornada = msg.body



        }, { id: "subsciptionGU_GIJ" });
        this.props.stompClient.send("/app/getIdJornada", {}, JSON.stringify({
            "tipo": document.getElementById("selectJornadas").value
        }));

    }

    sendUserInfo() {

        this.userInfo = {
            "id": this.user.id,
            "nombre": this.inputNombre.current.value,
            "nombre_usuario": this.inputNombreUsuario.current.value,
            "apellidos": this.inputApellidoP.current.value + " " + this.inputApellidoS.current.value,
            "fecha_nacimiento": this.inputFechaNac.current.value,
            "fecha_antiguedad": this.inputFechaAnt.current.value,
            "nif": this.inputNif.current.value,
            "num_seg_social": this.inputSegSoc.current.value,
            "sexo": this.inputSexo.current.value,
            "passwd": "",
            "restablecerPasswd": this.generarPasswd,
            "admin": document.getElementById("administradorcb").checked,
            "jornada": this.idJornada

        }
        return this.userInfo;
    }
    render() {
        return (
            <div id="formDatos">
                <div id="bodyFormDatos">

                    <div id="userDatos">
                        <div>
                            <label >Nombre</label>
                            <input id="nombre" className="inputAll" type="text" ref={this.inputNombre} defaultValue={this.user.nombre} />
                        </div>
                        <div>
                            <label>Apellidos</label>
                            <input type="text" ref={this.inputApellidoP} defaultValue={this.apellidoP} />
                            <input type="text" ref={this.inputApellidoS} defaultValue={this.apellidoS} />
                        </div>
                        <div>
                            <label >Nombre Usuario</label>
                            <input id="nombre" className="inputAll" type="text" ref={this.inputNombreUsuario} defaultValue={this.user.nombre_usuario} />
                        </div>
                        <div>
                            <label>Fecha Nacimiento</label>
                            <input type="date" ref={this.inputFechaNac} defaultValue={this.user.fecha_nacimiento} />
                            <label>Fecha Antiguedad</label>
                            <input type="date" ref={this.inputFechaAnt} defaultValue={this.user.fecha_antiguedad} />
                        </div>
                        <div>
                            <label>NIF</label>
                            <input id ="divNif"type="text" ref={this.inputNif} defaultValue={this.user.nif} />
                            <label className="minWidthNif">Nº Seg Social</label>
                            <input id="divNif" type="text" ref={this.inputSegSoc} defaultValue={this.user.seg_soc} />
                            <label className="minWidthNif">Sexo</label>
                            <input id="divNif" type="text" ref={this.inputSexo} defaultValue={this.user.sexo} />
                        </div>
                        <div>
                            <label>Dirección</label>
                            <input type="text" ref={this.inputDireccion} onLoad={this.getMainAddress()} readOnly />
                        </div>
                        <div>
                            <label>Pais</label>
                            <input type="text" ref={this.inputPais} onLoad={this.user.pais} readOnly />
                            <label>Zona Geografica</label>
                            <input type="text" value="34" />
                        </div>
                        <div>
                            <label>Telefono</label>
                            <input type="text" ref={this.inputTelefono} value={this.user.telefono} readOnly />
                        </div>
                        <div>
                            <label>Email</label>
                            <input className="inputAll" type="text" ref={this.inputEmail} value={this.user.email} readOnly />
                        </div>
                        <div>
                            <label>Administrador</label>
                            <input id="administradorcb" type="checkbox" />
                        </div>
                        <div id="divJornadas">
                            <select id="selectJornadas" class="custom-select mr-sm-2" ref={this.refSelectJornadas} onChange={this.returnIdJornadaByName}></select>

                        </div>
                    </div>

                    <div id="userType">
                        <div id="divRadios" ref={this.radioButtonsDepartamentos}>
                        </div>
                    </div>
                </div>

                <div id="btnFooter">

                    <button type="button" class="btn btn-danger btn-lg" data-toggle="modal" data-target="#modalFormBorrarUsuario">Borrar Usuario</button>
                    <button type="button" class="btn btn-warning btn-lg" data-toggle="modal" data-target="#modalFormPasswd">Restablecer passwd</button>
                    <button type="button" class="btn btn-primary btn-lg" data-toggle="modal" data-target="#modalFormAuditoria">Auditoria</button>
                    <button type="button" class="btn btn-primary btn-lg" id="botonModificarUsuario" onClick={() => { this.guardarDepartamentos(); }}>Modificar</button>

                    <div id="modalAuditoria">

                    </div>
                    <div id="modalPasswd">

                    </div>
                    <div id="modalBorrarUsuario">

                    </div>
                    <div id="modalErrorModificar">

                    </div>

                </div>

            </div>
        );
    }
}

export default DatosUser;
