import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalDailyRecord extends Component {

    constructor(props) {
        super(props);
        this.user = this.props.user

    }

    componentDidMount(){

    }

    render() {
        return (
            <div class="modal fade" id="modalFormRecordsDaily" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Fichajes por dia</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">
                            <div id="TableRecordsSon">
                                
                            </div>

                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalDailyRecord;
