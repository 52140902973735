import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalChangePasswd extends Component {

    constructor(props) {
        super(props);
        this.returnPassword = this.returnPassword.bind(this);

        this.inputPasswd = React.createRef();
        this.inputPasswdAnt = React.createRef();
        this.inputPasswdRepeat = React.createRef();

    }

    componentDidMount() {

    }

    returnPassword() {

        if (this.inputPasswd.current.value == this.inputPasswdRepeat.current.value) {
            if (this.inputPasswd.current.value == "") {
                document.getElementById("msgErrorContraseñaVacia").style.display = "block"
                document.getElementById('msgErrorContraseñaNoValida').style.display = "none";
                document.getElementById("msgErrorContraseñaNoCoincide").style.display = "none"
            } else {
                if (this.props.isAdmin == false) {
                    document.getElementById("msgErrorContraseñaNoCoincide").style.display = "none"
                    document.getElementById("msgErrorContraseñaVacia").style.display = "none"
                    if (this.inputPasswd.current.value && this.inputPasswdAnt.current.value != "") {
                        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[ A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~€]{6,}$/.test(this.inputPasswd.current.value)) {
                            this.props.checkPasswd(this.inputPasswdAnt.current.value, this.inputPasswd.current.value)
                        } else {
                            document.getElementById('msgErrorContraseñaNoValida').style.display = "block";
                        }
                    }

                } else {
                    if (this.inputPasswd.current.value && this.inputPasswdAnt.current.value != "") {
                        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[ A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~€]{6,}$/.test(this.inputPasswd.current.value)) {
                            this.props.checkPasswd(this.inputPasswdAnt.current.value, this.inputPasswd.current.value)

                        } else {
                            document.getElementById('msgErrorContraseñaNoValida').style.display = "block";
                        }

                    }

                }
            }

        } else {
            document.getElementById("msgErrorContraseñaVacia").style.display = "none"
            document.getElementById('msgErrorContraseñaNoValida').style.display = "none";
            document.getElementById("msgErrorContraseñaNoCoincide").style.display = "block"
        }
    }

    render() {
        return (
            <div class="modal fade" id="modalFormChangePasswd" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Cambiar Contraseña</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>La contraseña tiene que tener como mínimo, una letra, mayúscula y minúscula, un número y seis caracteres</p>
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Antigua Contraseña</label>
                                <div class="col-sm-9">
                                    <input type="password" class="form-control" id="colFormLabel" ref={this.inputPasswdAnt} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Nueva Contraseña</label>
                                <div class="col-sm-9">
                                    <input type="password" class="form-control" id="colFormLabel" ref={this.inputPasswd} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Repite la Nueva Contraseña</label>
                                <div class="col-sm-9">
                                    <input type="password" class="form-control" id="colFormLabel" ref={this.inputPasswdRepeat} />
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <div>
                                <p id="msgErrorContraseñaAnteriorNoValida" style={{ color: 'red', display: 'none', marginTop: "10px" }}>*Error, la contraseña anterior no és valida</p>
                                <p id="msgErrorContraseñaNoCoincide" style={{ color: 'red', display: 'none', marginTop: "10px" }}>*Error, las contraseñas no coinciden</p>
                                <p id="msgErrorContraseñaVacia" style={{ color: 'red', display: 'none', marginTop: "10px" }}>*Error, La contraseña está vacia</p>
                                <p id="msgErrorContraseñaNoValida" style={{ color: 'red', display: 'none', marginTop: "10px" }}>*Error, La contraseña debe cumplir los requisitos</p>
                            </div>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary" onClick={this.returnPassword}>Restablecer Contraseña</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalChangePasswd;
