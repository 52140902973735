import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalDirecciones extends Component {

    constructor(props) {
        super(props);
        this.returnCpTable = this.returnCpTable.bind(this)
        this.returnAdressTable = this.returnAdressTable.bind(this)
    }

    returnCpTable(item) {
        if (item.principal) {
            return (
                <th>{item.cod_postal}</th>
            )
        } else {
            return (
                <td>{item.cod_postal}</td>
            )

        }
    }
    returnAdressTable(item) {
        if (item.principal) {
            return (
                <th>{item.address}</th>
            )
        }
        else {
            return (
                <td>{item.address}</td>
            )
        }

    }

    render() {
        return (
            <div class="table-wrapper" id="tableDirecciones">
                <table class="table">
                    <thead class="table-dark">
                        <tr >
                            <th>CP</th>
                            <th>Calle</th>
                        </tr>
                    </thead>
                    <tbody >
                        {JSON.parse(this.props.param).map(item => (

                            <tr onDoubleClick={() => this.props.modifyDirecciones(item)}>
                                {this.returnCpTable(item)}
                                {this.returnAdressTable(item)}
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ModalDirecciones;
