import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalVacacionesLocal extends Component {

    constructor(props) {
        super(props);
        this.makeJSON = this.makeJSON.bind(this);


        this.inputDateGo = React.createRef();
        this.inputReason = React.createRef();
    }

    componentDidMount() {
        this.props.doJson(this.makeJSON)


    }
    makeJSON() {
        if (this.inputDateGo.current.value != "") {
            return this.contactInfo = {
                "iduser": 0,
                "dateFirstDay": this.inputDateGo.current.value,
                "dateLastDay": null,
                "reason": this.inputReason.current.value,
                "description": null,
                "id_user_creator": null,
                "id_user_modifier": null,

            }
        } else {
            return this.error = {
                "error": "Te has dejado algun campo vacio, para realizar la tarea deben estar completos todos los campos. Vuelve a intentarlo"
            }
        }
    }


    render() {
        return (
            <div class="modal fade" id="modalFormVacacionesLocal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Formulario vacaciones</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">
                            <input value="" id="idUserRecord" ref={this.idUserRecord} hidden />
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Día de Fiesta</label>
                                <div class="col-8">
                                    <input class="form-control" type="date" id="inputDateGo" ref={this.inputDateGo} />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="colFormLabel" class="col-4 col-form-label">Motivo</label>
                                <div class="col-8">
                                    <select id="inputState inputReason" class="form-control" ref={this.inputReason}>
                                        <option value="Fiesta Local">Fiesta Local</option>
                                        <option value="Fiesta Estatal">Fiesta Estatal</option>
                                        <option value="Fiesta Nacional">Fiesta Nacional</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Última Modificación</label>
                                <div class="col-4">
                                    <input class="form-control" type="text" id="inputAdminModifier" ref={this.inputAdminModifier} readOnly />
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" id="inputDateModified" ref={this.inputDateModified} readOnly />
                                </div>
                            </div>


                        </div>
                        <div class="modal-footer">
                            <button type="button" id="btn-deleteMVL" class="btn btn-danger" onClick={this.props.deleteVacacionLocal}>Eliminar</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary" onClick={this.props.saveNew}>Guardar cambios</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalVacacionesLocal;
