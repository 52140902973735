import React, { Component } from "react";
import $ from 'jquery';
import '../css/User.css';
import '../css/HistorialRecords.css';
import ModalChangePasswd from "./formPersonalComponents/modalesForm/ModalChangePasswd";
import ReactDOM from 'react-dom';
import User from "./User";
import AdminPage from "./AdminPage";
import HistorialRecords from "./HistorialRecords"
import Incidencias from "./Incidencias";
import IncidenciasAdmin from "./IncidenciasAdmin";


class IncidenciasForm extends Component {

    constructor(props) {
        super(props);
        this.cargarFichaje = this.cargarFichaje.bind(this);
        this.adminInfo = this.props.adminInfo
        this.cargarUsuario = this.cargarUsuario.bind(this);
        this.cargarHistorial = this.cargarHistorial.bind(this);
        this.cargarIncidencias = this.cargarIncidencias.bind(this);
        this.getIncidencia = this.getIncidencia.bind(this)
        this.saveNew = this.saveNew.bind(this)
        this.getAllInfo = this.getAllInfo.bind(this)
        this.cancel = this.cancel.bind(this)
        this.solved = this.solved.bind(this)

        this.refSelectEstado = React.createRef();
        this.refSelectPrioridad = React.createRef();
        this.refSelectTipo = React.createRef();

        this.listaType = []
        this.listaPriority = []
        this.listaStatus = []

    }
    componentDidMount() {

        ReactDOM.unmountComponentAtNode(document.getElementById('modalContraseña'))
        ReactDOM.render(
            <ModalChangePasswd checkPasswd={this.props.checkPasswd} isAdmin={false} />,
            document.getElementById('modalContraseña')
        );
        $('#modalFormPasswd').modal('hide')

        this.getAllInfo()

    }

    componentWillUnmount() {
    }

    saveNew() {
        document.getElementById("botonGuardarObservacion").disabled = true
        this.subsciptionGU_GIBY = this.props.stompClient.subscribe("/user/queue/saveOrModifyIncidenceSubscribe", (msg) => {
            this.subsciptionGU_GIBY.unsubscribe("subsciptionGU_GIBY")
            document.getElementById("botonGuardarObservacion").disabled = false

            ReactDOM.render(
                <IncidenciasAdmin adminInfo={this.adminInfo} checkPasswd={this.checkPasswd} stompClient={this.props.stompClient} />,
                document.getElementById('incidenciasAdmin')
            );


        }, { id: "subsciptionGU_GIBY" });
        this.props.stompClient.send("/app/saveOrModifyIncidence", {}, JSON.stringify({
            "id": this.props.idIncicence,
            "incidenceStatus": document.getElementById("estado").value,
            "incidenceType": document.getElementById("tipo").value,
            "incidencePriority": document.getElementById("prioridad").value,
            "observation": document.getElementById("txtObservacion").value,
            "nombre": document.getElementById("nombreInput").value,
        }));

    }

    async getIncidencia() {
        await new Promise((resolve) => {
            this.subsciptionGU_GIBY = this.props.stompClient.subscribe("/user/queue/getIncidenciasByIdSubscribe", (msg) => {

                console.log(msg.body.nombre)
                this.incidencia = JSON.parse(msg.body)

                document.getElementById("nombreInput").value = this.incidencia.nombre
                document.getElementById("txtObservacion").value = this.incidencia.observation
                document.getElementById("tipo").value = this.incidencia.incidenceType
                document.getElementById("estado").value = this.incidencia.incidenceStatus
                document.getElementById("prioridad").value = this.incidencia.incidencePriority

                setTimeout(() => {
                    resolve()
                }, this.subsciptionGU_GIBY.unsubscribe("subsciptionGU_GIBY"))

            }, { id: "subsciptionGU_GIBY" });
            this.props.stompClient.send("/app/getIncidenciasById", {}, JSON.stringify({
                "id": this.props.idIncicence,
            }));
        });
    }

    solved() {
        this.subsciptionGU_SIAS = this.props.stompClient.subscribe("/user/queue/saveIncidenceAsSolvedSubscribe", (msg) => {

            this.subsciptionGU_SIAS.unsubscribe("subsciptionGU_SIAS")
            ReactDOM.render(
                <IncidenciasAdmin adminInfo={this.adminInfo} stompClient={this.props.stompClient} />,
                document.getElementById('incidenciasAdmin')
            );    

        }, { id: "subsciptionGU_GIBY" });
        this.props.stompClient.send("/app/saveIncidenceAsSolved", {}, JSON.stringify({
            "id": this.props.idIncicence,
        }));
    }

    cancel() {
        ReactDOM.render(
            <IncidenciasAdmin adminInfo={this.adminInfo} stompClient={this.props.stompClient} />,
            document.getElementById('incidenciasAdmin')
        );
    }

    getAllInfo() {
        this.subsciptionGU_GAII = this.props.stompClient.subscribe("/user/queue/getAllIncidenceInfoSubscribe", (msg) => {
            this.subsciptionGU_GAII.unsubscribe("subsciptionGU_GAII")
            this.listaTotal = JSON.parse(msg.body)

            this.listaTotal.forEach(element => {
                if (element.optionDefault == "Type") {
                    this.listaType.push(element.name)
                } else if (element.optionDefault == "Priority") {
                    this.listaPriority.push(element.name)
                } else if (element.optionDefault == "Status") {
                    this.listaStatus.push(element.name)
                }
            });

            const listaRenderEstados = this.listaStatus.map((item,i) => <option value={item}>{item}</option>)
            ReactDOM.unmountComponentAtNode(document.getElementById("estado"))
            ReactDOM.render(listaRenderEstados, document.getElementById("estado"))

            const listaRenderType = this.listaType.map(item => <option value={item}>{item}</option>)
            ReactDOM.unmountComponentAtNode(document.getElementById("tipo"))
            ReactDOM.render(listaRenderType, document.getElementById("tipo"))

            const listaRenderPriority = this.listaPriority.map(item => <option value={item}>{item}</option>)
            ReactDOM.unmountComponentAtNode(document.getElementById("prioridad"))
            ReactDOM.render(listaRenderPriority, document.getElementById("prioridad"))

            this.getIncidencia()


        }, { id: "subsciptionGU_GAII" });
        this.props.stompClient.send("/app/getAllIncidenceInfo", {}, JSON.stringify({
        }));

    }

    cargarFichaje() {
        ReactDOM.render(
            <User adminInfo={this.props.adminInfo} stompClient={this.props.stompClient} />, document.getElementById('generalTablHistorialRecords')
        );
    }

    cargarUsuario() {
        ReactDOM.render(
            <AdminPage adminInfo={this.props.adminInfo} stompClient={this.props.stompClient} />, document.getElementById('root')
        );
    }

    cargarHistorial() {
        ReactDOM.render(
            <HistorialRecords adminInfo={this.adminInfo} checkPasswd={this.checkPasswd} stompClient={this.props.stompClient} />,
            document.getElementById('incidenciasAdmin')
        );
    }

    cargarIncidencias() {
        ReactDOM.render(
            <IncidenciasAdmin adminInfo={this.adminInfo} checkPasswd={this.checkPasswd} stompClient={this.props.stompClient} />,
            document.getElementById('incidenciasAdmin')
        );
    }


    changePasswd() {

        this.subsciptionVU_U.unsubscribe("subsciptionVU_U");
        this.subsciptionCP_U = this.props.stompClient.subscribe('/user/queue/changePasswdSubscribe', this.passwordChanged, { id: "subsciptionCP_U" });
        this.props.stompClient.send("/app/changePasswd", {}, JSON.stringify({
            "id": this.adminInfo.id,
            "passwd": this.newPasswd,
        }));
    }

    passwordChanged() {
        $('#modalFormChangePasswd').modal('hide')
        this.subsciptionCP_U.unsubscribe("subsciptionCP_U");
    }

    render() {
        return (
            <div id="formularioIncidencias">
                <div>
                    <h2>FORMULARIO INCIDENCIA</h2>
                </div>
                <div>
                    <label >Nombre Incidencia </label>
                    <input id="nombreInput" className="inputAll" type="text" />
                </div>
                <div>
                    <label>Estado de la incidencia</label>
                    <select class="custom-select mr-sm-2" ref={this.refSelectEstado} id="estado"></select>
                </div>

                <div>
                    <label>Tipo de incidencia</label>
                    <select class="custom-select mr-sm-2" ref={this.refSelectTipo} id="tipo"></select>
                </div>

                <div>
                    <label>Prioridad de la incidencia</label>
                    <select class="custom-select mr-sm-2" ref={this.refSelectPrioridad} id="prioridad"></select>
                </div>
                <div class="form-group">
                    <label for="street1_id" class="control-label">Observación</label>
                    <textarea class="form-control" id="observacion_id" rows="10" id="txtObservacion"></textarea>
                </div>

                <button type="button" class="btn btn-primary" id="botonGuardarObservacion" onClick={this.saveNew}>Guardar cambios</button>
                <button type="button" class="btn btn-secondary" onClick={this.cancel}>Cancelar</button>
                <button type="button" class="btn btn-success" onClick={this.solved}>Marcar como resuelta</button>



                <div id="modalContraseña">
                </div>
            </div>
        );
    }
}

export default IncidenciasForm;
