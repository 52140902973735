import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalDirecciones extends Component {

    constructor(props) {
        super(props);
        this.admin = this.props.adminInfo
        this.user = this.props.user
        this.makeJSON = this.makeJSON.bind(this);

        this.inputCalle = React.createRef();
        this.inputLocalidad = React.createRef();
        this.inputCodPostal = React.createRef();
        this.inputPais = React.createRef();
        this.inputComunidad = React.createRef();
        this.inputProvincia = React.createRef();

        this.direccionPrincipal = this.direccionPrincipal.bind(this)

        this.inputId = React.createRef();
        this.mainDireccion = false

    }

    componentDidMount() {
        this.props.doJson(this.makeJSON)

    }
    makeJSON() {
        if (this.inputCalle.current.value != "" && this.inputLocalidad.current.value != "" && this.inputCodPostal.current.value != ""
            && this.inputPais.current.value != "" && this.inputComunidad.current.value != "") {

            return this.directionInfo = {
                "id": this.inputId.current.value,
                "iduser": this.user.id,
                "address": this.inputCalle.current.value,
                "localidad": this.inputLocalidad.current.value,
                "cod_postal": this.inputCodPostal.current.value,
                "pais": this.inputPais.current.value,
                "com_autonoma": this.inputComunidad.current.value,
                "provincia": this.inputProvincia.current.value,
                "id_user_creator": this.admin.id,
                "id_user_modifier": this.admin.id,
                "principal": this.mainDireccion,

            }
        } else {
            return this.error = {
                "error": "Te has dejado algun campo vacio, para realizar la tarea deben estar completos todos los campos.Vuelve a intentarlo",
            }
        }
    }

    direccionPrincipal() {
        if (this.mainDireccion) {
            this.mainDireccion = false
            document.getElementById("btnDireccionPrincipal").innerHTML = "No Principal"
        } else {
            this.mainDireccion = true
            document.getElementById("btnDireccionPrincipal").innerHTML = "Principal"
        }

    }

    render() {
        this.mainDireccion = this.props.isPrincipal
        return (
            <div class="modal fade" id="modalFormDireccion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Formulario de Alta de una Dirección</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                            <input type="text" class="form-control" id="id_direccions" ref={this.inputId} readOnly hidden />

                            <div class="form-group">
                                <label for="calle_id" class="control-label">Calle</label>
                                <input type="text" class="form-control" id="calle_id" placeholder="C/ Doctor Alabama " ref={this.inputCalle} required />
                            </div>

                            <div class="form-group">
                                <label for="city_id" class="control-label">Localidad</label>
                                <input type="text" class="form-control" id="localidad_id" name="city" placeholder="Xàtiva" ref={this.inputLocalidad} required />
                            </div>

                            <div class="form-group">
                                <label for="state_id" class="control-label">Comunidad</label>
                                <input type="text" class="form-control" id="comunidad_id" name="zip" placeholder="Comunitat Valenciana" ref={this.inputComunidad} required />
                            </div>

                            <div class="form-group">
                                <label for="state_id" class="control-label">Provincia</label>
                                <input type="text" class="form-control" id="provincia_id" name="zip" placeholder="Valencia" ref={this.inputProvincia} required />
                            </div>

                            <div class="form-group">
                                <label for="zip_id" class="control-label">Código Postal</label>
                                <input type="text" class="form-control" id="cp_id" name="zip" placeholder="#####" ref={this.inputCodPostal} required />
                            </div>
                            <div class="form-group">
                                <label for="pais_id" class="control-label">País</label>
                                <input type="text" class="form-control" id="pais_id" name="pais" placeholder="España" ref={this.inputPais} required />
                            </div>
                            <div class="form-group row col-md-12 text-center">
                                <button id="btnDireccionPrincipal" type="button" class="btn btn-success control-label" onClick={this.direccionPrincipal}></button>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <div>
                                <p id="msgErrorDireccionesCodigoPostal" style={{ color: 'red', display: 'none', marginTop: "10px" }}>*Error, Código postal tiene que ser un número</p>
                                <p id="msgErrorDireccion" style={{ color: 'red', display: 'none', marginTop: "10px" }}>*Error, algun campo está vacío</p>
                                <p id="msgErrorServidorDireccion" style={{ color: 'red', display: 'none', marginTop: "10px" }}>*Error, el servidor no ha podido crear la dirección</p>

                            </div>
                            <br></br>
                            <div>
                                <button type="button" id="btn-deleteMVL" class="btn btn-danger" data-dismiss="modal" onClick={this.props.deleteDirecciones}>Eliminar</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="button" class="btn btn-primary" id="botonCrearDireccion" onClick={this.props.saveNew}>Guardar cambios</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalDirecciones;
