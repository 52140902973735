import React, { Component } from "react";
import ReactDOM from 'react-dom';
import '../css/User.css';

class Reloj extends Component {

  constructor(props) {
    super(props);



  }
  render() {
    return (
      <div class="reloj">
        <p class="hora">{this.props.hora}</p>
        <p>:</p>
        <p class="minutos">{this.props.minutos}</p>
        <p>:</p>
        <div class="cajaSegundos">
          <p class="segundos">{this.props.segundos}</p>
        </div>
      </div>
    );
  }
}

export default Reloj;
