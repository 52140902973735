import React, { Component } from "react";

class CurrentUsers extends Component {

  constructor(props) {
    super(props);

  }


  timerHours(timeEntry, timeExit) {
    if (timeExit != null) {
      this.timeDiff = ((parseInt(timeExit.split(":")[0]) * 3600) + (parseInt(timeExit.split(":")[1]) * 60) + (parseInt(timeExit.split(":")[2]))) - ((parseInt(timeEntry.split(":")[0]) * 3600) + (parseInt(timeEntry.split(":")[1]) * 60) + (parseInt(timeEntry.split(":")[2])));

      this.horasDiff = Math.floor(this.timeDiff / 3600);
      this.minutosDiff = Math.floor((this.timeDiff - (this.horasDiff * 3600)) / 60);
      this.secondsDiff = this.timeDiff - (this.horasDiff * 3600) - (this.minutosDiff * 60);

      if (this.horasDiff < 10) { this.horasDiff = "0" + this.horasDiff; }
      if (this.minutosDiff < 10) { this.minutosDiff = "0" + this.minutosDiff; }
      if (this.secondsDiff < 10) { this.secondsDiff = "0" + this.secondsDiff; }
      return this.horasDiff + ":" + this.minutosDiff + ":" + this.secondsDiff;
    }

  }

  render() {
    return (
      <div class="table-wrapper" id="tableRecords">
        <table class="table table-striped table-sm " >
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Nombre</th>
              <th scope="col">Apellidos</th>
              <th scope="col" >Telefono</th>
              <th scope="col" >Email</th>
            </tr>
          </thead>
          <tbody >
            {JSON.parse(this.props.param).map(item => (
              <tr onDoubleClick={this.props.modUser}>
                <td>{item.id}</td>
                <td>{item.nombre}</td>
                <td>{item.apellidos}</td>
                <td>{item.telefono}</td>
                <td>{item.email}</td>
              </tr>
            ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default CurrentUsers;
