import ReactDOM from 'react-dom';
import './index.css';
import User from './components/User';
import AdminPage from './components/AdminPage';
import Login from './components/Login';
import React, { Component } from "react";
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import ModalBadLogin from './components/formPersonalComponents/modalesForm/ModalBadLogin'
import $, { data } from 'jquery';
import ModalCantConnect from './components/formPersonalComponents/modalesForm/ModalCantConnect';

let hostnameIp = window.location.hostname
var sock = new SockJS(window.location.protocol + '//' + hostnameIp + ':8092/jsa-stomp-endpoint');

/*if( navigator.userAgent.match(/Android/i)
|| navigator.userAgent.match(/webOS/i)
|| navigator.userAgent.match(/iPhone/i)
|| navigator.userAgent.match(/iPad/i)
|| navigator.userAgent.match(/iPod/i)
|| navigator.userAgent.match(/BlackBerry/i)
|| navigator.userAgent.match(/Windows Phone/i)){

  alert("No puedes fichar desde un telefono movil")

}*/


var stompClient = Stomp.over(sock);
stompClient.connect({}, function (frame) {
  ReactDOM.render(
    <Login />, document.getElementById('root')
  );
}, () => {
  ReactDOM.render(
    <ModalCantConnect />, document.getElementById('root')
  );
  $('#modalFormBadLogin').modal('show')
});


function returnLogin(errorUser, errorOff) {
  ReactDOM.render(
    <Login errorOff={errorOff} errorUser={errorUser} />, document.getElementById('root')
  );
}

function getCookie(key)  { 
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const data = cookies[i].split("=");
    if (data[0] === key) {
      return data[1];
    }
  }
  return "";
}


class Index extends Component {
  constructor(props) {
    super(props);
    this.comprobacionLogin = this.comprobacionLogin.bind(this);
    this.returnError = this.returnError.bind(this)

    this.userExiste = true
    this.sessionId = stompClient.ws._transport.url;
    this.sessionId = this.sessionId.replace("ws://" + hostnameIp + ':8092/jsa-stomp-endpoint', "");
    this.sessionId = this.sessionId.replace("/websocket", "");
    this.sessionId = this.sessionId.replace("/", "");
    this.sessionId = this.sessionId.replace(/^[0-9]+\//, "");
    this.ipAdrress = ""
    console.log(document.cookie)
    this.subsciptionVU_I = stompClient.subscribe('/user/queue/validateUserSubscribe', this.comprobacionLogin, { id: "subsciptionVU_I" });
    stompClient.send("/app/validateUser", {}, JSON.stringify({
      "nombre_usuario": this.props.userInfo.name,
      "passwd": this.props.userInfo.passwd,
      "cookies": getCookie("pcid"),
    }));
  }

  getDataInfo(data) {
    console.log(JSON.stringify(data, null, 2));
  }

  returnError() {
    if (this.errorOff == true) {
      return (
        <p>* Error, Usuario desactivado, contacta con un administrador</p>
      );
    }
  }

  componentDidMount() {

  }

  comprobacionLogin(message) {
    this.subsciptionVU_I.unsubscribe("subsciptionVU_I");
      /*ReactDOM.render(
        <div>
          <Login />
          <ModalBadLogin textoError={"Error, no puedes iniciar session desde fuera de la fabrica si no teletrabajas"} /></div>
        , document.getElementById('root')
      );
      $('#modalFormBadLogin').modal('show')*/
    if (message.body == "false") {
      ReactDOM.render(
        <div>
          <Login />
          <ModalBadLogin textoError={"Error, usuario y contraseña incorrectas"} /></div>
        , document.getElementById('root')
      );
      $('#modalFormBadLogin').modal('show')
    }
    else if (message.body == "falseE") {
      ReactDOM.render(
        <div>
          <Login />
          <ModalBadLogin textoError={"Error, este usuario ha sido desactivado. Contacta con un administrador"} /></div>
        , document.getElementById('root')
      );
      $('#modalFormBadLogin').modal('show')
    }
    else if (message.body == "falseIP") {
      ReactDOM.render(
        <div>
          <Login />
          <ModalBadLogin textoError={"Tienes que fichar desde la oficina, para habilitar el teletrabajo, debes contactar con un administrador"} /></div>
        , document.getElementById('root')
      );
      $('#modalFormBadLogin').modal('show')
    }

    else {
      if (message.body != null) {
        var userInfo = JSON.parse(message.body)
        this.errorUser = false
        if (userInfo.off == false) {
          ReactDOM.render(
            <User userInfo={userInfo} stompClient={stompClient} />, document.getElementById('root')
          );
        }
        else {
          ReactDOM.render(
            <div>
              <Login />
              <ModalBadLogin textoError={"Error, este usuario ha sido desactivado. Contacta con un administrador"} /></div>
            , document.getElementById('root')
          );
          $('#modalFormBadLogin').modal('show')

        }
      } else {
        ReactDOM.render(
          <div>
            <Login />
            <ModalBadLogin textoError={"Error, usuario y contraseña incorrectas"} /></div>
          , document.getElementById('root')
        );

        $('#modalFormBadLogin').modal('show')
      }

    }
  }
  render() {
    return (
      <div>
        {returnLogin(this.errorUser, this.errorOff)}
      </div>
    )
  }
}

export default Index;


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
