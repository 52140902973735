import React, { Component } from "react";
import ReactDOM from 'react-dom';
import TablaVacaciones from './tablasForm/TablaVacaciones';
import ModalVacaciones from './modalesForm/ModalVacaciones';
import TablaVacacionesSon from './tablasForm/TablaVacacionesSon';
import $ from 'jquery';
import '../../css/formPersonal/VacacionesUser.css';

class VacacionesUser extends Component {

    constructor(props) {
        super(props);

        this.user = this.props.user
        this.admin = this.props.adminInfo

        this.returnJSONModalVacaciones = this.returnJSONModalVacaciones.bind(this);
        this.datesDefault = this.datesDefault.bind(this);
        this.daysInMonth = this.daysInMonth.bind(this);
        this.modifyVacaciones = this.modifyVacaciones.bind(this);
        this.modifyContact = this.modifyContact.bind(this);
        this.returnVacaciones = this.returnVacaciones.bind(this);
        this.sendUserInfo = this.sendUserInfo.bind(this);
        this.returnNewVacaciones = this.returnNewVacaciones.bind(this);
        this.saveNewVacaciones = this.saveNewVacaciones.bind(this);
        this.returnVacacionesModified = this.returnVacacionesModified.bind(this);
        this.returnVacacionesSon = this.returnVacacionesSon.bind(this);
        this.returnName = this.returnName.bind(this);
        this.returnLocalDate = this.returnLocalDate.bind(this);
        this.changeToCustom = this.changeToCustom.bind(this);
        this.search = this.search.bind(this)

        this.deleteVacaciones = this.deleteVacaciones.bind(this)
        this.returnAllVacaciones = this.returnAllVacaciones.bind(this)
        this.borrarDiaVacacionesSon = this.borrarDiaVacacionesSon.bind(this)
        this.createNewVacaciones = this.createNewVacaciones.bind(this)
        this.renderizarVacaciones = this.renderizarVacaciones.bind(this)
        this.selectOption = React.createRef();

        this.dateTargetBeforeRef = React.createRef();
        this.dateTargetAfterRef = React.createRef();

    }

    componentDidMount() {
        ReactDOM.render(
            <ModalVacaciones user={this.props.user} deleteVacaciones={this.deleteVacaciones} adminInfo={this.props.adminInfo} saveNew={this.saveNewVacaciones} doJson={this.returnJSONModalVacaciones} />,
            document.getElementById('modalVacaciones')
        );
        $('#modalVacaciones').on('hide.bs.modal', function () {
            document.getElementById('btn-deleteMVL').style.display = "none"
        })

        this.subsciptionN_CR = this.props.stompClient.subscribe('/user/queue/getAllUsersNameAndIdSubscribe', this.returnName, { id: "subsciptionN_CR" });
        this.props.stompClient.send("/app/getAllUsersNameAndId", {});



    }

    search(){
        this.subsciptionTO_OU = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordGroupSubscribe', this.returnVacaciones, { id: "subsciptionTO_OU" });
        this.props.stompClient.send("/app/returnTableNonWorkRecordGroup", {}, JSON.stringify({
            "iduser": this.user.id,
            "dateBefore": this.dateTargetBeforeRef.current.value,
            "dateAfter": this.dateTargetAfterRef.current.value
        }));
        this.subsciptionLD_HA.unsubscribe("subsciptionTO_OU");

    }

    renderizarVacaciones() {
        ReactDOM.render(
            <ModalVacaciones user={this.props.user} deleteVacaciones={this.deleteVacaciones} adminInfo={this.props.adminInfo} saveNew={this.saveNewVacaciones} doJson={this.returnJSONModalVacaciones} />,
            document.getElementById('modalVacaciones')
        );
        $('#modalVacaciones').on('hide.bs.modal', function () {
            document.getElementById('btn-deleteMVL').style.display = "none"
        })
    }

    createNewVacaciones() {

        document.getElementById("botonGuardarVacaciones").disabled = false

        document.getElementById("msgErrorVacaciones").style.display = "none"
        document.getElementById("msgErrorServidorVacaciones").style.display = "none"
        this.renderizarVacaciones()
        document.getElementById('idVacacion').value = ""
        document.getElementById('inputDateGo').value = ""
        document.getElementById('inputDateBack').value = ""
        //document.getElementById('inputReason').value = ""
        document.getElementById('inputAdminModifier').value = ""
        document.getElementById('inputDateModified').value = ""
        document.getElementById('inputDescription').value = ""
        document.getElementById('btn-deleteMVL').style.display = "none"
        this.idUserModifycator = null
        $('#modalFormVacaciones').modal('show')
        this.componentWillUnmount(document.getElementById('tableVacacionesSon'))
        ReactDOM.render(
            <TablaVacacionesSon param={""} borrarDiaVacacionesSon={this.borrarDiaVacacionesSon} />,
            document.getElementById('tableVacacionesSon')
        );


    }

    deleteVacaciones(event) {

        this.props.stompClient.send("/app/deleteNonWorkRecordById", {}, JSON.stringify({
            "id": document.getElementById("idVacacion").value,
        }));
        this.subsciptionRHD_DV = this.props.stompClient.subscribe('/user/queue/deleteNonWorkRecordByIdSubscribe', (msg) => {
            this.subsciptionRHD_DV.unsubscribe("subsciptionRHD_DV")

            this.returnAllVacaciones();
        }, { id: "subsciptionRHD_DV" });
    }
    changeToCustom(e) {
        this.selectOption.current.value = "Custom"
    }


    returnName(msg) {
        this.JsonUsers = JSON.parse(msg.body)
        this.subsciptionN_CR.unsubscribe("subsciptionN_CR");

        this.subsciptionLD_HA = this.props.stompClient.subscribe('/user/queue/localdateSubscribe', this.returnLocalDate, { id: "subsciptionLD_HA" });
        this.props.stompClient.send("/app/localdate", {});
    }
    returnLocalDate(msg) {
        this.localDate = msg.body

        this.localDate = this.localDate.replaceAll('"', "")
        this.splitDate = this.localDate.split("-")
        this.anyo = this.splitDate[0]
        this.mes = this.splitDate[1]
        this.dia = this.splitDate[2]

        this.dateTargetBeforeRef.current.value = (parseInt(this.anyo)) + "-01-01";
        this.dateTargetAfterRef.current.value = (parseInt(this.anyo)) + "-12-31";
        this.selectOption.current.value = "ThisY"
        this.subsciptionTO_OU = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordGroupSubscribe', this.returnVacaciones, { id: "subsciptionTO_OU" });
        this.props.stompClient.send("/app/returnTableNonWorkRecordGroup", {}, JSON.stringify({
            "iduser": this.user.id,
            "dateBefore": this.dateTargetBeforeRef.current.value,
            "dateAfter": this.dateTargetAfterRef.current.value
        }));
        this.subsciptionLD_HA.unsubscribe("subsciptionTO_OU");

    }


    modifyContact(item) {
        alert(item)
        $('#modalFormVacaciones').modal('show')
    }

    componentWillUnmount() {

    }

    returnVacacionesModified(msg) {
        this.subsciptionMO_OU.unsubscribe("subsciptionMO_OU");

        if (JSON.parse(msg.body).statusCodeValue == 500) {
            document.getElementById("msgErrorServidorVacaciones").style.display = "block"
            document.getElementById("msgErrorVacaciones").style.display = "none"
            document.getElementById("botonGuardarVacaciones").disabled = false

        } else {
            $('#modalFormVacaciones').modal('hide')
        }

        this.subsciptionTO_OU = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordGroupSubscribe', this.returnVacaciones, { id: "subsciptionTO_OU" });
        this.props.stompClient.send("/app/returnTableNonWorkRecordGroup", {}, JSON.stringify({
            "iduser": this.user.id
        }));
    }

    sendUserInfo() {
        this.userInfo = {
            "id": this.user.id,
        }

        return this.userInfo;
    }

    modifyVacaciones(item) {
        document.getElementById("botonGuardarVacaciones").disabled = false

        document.getElementById("msgErrorVacaciones").style.display = "none"
        document.getElementById("msgErrorServidorVacaciones").style.display = "none"

        document.getElementById('idVacacion').value = item.id
        document.getElementById('inputDateGo').value = item.dateFirstDay
        document.getElementById('inputDateBack').value = item.dateLastDay

        if (!item.reason == undefined){
            document.getElementById('inputReason').value = item.reason
        }
        if (this.JsonUsers[0][item.id_user_modifier] == undefined) {
            document.getElementById('inputAdminModifier').value = ""
        } else {
            document.getElementById('inputAdminModifier').value = this.JsonUsers[0][item.id_user_modifier]
        }

        document.getElementById('inputDateModified').value = item.date_modify
        document.getElementById('inputDescription').value = item.description
        document.getElementById('btn-deleteMVL').style.display = "block"
        this.idUserModifycator = this.admin.id
        this.idItem = item.id
        $('#modalFormVacaciones').modal('show')

        this.subsciptionTNWS_MV = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordSonSubscribe', this.returnVacacionesSon, { id: "subsciptionTNWS_MV" });
        this.props.stompClient.send("/app/returnTableNonWorkRecordSon", {}, JSON.stringify({
            "id": item.id
        }));

    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    datesDefault(e) {
        this.date = new Date()

        this.mesMesPasado = this.date.getMonth()
        this.anyoMesPasado = this.date.getFullYear()

        if (this.mesMesPasado == 0) {
            this.mesMesPasado = 11
            this.anyoMesPasado = this.date.getFullYear() + 1
        }

        this.lenghtMes = new Date(this.anyoMesPasado, this.anyoMesPasado, 0).getDate();
        switch (e.target.value) {
            case 'ThisM':
                var d = new Date();

                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-01";
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + (this.daysInMonth(d.getMonth() + 1, d.getFullYear())).toString();
                break;

            case 'LastD':
                var d = new Date();
                d.setDate(d.getDate() - 1)
                if (d.getDate() < 10) {
                    var diaAnterior = "0" + d.getDate().toString()
                }
                else {
                    var diaAnterior = d.getDate().toString()
                }
                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + diaAnterior;
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + diaAnterior;

                break;

            case 'Today':
                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                break;

            case 'All':
                this.dateTargetBeforeRef.current.value = "1980-01-01";
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                break;

            case 'LastW':

                var dateWeekBefore = new Date();
                var dateWeekAfter = new Date();

                var pastWeekDate = dateWeekAfter.getDate() - this.date.getDay();
                dateWeekAfter.setDate(pastWeekDate);
                dateWeekAfter.setMonth(dateWeekAfter.getMonth() + 1)

                var pastWeekDate = dateWeekBefore.getDate() - this.date.getDay() - 6;
                dateWeekBefore.setDate(pastWeekDate);
                dateWeekBefore.setMonth(dateWeekBefore.getMonth() + 1)

                var diaLastWeek1 = dateWeekBefore.getDate().toString()
                var diaLastWeek2 = dateWeekAfter.getDate().toString()

                var mesLastWeek1 = dateWeekBefore.getMonth().toString()
                var mesLastWeek2 = dateWeekAfter.getMonth().toString()

                var anyoLastWeek1 = dateWeekBefore.getFullYear().toString()
                var anyoLastWeek2 = dateWeekAfter.getFullYear().toString()

                if (diaLastWeek1 <= 9) {
                    diaLastWeek1 = "0" + diaLastWeek1
                }

                if (diaLastWeek2 <= 9) {
                    diaLastWeek2 = "0" + diaLastWeek2
                }

                if (mesLastWeek1 <= 9) {
                    mesLastWeek1 = "0" + mesLastWeek1
                }

                if (mesLastWeek2 <= 9) {
                    mesLastWeek2 = "0" + mesLastWeek2
                }

                this.dateTargetBeforeRef.current.value = anyoLastWeek1 + "-" + mesLastWeek1 + "-" + diaLastWeek1
                this.dateTargetAfterRef.current.value = anyoLastWeek2 + "-" + mesLastWeek2 + "-" + diaLastWeek2


                break;

            case 'LastM':
                var d = new Date();
                d.setMonth(d.getMonth() - 1);

                if ((parseInt(this.mes) - 1) != 0) {
                    if ((parseInt(this.mes) - 1) <= 9) {
                        this.dateTargetBeforeRef.current.value = this.anyo + "-0" + (parseInt(this.mes) - 1) + "-01";
                        this.dateTargetAfterRef.current.value = this.anyo + "-0" + (parseInt(this.mes) - 1) + "-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                    } else {

                        this.dateTargetBeforeRef.current.value = this.anyo + "-" + (parseInt(this.mes) - 1) + "-01";
                        this.dateTargetAfterRef.current.value = this.anyo + "-" + (parseInt(this.mes) - 1) + "-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                    }
                } else {

                    this.dateTargetBeforeRef.current.value = (parseInt(this.anyo) - 1) + "-12-01";
                    this.dateTargetAfterRef.current.value = (parseInt(this.anyo) - 1) + "-12-" + this.daysInMonth(d.getMonth() - 1, d.getFullYear());
                }
                break;

            case 'LastY':
                this.dateTargetBeforeRef.current.value = (parseInt(this.anyo) - 1) + "-01-01";
                this.dateTargetAfterRef.current.value = (parseInt(this.anyo) - 1) + "-12-31";

                break;
            case 'ThisY':
                this.dateTargetBeforeRef.current.value = (parseInt(this.anyo)) + "-01-01";
                this.dateTargetAfterRef.current.value = (parseInt(this.anyo)) + "-12-31";


            default:
                break;

        }

        this.search()

    }


    returnVacacionesSon(msg) {
        this.subsciptionTNWS_MV.unsubscribe("subsciptionTNWS_MV");
        this.componentWillUnmount(document.getElementById('tableVacacionesSon'))
        ReactDOM.render(
            <TablaVacacionesSon param={msg.body} borrarDiaVacacionesSon={this.borrarDiaVacacionesSon} />,
            document.getElementById('tableVacacionesSon')
        );
    }

    borrarDiaVacacionesSon(item) {

        this.subsciptionTNWS_DUNWD = this.props.stompClient.subscribe('/user/queue/deleteUniqueNonWorkRecordByIdSubscribe', (msg) => {
            this.subsciptionTNWS_DUNWD.unsubscribe("subsciptionTNWS_DUNWD")

            this.subsciptionTNWS_MV = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordSonSubscribe', this.returnVacacionesSon, { id: "subsciptionTNWS_MV" });
            this.props.stompClient.send("/app/returnTableNonWorkRecordSon", {}, JSON.stringify({
                "id": document.getElementById('idVacacion').value
            }));
        }, { id: "subsciptionTNWS_DUNWD" });
        this.props.stompClient.send("/app/deleteUniqueNonWorkRecordById", {}, JSON.stringify({
            "id": item.id
        }));

    }

    returnVacaciones(msg) {
        this.subsciptionTO_OU.unsubscribe("subsciptionTO_OU");
        this.componentWillUnmount(document.getElementById('tableVacaciones'))
        ReactDOM.render(
            <TablaVacaciones param={msg.body} modifyVacaciones={this.modifyVacaciones} />,
            document.getElementById('tableVacaciones')
        );
    }

    returnVacaciones(msg) {
        this.subsciptionTO_OU.unsubscribe("subsciptionTO_OU");
        this.componentWillUnmount(document.getElementById('tableVacaciones'))
        ReactDOM.render(
            <TablaVacaciones param={msg.body} modifyVacaciones={this.modifyVacaciones} />,
            document.getElementById('tableVacaciones')
        );
    }

    returnNewVacaciones(msg) {

        if (JSON.parse(msg.body).statusCodeValue == 500) {
            document.getElementById("msgErrorServidorVacaciones").style.display = "block"
            document.getElementById("msgErrorVacaciones").style.display = "none"
            document.getElementById("botonGuardarVacaciones").disabled = false

        } else {
            $('#modalFormVacaciones').modal('hide')
        }


        this.subsciptionCO_OU.unsubscribe("subsciptionCO_OU");
        this.subsciptionTO_OU = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordGroupSubscribe', this.returnVacaciones, { id: "subsciptionTO_OU" });
        this.props.stompClient.send("/app/returnTableNonWorkRecordGroup", {}, JSON.stringify({
            "iduser": this.user.id
        }));
    }

    returnAllVacaciones() {
        this.subsciptionTO_OU = this.props.stompClient.subscribe('/user/queue/returnTableNonWorkRecordGroupSubscribe', this.returnVacaciones, { id: "subsciptionTO_OU" });
        this.props.stompClient.send("/app/returnTableNonWorkRecordGroup", {}, JSON.stringify({
            "iduser": this.user.id
        }));
    }

    saveNewVacaciones() {

        document.getElementById("botonGuardarVacaciones").disabled = true

        this.Json = this.jsonModalVacaciones()

        if (this.Json.error == undefined) {
            document.getElementById('msgErrorVacaciones').style.display = 'none'
            document.getElementById('msgErrorServidorVacaciones').style.display = 'none'

            if (this.idUserModifycator != null) {
                this.Json.id_user_modifier = this.admin.id
                this.Json.id = this.idItem
                this.subsciptionMO_OU = this.props.stompClient.subscribe('/user/queue/modifyNonWorkRecordSubscribe', this.returnVacacionesModified, { id: "subsciptionMO_OU" });
                this.props.stompClient.send("/app/modifyNonWorkRecord", {}, JSON.stringify(this.Json));
            } else {

                this.Json.id_user_creator = this.admin.id

                this.subsciptionCO_OU = this.props.stompClient.subscribe('/user/queue/createNonWorkRecordSubscribe', this.returnNewVacaciones, { id: "subsciptionCO_OU" });
                this.props.stompClient.send("/app/createNonWorkRecord", {}, JSON.stringify(this.Json));
                this.idUserModifycator = null
            }
        } else {
            document.getElementById("botonGuardarVacaciones").disabled = false
            document.getElementById('msgErrorVacaciones').style.display = 'block'
            document.getElementById('msgErrorServidorVacaciones').style.display = 'none'
        }

    }

    returnJSONModalVacaciones(callbackModalVacaciones) {
        this.jsonModalVacaciones = callbackModalVacaciones
    }

    render() {
        return (
            <div id="formVacaciones">

                <div id="divFormVacaciones">
                    <div id="optionsVacacionesForm" className="form-group row">
                        <div className="col-sm-1 form-group row">
                            <button type="button" class="btn-success" data-toggle="modal" onClick={this.createNewVacaciones}>
                                Alta
                            </button>
                        </div>
                        <div className="col-sm-3 form-group row">
                            <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Inicio</label>
                            <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetBeforeRef} onClick={this.changeToCustom} />
                        </div>
                        <div className="col-sm-3 form-group row">
                            <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Fin</label>
                            <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetAfterRef} onClick={this.changeToCustom} />
                        </div>

                        <div className="col-sm-1.5 form-group row">
                            <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" onChange={this.datesDefault} ref={this.selectOption}>
                                <option value="Today">Hoy</option>
                                <option value="ThisM">Este mes</option>
                                <option value="ThisY">Este año</option>
                                <option value="All">Todos los Tiempos</option>
                                <option value="LastD">Ayer</option>
                                <option value="LastW">La semana Pasada</option>
                                <option value="LastM">El mes Pasado</option>
                                <option value="LastY">Año Pasado</option>
                                <option disabled>Custom</option>
                            </select>
                        </div>

                        <div className="col-sm-1 form-group row">
                            <button type="button" className="btn btn-primary" onClick={this.search}>Buscar</button>
                        </div>

                        <div id="modalVacaciones">

                        </div>
                    </div>
                    <div id="tableVacaciones">

                    </div>

                </div>
            </div>
        );
    }
}

export default VacacionesUser;
