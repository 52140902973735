import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalGeneratePasswd extends Component {

    constructor(props) {
        super(props);
        this.returnPassword = this.returnPassword.bind(this);
        this.forceToChange = this.forceToChange.bind(this);
        this.returnCbForce = this.returnCbForce.bind(this);


        this.inputPasswd = React.createRef();
        this.inputPasswdRepeat = React.createRef();
        this.resetPass = false

    }

    returnPassword() {

        if (this.inputPasswd.current.value == "" || this.inputPasswdRepeat.current.value == "") {
            document.getElementById("msgErrorPasswordEmpty").style.display = "block"
            document.getElementById("msgErrorPasswordStrong").style.display = "none"
            document.getElementById("msgErrorPasswordCoincide").style.display = "none"

        } else {
            document.getElementById("msgErrorPasswordEmpty").style.display = "none"
            if (this.inputPasswd.current.value == this.inputPasswdRepeat.current.value) {
                if (this.props.isAdmin != true) {
                    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[ A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~€ºª]{6,}$/.test(this.inputPasswd.current.value)) {
                        document.getElementById("msgErrorPasswordStrong").style.display = "none"
                        this.props.generatePasswd(this.inputPasswd.current.value, this.resetPass)
                    } else {
                        document.getElementById("msgErrorPasswordStrong").style.display = "block"
                        document.getElementById("msgErrorPasswordCoincide").style.display = "none"
                        document.getElementById("msgErrorPasswordEmpty").style.display = "none"
                    }
                }
                else {
                    document.getElementById("msgErrorPasswordStrong").style.display = "none"
                    this.props.generatePasswd(this.inputPasswd.current.value, this.resetPass)
                }
            } else {
                document.getElementById("msgErrorPasswordCoincide").style.display = "block"
                document.getElementById("msgErrorPasswordStrong").style.display = "none"
                document.getElementById("msgErrorPasswordEmpty").style.display = "none"
            }
        }
    }
    returnCloseButton() {
        if (this.props.isAdmin == true) {
            return <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>;
        }
    }

    returnCbForce(state) {
        if (this.props.isAdmin == true) {
            return (
                <div class="form-group row">
                    <label for="colFormLabel" class="col-sm-10">Obligar a cambiar contraseña en el próximo inicio de sesion</label>
                    <div >
                        <input type="checkbox" id="cbChangePass" onChange={this.forceToChange} />
                    </div>
                </div>
            );
        }
    }

    forceToChange() {
        this.resetPass = document.getElementById("cbChangePass").checked
    }

    render() {
        return (
            <div class="modal fade" id="modalFormPasswd" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
                data-keyboard="false" data-backdrop="static"
            >
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Cambiar Contraseña</h5>
                        </div>
                        <div class="modal-body">
                            <p>La contraseña tiene que tener como minimo, una letra, mayúscula y minúscula, un número y seis caracteres</p>

                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Nueva Contraseña</label>
                                <div class="col-sm-9">
                                    <input type="password" class="form-control" id="colFormLabel" ref={this.inputPasswd} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Repite la Nueva Contraseña</label>
                                <div class="col-sm-9">
                                    <input type="password" class="form-control" id="colFormLabel" ref={this.inputPasswdRepeat} />
                                </div>
                            </div>
                            {this.returnCbForce()}

                        </div>

                        <div class="modal-footer">
                            <div>
                                <p id="msgErrorPasswordStrong" style={{ color: 'red', display: 'none' }}>* Error, la contraseña no es suficientemente robusta</p>
                                <p id="msgErrorPasswordEmpty" style={{ color: 'red', display: 'none' }}>* Error, la contraseña no puede estar vacia</p>
                                <p id="msgErrorPasswordCoincide" style={{ color: 'red', display: 'none' }}>* Error, la contraseña no coincide</p>
                            </div>
                            <div>
                                {this.returnCloseButton()}
                                <button type="button" class="btn btn-primary" onClick={this.returnPassword}>Restablecer Contraseña</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalGeneratePasswd;
