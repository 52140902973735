import React, { Component } from "react";
import ReactDOM from 'react-dom';
import TableUsersSearch from '../tablasForm/TableUsersSearch';
import '../../../css/formPersonal/ModalSearchUser.css';

class ModalSearchUser extends Component {

  constructor(props) {
    super(props);

    this.searchEmpleado = this.searchEmpleado.bind(this);
    this.returnCurrentRecords = this.returnCurrentRecords.bind(this);


  }

  componentDidMount() {
    this.subsciptionUNCSS_MSU = this.props.stompClient.subscribe('/user/queue/getUserByNameCointainingSearchSubscribe', this.returnCurrentRecords, { id: "subsciptionUNCSS_MSU" });
    this.props.stompClient.send("/app/getUserByNameCointainingSearch", {}, JSON.stringify({ 'nombre': '' }));
  }

  componentWillUnmount() {
  }

  searchEmpleado(e) {
    this.subsciptionUNCSS_MSU = this.props.stompClient.subscribe('/user/queue/getUserByNameCointainingSearchSubscribe', this.returnCurrentRecords, { id: "subsciptionUNCSS_MSU" });
    this.props.stompClient.send("/app/getUserByNameCointainingSearch", {}, JSON.stringify({ 'nombre': e.target.value }));
  }

  returnCurrentRecords(msg) {
    this.subsciptionUNCSS_MSU.unsubscribe("subsciptionUNCSS_MSU");
    ReactDOM.unmountComponentAtNode(document.getElementById('tableUsersSearchs'))
    ReactDOM.render(
      <TableUsersSearch param={msg.body} selectUser={this.props.selectUser} />,
      document.getElementById('tableUsersSearchs')
    );
  }

  render() {
    return (
      <div class="modal fade" id="modalSearchUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Selecciona un empleado</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <div class="form-group row">
                <label for="inputEmail3" class="col-sm-4 col-form-label" >Nombre Empleado</label>
                <div class="col-sm-7">
                  <input type="email" class="form-control" id="inputEmail3" placeholder="nombre" onChange={this.searchEmpleado} />
                </div>
              </div>

              <div id="tableUsersSearchs">

              </div>

            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalSearchUser;