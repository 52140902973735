import React, { Component } from "react";

class TableUsersSearch extends Component {

    constructor(props) {
        super(props);
        this.getUser = this.getUser.bind(this)
    }

    getUser(item) {

        if (item.id == 0) {
            return (
                <tr onClick={() => this.props.selectUser(item)}>
                    <th>{item.nombre}</th>
                    <th>{item.apellidos}</th>
                </tr>
            )
        } else {
            return (
                <tr onClick={() => this.props.selectUser(item)}>
                    <td>{item.nombre}</td>
                    <td>{item.apellidos}</td>
                </tr>
            )
        }
    }

    render() {
        return (
            <div class="table-wrapper" id="tableDirecciones">
                <table class="table">
                    <thead class="table-dark">
                        <tr >
                            <th>Nombre</th>
                            <th>Apellido</th>
                        </tr>
                    </thead>
                    <tbody >
                        {JSON.parse(this.props.param).map(item => (
                            this.getUser(item)
                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableUsersSearch;
