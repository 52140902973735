import React, { Component } from "react";
import ReactDOM from 'react-dom';
import Login from './Login';
import User from './User';
import CurrentRecords from './CurrentRecords';
import ModalRecord from './formPersonalComponents/modalesForm/ModalRecord';
import $ from 'jquery';
import ModalChangePasswd from './formPersonalComponents/modalesForm/ModalChangePasswd';
import '../css/User.css';
import '../css/HistorialRecords.css';
import AdminPage from "./AdminPage"
import Incidencias from "./Incidencias";
import HistorialCumulativeHoursUser from "./HistorialCumulativeHoursUser";

class HistorialRecords extends Component {

    constructor(props) {
        super(props);
        this.userInfo = this.props.userInfo

        this.cargarFichaje = this.cargarFichaje.bind(this);
        this.cargarLogin = this.cargarLogin.bind(this);
        this.datesDefault = this.datesDefault.bind(this);
        this.changeToCustom = this.changeToCustom.bind(this);
        this.search = this.search.bind(this);
        this.returnLocalDate = this.returnLocalDate.bind(this);
        this.returnName = this.returnName.bind(this);
        this.changePasswd = this.changePasswd.bind(this);
        this.passwordChanged = this.passwordChanged.bind(this);
        this.daysInMonth = this.daysInMonth.bind(this);
        this.returnTipoJornada = this.returnTipoJornada.bind(this);
        this.cargarUsuario = this.cargarUsuario.bind(this);
        this.cargarIncidencias = this.cargarIncidencias.bind(this);
        this.cargarHistorialCalculo = this.cargarHistorialCalculo.bind(this);
        this.showDropdown = this.showDropdown.bind(this);
        this.hideDropdown = this.hideDropdown.bind(this);

        this.cantidadTotalRecords = 0
        this.dateTargetBeforeRef = React.createRef();
        this.dateTargetAfterRef = React.createRef();
        this.selectOption = React.createRef();
        this.hlocalDate = React.createRef();

        this.cantidadRecords = 0

    }

    cargarUsuario() {
        ReactDOM.render(
            <AdminPage userInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('root')
        );
    }

    componentDidMount() {

        ReactDOM.unmountComponentAtNode(document.getElementById('modalContraseña'))
        ReactDOM.render(
            <ModalChangePasswd checkPasswd={this.props.checkPasswd} isAdmin={false} />,
            document.getElementById('modalContraseña')
        );
        $('#modalFormPasswd').modal('hide')

        ReactDOM.render(
            <ModalRecord />,
            document.getElementById('historialRecordsmodalRecord')
        );

        this.subsciptionN_CR = this.props.stompClient.subscribe('/user/queue/getAllUsersNameAndIdSubscribe', this.returnName, { id: "subsciptionN_CR" });
        this.props.stompClient.send("/app/getAllUsersNameAndId", {});

        //this.search()

        if (this.userInfo.admin) {
            ReactDOM.render(
                <div class="container">
                    <button onClick={this.cargarUsuario} type="button" class="btn font-weight-bold" style={{ backgroundColor: "green", marginLeft: "20px" }}>Vista admin</button>
                    <button class="navbar-brand btn btn-danger font-weight-bold" onClick={this.cargarLogin} type="button">SALIR</button>
                    <a class="navbar-brand" onClick={this.cargarFichaje} >Fichaje</a>
                    <div class="btn-group" id="divHistorial">
                        <a class="navbar-brand" id="cargarHistorial" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown} style={{ backgroundColor: '#2F6D6C', paddingLeft: '1%', paddingRight: '1%', borderRadius: '22px' }}>Historial ☰</a>
                        <div class="dropdown-menu dropdown-menu-right" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown}>
                            <a class="dropdown-item" href="#">Historial</a>
                            <a class="dropdown-item" onClickCapture={this.cargarHistorialCalculo}>Historial h/Mensuales</a>
                        </div>
                    </div>
                    <a class="navbar-brand" onClick={this.cargarIncidencias}>Incidencias</a>
                    <a class="navbar-brand" data-toggle="modal" data-target="#modalFormChangePasswd">Cambiar Contraseña</a>
                </div>, document.getElementById("menuUser")
            );
        } else {
            ReactDOM.render(
                <div class="container">
                    <button class="navbar-brand btn btn-danger font-weight-bold" onClick={this.cargarLogin} type="button">SALIR</button>
                    <a class="navbar-brand" onClick={this.cargarFichaje}>Fichaje</a>
                    <div class="btn-group" id="divHistorial">
                        <a class="navbar-brand" id="cargarHistorial" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown} style={{ backgroundColor: '#2F6D6C', paddingLeft: '1%', paddingRight: '1%', borderRadius: '22px' }}>Historial ☰</a>
                        <div class="dropdown-menu dropdown-menu-right" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown}>
                            <a class="dropdown-item" href="#">Historial</a>
                            <a class="dropdown-item" onClickCapture={this.cargarHistorialCalculo}>Historial h/Mensuales</a>
                        </div>
                    </div>
                    <a class="navbar-brand" onClick={this.cargarIncidencias}>Incidencias</a>
                    <a class="navbar-brand" data-toggle="modal" data-target="#modalFormChangePasswd">Cambiar Contraseña</a>
                </div>
                , document.getElementById("menuUser")
            );
        }
    }

    componentWillUnmount() {

    }


    cargarIncidencias() {
        this.contarTiempoDescanso = false
        ReactDOM.render(
            <Incidencias userInfo={this.userInfo} checkPasswd={this.checkPasswd} stompClient={this.props.stompClient} />,
            document.getElementById('root')
        );
    }

    returnTipoJornada() {

        this.subsciptionRCR = this.props.stompClient.subscribe('/user/queue/getJornadaByUserSubscribe', (msg) => {

            if (document.getElementById("jornada") != null) {
                document.getElementById("jornada").innerHTML = "Jornada: " + JSON.parse(msg.body).tipo
            }

        });
        this.props.stompClient.send("/app/getJornadaByUser", {}, JSON.stringify({
            "jornada": this.userInfo.jornada,
        }));
    }

    returnLocalDate(msg) {
        this.localDate = msg.body
        this.localDate = this.localDate.replaceAll('"', "")
        this.splitDate = this.localDate.split("-")
        this.anyo = this.splitDate[0]
        this.mes = this.splitDate[1]
        this.dia = this.splitDate[2]

        this.hlocalDate.current.textContent = this.localDate

        this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
        this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;

        this.subsciptionLS_HR.unsubscribe("subsciptionLS_HR");

        this.search()

    }

    cargarHistorialCalculo(){
        ReactDOM.render(
            <HistorialCumulativeHoursUser userInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('root')
        );

    }

    timerHours(timeEntry, timeExit) {
        if (timeExit != null) {
            this.timeDiff = ((parseInt(timeExit.split(":")[0]) * 3600) + (parseInt(timeExit.split(":")[1]) * 60) + (parseInt(timeExit.split(":")[2]))) - ((parseInt(timeEntry.split(":")[0]) * 3600) + (parseInt(timeEntry.split(":")[1]) * 60) + (parseInt(timeEntry.split(":")[2])));

            this.horasDiff = Math.floor(this.timeDiff / 3600);
            this.minutosDiff = Math.floor((this.timeDiff - (this.horasDiff * 3600)) / 60);
            this.secondsDiff = this.timeDiff - (this.horasDiff * 3600) - (this.minutosDiff * 60);

            if (this.horasDiff < 10) { this.horasDiff = "0" + this.horasDiff; }
            if (this.minutosDiff < 10) { this.minutosDiff = "0" + this.minutosDiff; }
            if (this.secondsDiff < 10) { this.secondsDiff = "0" + this.secondsDiff; }
            return this.horasDiff + ":" + this.minutosDiff + ":" + this.secondsDiff;
        }

    }
    cargarLogin() {
        ReactDOM.render(
            <Login showError={false} />, document.getElementById('root')
        );
    }
    changeToCustom(e) {
        this.selectOption.current.value = "Custom"
    }

    async search() {

        if (this.dateTargetAfterRef.current.value === "" || this.dateTargetBeforeRef.current.value === "") {

        } else {
            ReactDOM.unmountComponentAtNode(document.getElementById('historialRecordsCurrentRecords'))
            const limit = 100
            var offset = 0
            var continuar = true
            var arrayRecords = []
            var arrayTemporal = []

            do {
                await new Promise((resolve) => {
                    this.props.stompClient.send("/app/historialByDate", {}, JSON.stringify({
                        "dateBefore": this.dateTargetBeforeRef.current.value,
                        "dateAfter": this.dateTargetAfterRef.current.value,
                        "iduser": this.userInfo.id,
                        "valorInicial": offset,
                        "limit": limit,
                    }));
                    this.subsciptionRHD_HR = this.props.stompClient.subscribe('/user/queue/recordHistorialByDate', (msg) => {
                        arrayTemporal = []
                        arrayTemporal = JSON.parse(msg.body)

                        if (!arrayTemporal.length == 0) {
                            offset += arrayTemporal.length
                            if (continuar) {
                                arrayRecords = arrayRecords.concat(arrayTemporal)
                            }

                            if (arrayTemporal.length < limit) {
                                continuar = false
                                arrayTemporal = []
                            }
                        } else {
                            continuar = false
                            arrayTemporal = []
                        }

                        setTimeout(() => {
                            resolve()
                        }, this.subsciptionRHD_HR.unsubscribe())

                    }, { id: "subsciptionRHD_HR" });
                });

            } while (continuar)

            ReactDOM.unmountComponentAtNode(document.getElementById('historialRecordsCurrentRecords'))

            ReactDOM.render(
                <CurrentRecords canMod={true} param={arrayRecords} users={this.JsonUsers[0]} stompClient={this.props.stompClient} modRecord={this.modRecord} />,
                document.getElementById('historialRecordsCurrentRecords')
            );
        }
    }

    cargarFichaje() {
        ReactDOM.render(
            <User userInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('root')
        );
    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    datesDefault(e) {
        this.date = new Date()

        this.mesMesPasado = this.date.getMonth()
        this.anyoMesPasado = this.date.getFullYear()

        if (this.mesMesPasado == 0) {
            this.mesMesPasado = 11
            this.anyoMesPasado = this.date.getFullYear() + 1
        }

        this.lenghtMes = new Date(this.anyoMesPasado, this.anyoMesPasado, 0).getDate();
        switch (e.target.value) {
            case 'ThisM':
                var d = new Date();

                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-01";
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + (this.daysInMonth(d.getMonth() + 1, d.getFullYear())).toString();
                break;

            case 'LastD':
                var d = new Date();
                d.setDate(d.getDate() - 1)
                if (d.getDate() < 10) {
                    var diaAnterior = "0" + d.getDate().toString()
                }
                else {
                    var diaAnterior = d.getDate().toString()
                }
                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + diaAnterior;
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + diaAnterior;

                break;
            case 'Today':
                this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                break;
            case 'All':

                this.dateTargetBeforeRef.current.value = "1980-01-01";
                this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
                break;
            case 'LastW':
                var dateWeekBefore = new Date();
                var dateWeekAfter = new Date();

                var pastWeekDate = dateWeekAfter.getDate() - this.date.getDay();
                dateWeekAfter.setDate(pastWeekDate);
                dateWeekAfter.setMonth(dateWeekAfter.getMonth() + 1)

                var pastWeekDate = dateWeekBefore.getDate() - this.date.getDay() - 6;
                dateWeekBefore.setDate(pastWeekDate);
                dateWeekBefore.setMonth(dateWeekBefore.getMonth() + 1)

                var diaLastWeek1 = dateWeekBefore.getDate().toString()
                var diaLastWeek2 = dateWeekAfter.getDate().toString()

                var mesLastWeek1 = dateWeekBefore.getMonth().toString()
                var mesLastWeek2 = dateWeekAfter.getMonth().toString()

                var anyoLastWeek1 = dateWeekBefore.getFullYear().toString()
                var anyoLastWeek2 = dateWeekAfter.getFullYear().toString()


                if (diaLastWeek1 <= 9) {
                    diaLastWeek1 = "0" + diaLastWeek1
                }

                if (diaLastWeek2 <= 9) {
                    diaLastWeek2 = "0" + diaLastWeek2
                }

                if (mesLastWeek1 <= 9) {
                    mesLastWeek1 = "0" + mesLastWeek1
                }

                if (mesLastWeek2 <= 9) {
                    mesLastWeek2 = "0" + mesLastWeek2
                }

                this.dateTargetBeforeRef.current.value = anyoLastWeek1 + "-" + mesLastWeek1 + "-" + diaLastWeek1
                this.dateTargetAfterRef.current.value = anyoLastWeek2 + "-" + mesLastWeek2 + "-" + diaLastWeek2

                break;

            case 'LastM':
                var d = new Date();
                d.setMonth(d.getMonth() - 1);
                if ((parseInt(this.mes) - 1) != 0) {
                    if ((parseInt(this.mes) - 1) <= 9) {

                        this.dateTargetBeforeRef.current.value = this.anyo + "-0" + (parseInt(this.mes) - 1) + "-01";
                        this.dateTargetAfterRef.current.value = this.anyo + "-0" + (parseInt(this.mes) - 1) + "-" + this.daysInMonth(d.getMonth(), d.getFullYear());
                    } else {

                        this.dateTargetBeforeRef.current.value = this.anyo + "-" + (parseInt(this.mes) - 1) + "-01";
                        this.dateTargetAfterRef.current.value = this.anyo + "-" + (parseInt(this.mes) - 1) + "-" + this.daysInMonth(d.getMonth() + 1, d.getFullYear());
                    }
                } else {

                    this.dateTargetBeforeRef.current.value = (parseInt(this.anyo) - 1) + "-12-01";
                    this.dateTargetAfterRef.current.value = (parseInt(this.anyo) - 1) + "-12-" + this.daysInMonth(d.getMonth(), d.getFullYear());
                }

                break;
            case 'LastY':
                this.dateTargetBeforeRef.current.value = (parseInt(this.anyo) - 1) + "-01-01";
                this.dateTargetAfterRef.current.value = (parseInt(this.anyo) - 1) + "-12-31";

                break;

            default:
                break;
        }
        this.search()

    }

    hideDropdown() {
        $('.dropdown-menu').dropdown().hide()
      }
    
      showDropdown() {
        $('.dropdown-menu').dropdown().show()
      }
    

    returnName(msg) {
        this.JsonUsers = JSON.parse(msg.body)
        this.subsciptionN_CR.unsubscribe("subsciptionN_CR");

        this.subsciptionLS_HR = this.props.stompClient.subscribe('/user/queue/localdateSubscribe', this.returnLocalDate, { id: "subsciptionLS_HR" });
        this.props.stompClient.send("/app/localdate", {});
    }

    changePasswd() {

        this.subsciptionVU_U.unsubscribe("subsciptionVU_U");
        this.subsciptionCP_U = this.props.stompClient.subscribe('/user/queue/changePasswdSubscribe', this.passwordChanged, { id: "subsciptionCP_U" });
        this.props.stompClient.send("/app/changePasswd", {}, JSON.stringify({
            "id": this.userInfo.id,
            "passwd": this.newPasswd,
        }));
    }

    passwordChanged() {
        $('#modalFormChangePasswd').modal('hide')
        this.subsciptionCP_U.unsubscribe("subsciptionCP_U");
    }


    render() {
        return (
            <div id="generalTablHistorialRecords">

                <div id="divHeaderHistorialRecords">
                    <nav class="navbar navbar-dark bg-dark" id="menuUser">
                    </nav>
                    <div class="container">
                        <div class="form-group row">
                            <div class="col-md-4 col-form-label">
                                <h2 id="jornada" onLoad={this.returnTipoJornada()}>Jornada: </h2>
                            </div>
                            <div class="col-md-4 col-form-label">
                                <h2 id="empleado">Empleado: {this.userInfo.nombre}</h2>
                            </div>
                            <div class="col-md-4 col-form-label" id="fecha">
                                <h2 id="fecha" ref={this.hlocalDate} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" id="searchHistorialAdmin">
                    <div className="col-sm-2 form-group row">
                        <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" onChange={this.datesDefault} ref={this.selectOption}>
                            <option value="Today">Hoy</option>
                            <option value="All">Todos los Tiempos</option>
                            <option value="LastW">La semana Pasada</option>
                            <option value="LastM">El mes Pasado</option>
                            <option value="LastY">Año Pasado</option>
                            <option disabled>Custom</option>
                        </select>
                    </div>

                    <div className="col-sm-4 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Inicio</label>
                        <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetBeforeRef} onClick={this.changeToCustom} />
                    </div>

                    <div className="col-sm-4 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Fin</label>
                        <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetAfterRef} onClick={this.changeToCustom} />
                    </div>
                    <div className="col-sm-1 form-group row">

                        <button type="button" className="btn btn-primary" onClick={this.search}>Buscar</button>
                    </div>
                </div>
                <div id="modalContraseña">

                </div>
                <div id="historialRecordsCurrentRecords">

                </div>
                <div id="historialRecordsmodalRecord">

                </div>

            </div>
        );
    }
}

export default HistorialRecords;
