import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css'

class ModalFestivo extends Component {

    constructor(props) {
        super(props);


    }
    render() {
        return (
            <div class="modal fade" id="modalFormFestivo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Creando Nuevo festivo</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <input type="text" class="form-control" id="id_festivo" readOnly hidden />
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Día del festivo</label>
                                <div class="col-8">
                                    <input class="form-control" type="date" id="inputDateGo" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-4 col-form-label">Tipo de festivo</label>
                                <div class="col-8">
                                    <select id="inputState" class="form-control" id="inputTipoFestivo">
                                        <option value="Fiesta Local">Fiesta Local</option>
                                        <option value="Fiesta Estatal">Fiesta Estatal</option>
                                        <option value="Fiesta Nacional">Fiesta Nacional</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Descripción</label>
                                <div class="col-8">
                                    <input class="form-control" type="text" id="DescripcionFestivo" />
                                </div>
                            </div>


                            <div class="modal-footer">
                                <div>
                                    <p id="msgErrorFestivoNoValido" style={{ color: 'red', display: 'none' }}>* Error, algunos campos estan vacios, compruebe los datos</p>
                                    <p id="msgErrorServidorFestivo" style={{ color: 'red', display: 'none' }}>* Error, el servidor no ha podido crear el festivo</p>

                                </div>
                                <div>
                                    <button type="button" class="btn btn-danger" id="botonBorrarFestivo" data-dismiss="modal" onClick={this.props.deleteFestivo} style={{ display: 'none' }}>Borrar</button>
                                    <button type="button" class="btn btn-secundary" data-dismiss="modal">Cerrar</button>
                                    <button type="button" class="btn btn-primary" id="botonCrearFestivo" onClick={this.props.valideAndCreateNewFestivo}>Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalFestivo;
