import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class TablaDepartamentos extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div class="table-wrapper" id="tableDepartamento">
                <table class="table">
                    <thead class="table-dark">
                        <tr >
                            <th>Id</th>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.param.map(item => (
                            <tr onDoubleClick={() => this.props.modifyDepartamento(item)}>
                                <th style={{ width: '20%' }}>{item.id}</th>
                                <td style={{ width: '80%' }}>{item.nombre}</td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TablaDepartamentos;
