import React, { Component } from "react";
import ReactDOM from 'react-dom';
import TablaContactos from './tablasForm/TablaContactos';
import TablaDirecciones from './tablasForm/TablaDirecciones';
import ModalContactos from './modalesForm/ModalContactos';
import ModalDirecciones from './modalesForm/ModalDirecciones';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/formPersonal/DireccionUser.css';

class DireccionUser extends Component {

    constructor(props) {
        super(props);
        this.returnDirecciones = this.returnDirecciones.bind(this);
        this.modifyContact = this.modifyContact.bind(this);
        this.modifyDirecciones = this.modifyDirecciones.bind(this);
        this.returnJSONModalDirection = this.returnJSONModalDirection.bind(this);
        this.returnJSONModalContact = this.returnJSONModalContact.bind(this);
        this.returnContactos = this.returnContactos.bind(this);
        this.saveNewDirection = this.saveNewDirection.bind(this);
        this.saveNewContact = this.saveNewContact.bind(this);
        this.returnNewDirection = this.returnNewDirection.bind(this);
        this.returnNewContact = this.returnNewContact.bind(this);
        this.returnDirectionModified = this.returnDirectionModified.bind(this);
        this.returnContactModified = this.returnContactModified.bind(this);
        this.deleteDirecciones = this.deleteDirecciones.bind(this);
        this.loadAllAddresses = this.loadAllAddresses.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
        this.loadAllContacts = this.loadAllContacts.bind(this);

        this.renderizarDireccion = this.renderizarDireccion.bind(this);
        this.altaNewDireccion = this.altaNewDireccion.bind(this);
        this.renderizarContactos = this.renderizarContactos.bind(this);
        this.altaNewContacto = this.altaNewContacto.bind(this);

        this.user = this.props.user
        this.admin = this.props.adminInfo

        this.inputCalle = React.createRef();
        this.inputLocalidad = React.createRef();
        this.inputCodPostal = React.createRef();
        this.inputPais = React.createRef();
        this.inputComunidad = React.createRef();
        this.inputProvincia = React.createRef();

        this.inputTipoContacto = React.createRef();
        this.inputValueContacto = React.createRef();

    }

    componentDidMount() {

        this.subsciptionTD_DU = this.props.stompClient.subscribe('/user/queue/returnTableDireccionesSubscribe', this.returnDirecciones, { id: "subsciptionTD_DU" });
        this.props.stompClient.send("/app/returnTableDirecciones", {}, JSON.stringify({
            "iduser": this.user.id
        }));

        this.subsciptionTC_DU = this.props.stompClient.subscribe('/user/queue/returnTableContactosSubscribe', this.returnContactos, { id: "subsciptionTC_DU" });
        this.props.stompClient.send("/app/returnTableContactos", {}, JSON.stringify({
            "iduser": this.user.id
        }));

        this.componentWillUnmount(document.getElementById('modalDirecciones'))
        ReactDOM.render(
            <ModalDirecciones user={this.props.user} adminInfo={this.props.adminInfo} saveNew={this.saveNewDirection} deleteDirecciones={this.deleteDirecciones} doJson={this.returnJSONModalDirection} />,
            document.getElementById('modalDirecciones')
        );
        this.componentWillUnmount(document.getElementById('modalContactos'))
        ReactDOM.render(
            < ModalContactos user={this.props.user} adminInfo={this.props.adminInfo} saveNew={this.saveNewContact} deleteContact={this.deleteContact} doJson={this.returnJSONModalContact} />,
            document.getElementById('modalContactos')
        );
        $('#modalDirecciones').on('hide.bs.modal', function () {
            document.getElementById('btn-deleteMVL').style.display = "none"
        })
        $('#modalContactos').on('hide.bs.modal', function () {
            document.getElementById('btn-deleteMVL2').style.display = "none"
        })
    }
    componentWillUnmount() {

    }

    saveNewDirection(e) {

        document.getElementById("botonCrearDireccion").disabled = true

        this.Json = this.jsonModalDirection()
        let codigoPostal = parseInt(this.Json.cod_postal)

        if (this.Json.error == undefined) {
            document.getElementById('msgErrorDireccion').style.display = "none"
            if (Number.isInteger(+this.Json.cod_postal)) {
                document.getElementById('msgErrorDireccionesCodigoPostal').style.display = "none"
                if (this.idUserModifycator != null) {
                    this.Json.id_user_modifier = this.idUserModifycator
                    this.Json.id = this.idItem
                    this.subsciptionMA_DU = this.props.stompClient.subscribe('/user/queue/modifyAddressSubscribe', this.returnDirectionModified, { id: "subsciptionMA_DU" });
                    this.props.stompClient.send("/app/modifyAddress", {}, JSON.stringify(this.Json));
                } else {

                    this.subsciptionCA_DU = this.props.stompClient.subscribe('/user/queue/createAddressSubscribe', this.returnNewDirection, { id: "subsciptionCA_DU" });
                    this.props.stompClient.send("/app/createAddress", {}, JSON.stringify(this.Json));
                }
            } else {
                document.getElementById("botonCrearDireccion").disabled = false
                document.getElementById('msgErrorDireccionesCodigoPostal').style.display = "block"
                document.getElementById('msgErrorServidorDireccion').style.display = "none"

            }
        } else {
            document.getElementById("botonCrearDireccion").disabled = false
            document.getElementById('msgErrorDireccion').style.display = "block"
            document.getElementById('msgErrorServidorDireccion').style.display = "none"
        }

        this.idUserModifycator = null
    }

    altaNewDireccion() {

        document.getElementById("botonCrearDireccion").disabled = false

        this.renderizarDireccion()

        document.getElementById('id_direccions').value = ""
        document.getElementById('calle_id').value = ""
        document.getElementById('localidad_id').value = ""
        document.getElementById('comunidad_id').value = ""
        document.getElementById('provincia_id').value = ""
        document.getElementById('cp_id').value = ""
        document.getElementById('pais_id').value = ""
        document.getElementById('btn-deleteMVL').style.display = "none"
        document.getElementById("btnDireccionPrincipal").innerHTML = "No Principal"

        this.idUserModifycator = null

        $('#modalFormDireccion').modal('show')

    }

    renderizarDireccion() {
        document.getElementById("msgErrorDireccionesCodigoPostal").style.display = "none"
        document.getElementById("msgErrorDireccion").style.display = "none"
        document.getElementById("msgErrorServidorDireccion").style.display = "none"
        this.componentWillUnmount(document.getElementById('modalDirecciones'))
        ReactDOM.render(
            <ModalDirecciones user={this.props.user} adminInfo={this.props.adminInfo} saveNew={this.saveNewDirection} deleteDirecciones={this.deleteDirecciones} doJson={this.returnJSONModalDirection} />,
            document.getElementById('modalDirecciones')
        );
    }

    saveNewContact(e) {

        document.getElementById("botonGuardarContacto").disabled = true

        this.Json = this.jsonModalContact()

        if (this.Json.error == undefined) {
            document.getElementById('msgErrorContacto').style.display = 'none'
            if (this.idUserModifycator != null) {
                this.Json.id_user_modifier = this.idUserModifycator
                this.Json.id = this.idItem
                $('#modalFormContacto').modal('hide')
                this.subsciptionMC_DU = this.props.stompClient.subscribe('/user/queue/modifyContactSubscribe', this.returnContactModified, { id: "subsciptionMC_DU" });
                this.props.stompClient.send("/app/modifyContacts", {}, JSON.stringify(this.Json));
            } else {

                this.subsciptionCC_DU = this.props.stompClient.subscribe('/user/queue/createContactSubscribe', this.returnNewContact, { id: "subsciptionCC_DU" });
                this.props.stompClient.send("/app/createContacts", {}, JSON.stringify(this.Json));
            }
        } else {
            document.getElementById("botonGuardarContacto").disabled = false
            document.getElementById('msgErrorContacto').style.display = 'block'
            document.getElementById("msgErrorServidorContacto").style.display = "none"
        }
        this.idUserModifycator = null

    }

    deleteDirecciones(event) {
        this.Json = this.jsonModalDirection()

        this.props.stompClient.send("/app/deleteAddressById", {}, JSON.stringify({
            "id": this.Json.id
        }));
        this.subsciptionTC_DD = this.props.stompClient.subscribe('/user/queue/deleteAddressByIdSubscribe', () => {
            this.subsciptionTC_DU.unsubscribe("subsciptionTC_DD")
            this.loadAllAddresses()


        }, { id: "subsciptionTC_DD" });

    }

    renderizarContactos() {
        document.getElementById('msgErrorContacto').style.display = 'none'
        document.getElementById("msgErrorServidorContacto").style.display = "none"
        this.componentWillUnmount(document.getElementById('modalContactos'))
        ReactDOM.render(
            <ModalContactos user={this.props.user} adminInfo={this.props.adminInfo} saveNew={this.saveNewContact} deleteContact={this.deleteContact} doJson={this.returnJSONModalContact} />,
            document.getElementById('modalContactos')
        );
    }

    altaNewContacto() {
        document.getElementById("botonGuardarContacto").disabled = false

        this.renderizarContactos()

        document.getElementById('idItemContact').value = ""
        document.getElementById('tipoCont_id').value = "Gmail"
        document.getElementById('valueCont_id').value = ""
        document.getElementById('btn-deleteMVL2').style.display = "none"

        document.getElementById("btnContactoPrincipal").innerHTML = "No Principal"


        this.idUserModifycator = null
        $('#modalFormContacto').modal('show')
    }

    deleteContact(event) {
        this.Json = this.jsonModalContact()

        this.props.stompClient.send("/app/deleteContactById", {}, JSON.stringify({
            "id": this.Json.id
        }));
        this.subsciptionTC_DC = this.props.stompClient.subscribe('/user/queue/deleteContactByIdSubscribe', () => {
            this.subsciptionTC_DC.unsubscribe("subsciptionTC_DC")

            this.loadAllContacts()

        }, { id: "subsciptionTC_DC" });
    }

    loadAllContacts() {
        this.subsciptionTC_DU = this.props.stompClient.subscribe('/user/queue/returnTableContactosSubscribe', this.returnContactos, { id: "subsciptionTC_DU" });
        this.props.stompClient.send("/app/returnTableContactos", {}, JSON.stringify({
            "iduser": this.user.id
        }));
    }


    loadAllAddresses() {
        this.subsciptionTD_DU = this.props.stompClient.subscribe('/user/queue/returnTableDireccionesSubscribe', this.returnDirecciones, { id: "subsciptionTD_DU" });
        this.props.stompClient.send("/app/returnTableDirecciones", {}, JSON.stringify({
            "iduser": this.user.id
        }));
    }

    returnContactModified(msg) {

        if (JSON.parse(msg.body).statusCodeValue == 500) {
            document.getElementById("msgErrorServidorContacto").style.display = "block"
            document.getElementById("msgErrorContacto").style.display = "none"
            document.getElementById("botonGuardarContacto").disabled = false
        } else {
            $('#modalFormContacto').modal('hide')

        }
        this.subsciptionMC_DU.unsubscribe("subsciptionCC_DU");
        this.subsciptionTC_DU = this.props.stompClient.subscribe('/user/queue/returnTableContactosSubscribe', this.returnContactos, { id: "subsciptionTC_DU" });
        this.props.stompClient.send("/app/returnTableContactos", {}, JSON.stringify({
            "iduser": this.user.id
        }));
    }

    returnJSONModalDirection(callbackModalDirection) {
        this.jsonModalDirection = callbackModalDirection
    }
    returnJSONModalContact(callbackModalContact) {
        this.jsonModalContact = callbackModalContact
    }

    returnContactModified() {

        this.subsciptionMC_DU.unsubscribe("subsciptionMC_DU");
        this.subsciptionTC_DU = this.props.stompClient.subscribe('/user/queue/returnTableContactosSubscribe', this.returnContactos, { id: "subsciptionTC_DU" });
        this.props.stompClient.send("/app/returnTableContactos", {}, JSON.stringify({
            "iduser": this.user.id
        }));
    }
    returnDirectionModified(msg) {

        if (msg.body != null) {
            if (JSON.parse(msg.body).statusCodeValue == 500) {
                document.getElementById("botonCrearDireccion").disabled = false
                document.getElementById("msgErrorDireccionesCodigoPostal").style.display = "none"
                document.getElementById("msgErrorDireccion").style.display = "none"
                document.getElementById("msgErrorServidorDireccion").style.display = "block"

            } else {
                $('#modalFormDireccion').modal('hide')
            }

            this.subsciptionMA_DU.unsubscribe("subsciptionMA_DU");
            this.subsciptionTD_DU = this.props.stompClient.subscribe('/user/queue/returnTableDireccionesSubscribe', this.returnDirecciones, { id: "subsciptionTD_DU" });
            this.props.stompClient.send("/app/returnTableDirecciones", {}, JSON.stringify({
                "iduser": this.user.id
            }));

        } else {
            document.getElementById("botonCrearDireccion").disabled = false
            document.getElementById('msgErrorDireccionesCodigoPostal').style.display = "block"
            document.getElementById("msgErrorDireccion").style.display = "none"
            document.getElementById("msgErrorServidorDireccion").style.display = "none"
        }

    }

    returnNewDirection(msg) {
        if (msg.body != null) {
            if (JSON.parse(msg.body).statusCodeValue == 500) {
                document.getElementById("botonCrearDireccion").disabled = false
                document.getElementById("msgErrorDireccionesCodigoPostal").style.display = "none"
                document.getElementById("msgErrorDireccion").style.display = "none"
                document.getElementById("msgErrorServidorDireccion").style.display = "block"

            } else {
                $('#modalFormDireccion').modal('hide')
            }


            this.subsciptionCA_DU.unsubscribe("subsciptionCA_DU");
            this.subsciptionTD_DU = this.props.stompClient.subscribe('/user/queue/returnTableDireccionesSubscribe', this.returnDirecciones, { id: "subsciptionTD_DU" });
            this.props.stompClient.send("/app/returnTableDirecciones", {}, JSON.stringify({
                "iduser": this.user.id
            }));

        } else {
            document.getElementById("botonCrearDireccion").disabled = false
            document.getElementById('msgErrorDireccionesCodigoPostal').style.display = "block"
            document.getElementById("msgErrorDireccion").style.display = "none"
            document.getElementById("msgErrorServidorDireccion").style.display = "none"
        }
    }
    returnNewContact(msg) {

        if (JSON.parse(msg.body).statusCodeValue == 500) {
            document.getElementById("msgErrorServidorContacto").style.display = "block"
            document.getElementById("msgErrorContacto").style.display = "none"
            document.getElementById("botonGuardarContacto").disabled = false
        } else {
            $('#modalFormContacto').modal('hide')

        }

        this.subsciptionCC_DU.unsubscribe("subsciptionCC_DU");
        this.subsciptionTC_DU = this.props.stompClient.subscribe('/user/queue/returnTableContactosSubscribe', this.returnContactos, { id: "subsciptionTC_DU" });
        this.props.stompClient.send("/app/returnTableContactos", {}, JSON.stringify({
            "iduser": this.user.id
        }));
    }

    modifyContact(item) {
        document.getElementById("botonGuardarContacto").disabled = false
        this.componentWillUnmount(document.getElementById('modalContactos'))
        ReactDOM.render(
            < ModalContactos isPrincipal={item.principal} user={this.props.user} adminInfo={this.props.adminInfo} saveNew={this.saveNewContact} deleteContact={this.deleteContact} doJson={this.returnJSONModalContact} />,
            document.getElementById('modalContactos')
        );


        document.getElementById('msgErrorContacto').style.display = 'none'
        document.getElementById("msgErrorServidorContacto").style.display = 'none'

        document.getElementById('idItemContact').value = item.id
        document.getElementById('tipoCont_id').value = item.type
        document.getElementById('valueCont_id').value = item.value
        document.getElementById('btn-deleteMVL2').style.display = 'block'
        if (item.principal == true) {
            document.getElementById("btnContactoPrincipal").innerHTML = 'Principal'
        } else {
            document.getElementById("btnContactoPrincipal").innerHTML = 'No Principal'
        }

        this.idUserModifycator = this.admin.id
        this.idItem = item.id

        $('#modalFormContacto').modal('show')

    }

    modifyDirecciones(item) {

        document.getElementById("botonCrearDireccion").disabled = false

        this.componentWillUnmount(document.getElementById('modalDirecciones'))
        ReactDOM.render(
            <ModalDirecciones isPrincipal={item.principal} user={this.props.user} adminInfo={this.props.adminInfo} saveNew={this.saveNewDirection} deleteDirecciones={this.deleteDirecciones} doJson={this.returnJSONModalDirection} />,
            document.getElementById('modalDirecciones')
        );

        document.getElementById('msgErrorDireccionesCodigoPostal').style.display = "none"
        document.getElementById('msgErrorServidorDireccion').style.display = "none"
        document.getElementById('msgErrorDireccion').style.display = "none"


        document.getElementById('id_direccions').value = item.id
        document.getElementById('calle_id').value = item.address
        document.getElementById('localidad_id').value = item.localidad
        document.getElementById('comunidad_id').value = item.com_autonoma
        document.getElementById('provincia_id').value = item.provincia
        document.getElementById('cp_id').value = item.cod_postal
        document.getElementById('pais_id').value = item.pais
        document.getElementById('btn-deleteMVL').style.display = "block"
        if (item.principal == true) {
            document.getElementById("btnDireccionPrincipal").innerHTML = "Principal"
        } else {
            document.getElementById("btnDireccionPrincipal").innerHTML = "No Principal"
        }

        this.idUserModifycator = this.admin.id
        this.idItem = item.id
        $('#modalFormDireccion').modal('show')

    }

    returnDirecciones(msg) {
        this.subsciptionTD_DU.unsubscribe("subsciptionTD_DU");
        if (msg.body != null || msg.body != undefined) {
            this.componentWillUnmount(document.getElementById('tableDirecciones'))
            ReactDOM.render(
                <TablaDirecciones param={msg.body} nombre={this.user.nombre} modifyDirecciones={this.modifyDirecciones} />,
                document.getElementById('tableDirecciones')
            );
        }

    }
    returnContactos(msg) {
        this.subsciptionTC_DU.unsubscribe("subsciptionTC_DU");
        if (msg.body != null || msg.body != undefined) {
            this.componentWillUnmount(document.getElementById('tableContactos'))
            ReactDOM.render(
                <TablaContactos param={msg.body} modifyContact={this.modifyContact} />,
                document.getElementById('tableContactos')
            );
        }
    }


    render() {
        return (
            <div id="formDirecciones">
                <div id="divDirecciones">
                    <div id="optionsDireccionesForm">

                        <button type="button" class="btn-success btn-Form" data-toggle="modal" onClick={this.altaNewDireccion}>
                            Alta
                        </button>

                        <div id="modalDirecciones">

                        </div>

                    </div>

                    <div id="tableDirecciones">

                    </div>

                </div>

                <div id="divContactos">
                    <div id="optionsDireccionesForm">
                        <button type="button" class="btn-success btn-Form" data-toggle="modal" onClick={this.altaNewContacto}>
                            Alta
                        </button>

                        <div id="modalContactos">

                        </div>
                    </div>
                    <div id="tableContactos">

                    </div>

                </div>

            </div>
        );
    }
}

export default DireccionUser;
