import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalBorrarUsuario extends Component {

    constructor(props) {
        super(props);

    }
    render() {
        return (
            <div class="modal fade" id="modalFormBorrarUsuario" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Borrar usuario</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p for="colFormLabel" class="col-form-label">¿Estás seguro que deseas eliminar el usuario {this.props.nombreUsuario}?</p>
                            <br></br>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={() => { this.props.deleteUser(this.props.sendUserInfo()); }} >Borrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalBorrarUsuario;
