import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class TablaVacacionesLocal extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div class="table-wrapper" id="tableDirecciones">
                <table class="table">
                    <thead class="table-dark">
                        <tr>
                            <th>Fecha de Salida</th>
                            <th>Motivo</th>
                        </tr>
                    </thead>
                    <tbody >
                        {JSON.parse(this.props.param).map(item => (

                            <tr onDoubleClick={() => this.props.modifyVacaciones(item)}>
                                <th>{item.dateFirstDay}</th>
                                <th>{item.reason}</th>
                            </tr>

                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TablaVacacionesLocal;
