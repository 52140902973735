import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalInformatiu extends Component {

    constructor(props) {
        super(props);

    }
    render() {
        return (
            <div class="modal fade" id="modalFormInfo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Informativo</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p for="colFormLabel" class="col-form-label">{this.props.msgInfo}</p>
                            <br></br>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalInformatiu;
