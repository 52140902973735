import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalContactos extends Component {

    constructor(props) {
        super(props);
        this.admin = this.props.adminInfo
        this.user = this.props.user
        this.makeJSON = this.makeJSON.bind(this);
        this.checkIfExist = this.checkIfExist.bind(this);
        this.contactoPrincipal = this.contactoPrincipal.bind(this)
        this.mainContact = this.props.isPrincipal

        this.inputTipoContacto = React.createRef();
        this.inputValueContacto = React.createRef();
        this.idItemContact = React.createRef();


    }

    componentDidMount() {
        this.props.doJson(this.makeJSON)
    }


    makeJSON() {
        if (this.inputTipoContacto.current.value != "" && this.inputValueContacto.current.value != "") {
            return this.contactInfo = {
                "id": this.idItemContact.current.value,
                "iduser": this.user.id,
                "type": this.inputTipoContacto.current.value,
                "value": this.inputValueContacto.current.value,
                "id_user_creator": this.admin.id,
                "id_user_modifier": this.admin.id,
                "principal": this.mainContact,
            }
        } else {
            return this.error = {
                "error": "Te has dejado algun campo vacio, para realizar la tarea deben estar completos todos los campos. Vuelve a intentarlo"
            }
        }
    }

    checkIfExist() {
    }

    contactoPrincipal() {
        if (this.mainContact) {
            this.mainContact = false
            document.getElementById("btnContactoPrincipal").innerHTML = "No Principal"
        } else {
            this.mainContact = true
            document.getElementById("btnContactoPrincipal").innerHTML = "Principal"
        }


    }

    render() {
        this.mainContact = this.props.isPrincipal
        return (
            <div class="modal fade" id="modalFormContacto" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Formulario de Alta de un Contacto</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <input id="idItemContact" ref={this.idItemContact} hidden readOnly />
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="tipoCont_id" class="control-label">Tipo</label>
                                <select class="form-control" id="tipoCont_id" ref={this.inputTipoContacto}>
                                    <option value="Gmail">Gmail</option>
                                    <option value="Movil">Movil</option>
                                    <option value="Fijo">Fijo</option>
                                    <option value="Fax">Fax</option>
                                    <option value="Skype">Skype</option>
                                    <option value="Discord">Discord</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="valueCont_id" class="control-label">Contacto</label>
                                <input type="text" class="form-control" id="valueCont_id" name="valueCont" placeholder="correo@gmail.com" ref={this.inputValueContacto} />
                            </div>
                            <div class="form-group row col-md-12 text-center">
                                <button id="btnContactoPrincipal" type="button" class="btn btn-success control-label" onClick={this.contactoPrincipal}></button>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <div>
                                <p id="msgErrorContacto" style={{ color: 'red', display: 'none' }}>* Error, el contacto está vacío</p>
                                <p id="msgErrorServidorContacto" style={{ color: 'red', display: 'none' }}>* Error, no se ha podido crear el contacto</p>
                            </div>
                            <div>
                                <button type="button" id="btn-deleteMVL2" class="btn btn-danger" data-dismiss="modal" onClick={this.props.deleteContact}>Eliminar</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="button" class="btn btn-primary" id="botonGuardarContacto" onClick={this.props.saveNew}>Guardar cambios</button>
                            </div>
                            {this.checkIfExist()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalContactos;
