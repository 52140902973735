import React, { Component } from "react";

class TablaHorasAcumuladasDays extends Component {

    constructor(props) {
        super(props);
        this.calculateHours = this.calculateHours.bind(this);

    }
    componentDidMount() {
    }

    componentWillUnmount() {
    }

    calculateHours(minutes) {
        let negativo = false
        if (minutes < 0) {
            minutes = minutes * -1
            negativo = true
        }

        this.horasDiff = Math.floor(minutes / 60);
        this.minutosDiff = Math.floor(minutes % 60);
        if (this.minutosDiff <= 9) {

            if (negativo) {
                return <th>-{this.horasDiff}h0{this.minutosDiff}m</th>
            }

            return <th>{this.horasDiff}h0{this.minutosDiff}m</th>
        }
        if (negativo) {
            return <th>-{this.horasDiff}h{this.minutosDiff}m</th>
        }

        return <th>{this.horasDiff}h{this.minutosDiff}m</th>
    }

    render() {
        return (
            <div class="table-wrapper" id="tableHorasAcumuladas">
                <table class="table">
                    <thead class="table-dark">
                        <tr>
                            <th>Nombre</th>
                            <th>Horas extras</th>
                        </tr>
                    </thead>
                    <tbody>
                        {JSON.parse(this.props.param).map(item => (
                            <tr>
                                <th>{this.props.users[item.iduser]}</th>
                                {this.calculateHours(item.sumahoras - item.horasTotales)}
                            </tr>

                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TablaHorasAcumuladasDays;
