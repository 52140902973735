import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalObservaciones extends Component {

    constructor(props) {
        super(props);
        this.admin = this.props.adminInfo
        this.user = this.props.user
        this.makeJSON = this.makeJSON.bind(this);

        this.inputTipoObservacion = React.createRef();
        this.inputValueObservacion = React.createRef();
        this.inputIdObservacion = React.createRef();
    }

    componentDidMount() {
        this.props.doJson(this.makeJSON)

    }

    makeJSON() {
        if (this.inputTipoObservacion.current.value != "" && this.inputValueObservacion.current.value != "") {

            return this.contactInfo = {
                "id": this.inputIdObservacion.current.value,
                "iduser": this.user.id,
                "type_observation": this.inputTipoObservacion.current.value,
                "observation": this.inputValueObservacion.current.value,
                "id_user_creator": this.admin.id,
                "id_user_modifier": this.admin.id,

            }

        } else {
            return this.error = {
                "error": "Te has dejado algun campo vacio, para realizar la tarea deben estar completos todos los campos. Vuelve a intentarlo"
            }
        }
    }


    render() {
        return (
            <div class="modal fade" id="modalFormObservaciones" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Formulario de Alta de una Observación</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">
                            <input class="form-control" id="idObservacion" ref={this.inputIdObservacion} readOnly hidden></input>

                            <div class="form-group">
                                <label for="tipoObservacion_id" class="control-label">Tipo de Observación</label>
                                <select class="form-control" id="tipoObservacion_id" ref={this.inputTipoObservacion}>
                                    <option value="Positiva">Positiva</option>
                                    <option value="Negativa">Negativa</option>
                                    <option value="Neutra">Neutra</option>
                                    <option value="Grave">Grave</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="street1_id" class="control-label">Observación</label>
                                <textarea class="form-control" id="observacion_id" placeholder="El empleado ha tenido una actitud ejemplar a la hora de realizar su trabajo" rows="7" ref={this.inputValueObservacion}></textarea>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div>
                                <p id="msgErrorObservacionNoValida" style={{ color: 'red', display: 'none' }}>* Error, algunos campos estan vacios, compruebe los datos</p>
                                <p id="msgErrorObservacionServidor" style={{ color: 'red', display: 'none' }}>* Error, el servidor no ha podido crear la observación</p>

                            </div>
                            <div>
                                <button type="button" id="btn-deleteMVL" class="btn btn-danger" data-dismiss="modal" onClick={this.props.deleteObservaciones}>Eliminar</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="button" class="btn btn-primary" id="botonGuardarObservacion" onClick={this.props.saveNew}>Guardar cambios</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalObservaciones;
