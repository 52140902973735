import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalObservaciones extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div class="table-wrapper" id="tableDirecciones">
                <table class="table">
                    <thead class="table-dark">
                        <tr>
                            <th>Fecha</th>
                            <th>Observación</th>
                            <th>Tipo de Observación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {JSON.parse(this.props.param).map(item => (

                            <tr onDoubleClick={() => this.props.modifyObservaciones(item)}>
                                <th>{item.date_create}</th>
                                <th>{item.observation}</th>
                                <th>{item.type_observation}</th>
                            </tr>

                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ModalObservaciones;
