import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';
import ModalFestivo from '../formPersonalComponents/modalesForm/ModalFestivo'
import TablaFestivo from '../formPersonalComponents/tablasForm/TableFestivos'
import '../../css/FestivosAdmin.css'

class FestivosAdmin extends Component {

    constructor(props) {
        super(props);


        this.createFestivo = this.createFestivo.bind(this)
        this.getAllFestivos = this.getAllFestivos.bind(this)
        this.modifyFestivo = this.modifyFestivo.bind(this)
        this.valideAndCreateNewFestivo = this.valideAndCreateNewFestivo.bind(this)
        this.deleteFestivo = this.deleteFestivo.bind(this)
        this.returnYear = this.returnYear.bind(this)


        this.inputYear = React.createRef();
        this.fechaBefore = ""
        this.fechaAfter = ""
    }

    componentDidMount() {
        document.getElementById("cargarHistorial").style.backgroundColor = "inherit"
        document.getElementById("cargarFestivos").style.backgroundColor = "#2F6D6C"
        document.getElementById("cargarFestivos").style.borderRadius = "22px"
        document.getElementById("cargarFestivos").style.paddingLeft = "1%"
        document.getElementById("cargarFestivos").style.paddingRight = "1%"
        document.getElementById("cargarDepartamentos").style.backgroundColor = "inherit"
        document.getElementById("cargarPersonal").style.backgroundColor = "inherit"
        document.getElementById("cargarIncidencias").style.backgroundColor = "inherit"

        ReactDOM.render(
            <ModalFestivo valideAndCreateNewFestivo={this.valideAndCreateNewFestivo} deleteFestivo={this.deleteFestivo} />,
            document.getElementById("modalFestivos")
        )
        this.getAllFestivos()
    }

    componentWillUnmount() {

    }

    deleteFestivo() {

        this.subsciptionGU_MF = this.props.stompClient.subscribe('/user/queue/deleteFestivoSubscribe', (msg) => {
            this.subsciptionGU_MF.unsubscribe("subsciptionGU_MF")
            if (msg.body != null || msg.body != undefined) {
                this.getAllFestivos()
            }

        }, { id: "subsciptionGU_MF" });
        this.props.stompClient.send("/app/deleteFestivo", {}, JSON.stringify({
            "id": document.getElementById("id_festivo").value,
        }));
    }

    modifyFestivo(item) {
        document.getElementById("botonCrearFestivo").disabled = false
        document.getElementById("msgErrorServidorFestivo").style.display = "none"
        document.getElementById("msgErrorFestivoNoValido").style.display = "none"

        $('#modalFormFestivo').modal('show')
        document.getElementById("id_festivo").value = item.id
        document.getElementById("inputDateGo").value = item.dateFirstDay
        document.getElementById("inputTipoFestivo").value = item.reason
        document.getElementById("DescripcionFestivo").value = item.description

        document.getElementById("botonBorrarFestivo").style.display = "block"

    }

    valideAndCreateNewFestivo() {

        document.getElementById("botonCrearFestivo").disabled = true

        if (document.getElementById("id_festivo").value != "") {

            if (document.getElementById("inputDateGo").value == "" || document.getElementById("DescripcionFestivo").value == "") {
                document.getElementById("msgErrorFestivoNoValido").style.display = "block"
                document.getElementById("msgErrorServidorFestivo").style.display = "none"
                document.getElementById("botonCrearFestivo").disabled = false
            } else {
                document.getElementById("msgErrorFestivoNoValido").style.display = "none"
                this.subsciptionGU_MF = this.props.stompClient.subscribe('/user/queue/modifyFestivoSubscribe', (msg) => {
                    this.subsciptionGU_MF.unsubscribe("subsciptionGU_MF")
                    if (msg.body != null || msg.body != undefined) {
                        if (JSON.parse(msg.body).statusCodeValue == 500) {
                            document.getElementById("botonCrearFestivo").disabled = false
                            document.getElementById("msgErrorFestivoNoValido").style.display = "none"
                            document.getElementById("msgErrorServidorFestivo").style.display = "block"
                        }
                        else {
                            this.getAllFestivos()
                            $('#modalFormFestivo').modal('toggle');
                        }
                    }

                }, { id: "subsciptionGU_MF" });
                this.props.stompClient.send("/app/modifyFestivo", {}, JSON.stringify({
                    "id": document.getElementById("id_festivo").value,
                    "dateFirstDay": document.getElementById("inputDateGo").value,
                    "reason": document.getElementById("inputTipoFestivo").value,
                    "description": document.getElementById("DescripcionFestivo").value
                }));
            }

        } else {
            if (document.getElementById("inputDateGo").value == "" || document.getElementById("DescripcionFestivo").value == "") {
                document.getElementById("msgErrorFestivoNoValido").style.display = "block"
                document.getElementById("botonCrearFestivo").disabled = false
            } else {
                document.getElementById("msgErrorFestivoNoValido").style.display = "none"

                this.subsciptionGU_CNF = this.props.stompClient.subscribe('/user/queue/saveNewFestivoSubscribe', (msg) => {
                    this.subsciptionGU_CNF.unsubscribe("subsciptionGU_CNF")

                    if (msg.body != null || msg.body != undefined) {
                        if (JSON.parse(msg.body).statusCodeValue == 500) {
                            document.getElementById("botonCrearFestivo").disabled = false
                            document.getElementById("msgErrorFestivoNoValido").style.display = "none"
                            document.getElementById("msgErrorServidorFestivo").style.display = "block"
                        }
                        else {
                            this.getAllFestivos()
                            $('#modalFormFestivo').modal('toggle');
                        }
                    }

                }, { id: "subsciptionGU_CNF" });
                this.props.stompClient.send("/app/saveNewFestivo", {}, JSON.stringify({
                    "dateFirstDay": document.getElementById("inputDateGo").value,
                    "reason": document.getElementById("inputTipoFestivo").value,
                    "description": document.getElementById("DescripcionFestivo").value
                }));
            }
        }
    }

    getAllFestivos() {

        if (parseInt(this.inputYear.current.value) >= 2000 && parseInt(this.inputYear.current.value) <= 3000) {

            this.fechaBefore = (parseInt(this.inputYear.current.value)) + "-01-01";
            this.fechaAfter = (parseInt(this.inputYear.current.value)) + "-12-31";

            this.subsciptionGU_GAF = this.props.stompClient.subscribe('/user/queue/getAllFestivosSubscribe', (msg) => {
                this.subsciptionGU_GAF.unsubscribe("subsciptionGU_GAF")
                if (msg.body != null || msg.body != undefined) {
                    this.listaFiestas = JSON.parse(msg.body)
                    this.componentWillUnmount(document.getElementById('tableFestivos'))
                    ReactDOM.render(
                        <TablaFestivo param={this.listaFiestas} modifyFestivo={this.modifyFestivo} />,
                        document.getElementById('tableFestivos')
                    );
                }

            }, { id: "subsciptionGU_GAF" });
            this.props.stompClient.send("/app/getAllFestivos", {}, JSON.stringify({
                "dateFirstDay": this.fechaBefore,
                "dateLastDay": this.fechaAfter,
                "reason": document.getElementById("formTipoDeFiesta").value
            }));

        } else {
            document.getElementById("añoABuscarFestivo").value = ""
            this.subsciptionGU_GAF = this.props.stompClient.subscribe('/user/queue/getAllFestivosSubscribe', (msg) => {
                this.subsciptionGU_GAF.unsubscribe("subsciptionGU_GAF")

                if (msg.body != null || msg.body != undefined) {
                    this.listaFiestas = JSON.parse(msg.body)
                    this.componentWillUnmount(document.getElementById('tableFestivos'))
                    ReactDOM.render(
                        <TablaFestivo param={this.listaFiestas} modifyFestivo={this.modifyFestivo} />,
                        document.getElementById('tableFestivos')
                    );
                }

            }, { id: "subsciptionGU_GAF" });
            this.props.stompClient.send("/app/getAllFestivos", {}, JSON.stringify({
                "reason": document.getElementById("formTipoDeFiesta").value
            }));

        }

    }


    createFestivo() {
        document.getElementById("botonCrearFestivo").disabled = false

        document.getElementById("msgErrorServidorFestivo").style.display = "none"
        document.getElementById("msgErrorFestivoNoValido").style.display = "none"

        $('#modalFormFestivo').modal('show')
        document.getElementById("id_festivo").value = ""

        document.getElementById("botonBorrarFestivo").style.display = "none"

        document.getElementById("inputDateGo").value = ""
        document.getElementById("inputTipoFestivo").value = "Fiesta Local"
        document.getElementById("DescripcionFestivo").value = ""

    }

    returnYear() {
        let date = new Date()
        return date.getFullYear()
    }


    render() {
        return (
            <div>
                <h1 id="divFestivos">Festivos</h1>
                <div class="form-group row" id="searchFestivoAdmin">
                    <div className="col-sm-3 form-group row">
                        <button class="btn-success btn-Form" onClick={this.createFestivo}>Alta</button>
                    </div>
                    <div className="col-sm-2 form-group row">
                        <select className="custom-select mr-sm-2" id="formTipoDeFiesta" onChange={this.getAllFestivos}>
                            <option value="Todas las fiestas">Todas las fiestas</option>
                            <option value="Fiesta Local">Fiesta Local</option>
                            <option value="Fiesta Estatal">Fiesta Estatal</option>
                            <option value="Fiesta Nacional">Fiesta Nacional</option>
                        </select>
                    </div>
                    <div className="col-sm-3 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Año</label>
                        <input id="añoABuscarFestivo" type="Number" defaultValue={this.returnYear()} class="form-control col-sm-6" ref={this.inputYear} placeholder="2021" minLength="4" maxLength="4" min="2000" />
                    </div>
                    <div className="col-sm-1 form-group row">
                        <button type="button" className="btn btn-primary" onClick={this.getAllFestivos}>Buscar</button>
                    </div>


                </div>
                <br></br>
                <br></br>
                <div id="tableFestivos">

                </div>
                <div id="modalFestivos">

                </div>

            </div>
        );
    }
}

export default FestivosAdmin;