import React, { Component } from "react";
import $ from 'jquery';


class Record extends React.Component {
    constructor(props) {
        super(props)

        this.modRecord = this.modRecord.bind(this);
        this.getRecord = this.getRecord.bind(this)
    }

    componentDidMount() {
    }

    getRecord(){

        if(this.props.canMod == false){
            return (
                <tr>
                    <th>{this.props.user}</th>
                    <td>{this.props.date}</td>
                    <td>{this.props.entry}</td>
                    <td>{this.props.exit}</td>
                    <td>{this.props.time}</td>
                    <td>{this.props.reason}</td>
                    <td>{this.props.retraso}</td>
                </tr>) 
        }
        else{
            if(this.props.reason == null){
                console.log(this.props.reason)
            }
            if(this.props.reason == "Vacaciones" || this.props.reason == "Absencia programada" || this.props.reason == "Baja Laboral" || this.props.reason == "Jornada interrumpida" || this.props.reason == null){
                return (
                    <tr style={{ color: '#29BC12'}}>
                        <th>{this.props.user}</th>
                        <td>{this.props.date}</td>
                        <td>{this.props.entry}</td>
                        <td>{this.props.exit}</td>
                        <td>{this.props.time}</td>
                        <td>{this.props.reason}</td>
                        <td>{this.props.retraso}</td>
                    </tr>)
            }else{
                return (
                    <tr onDoubleClick={() => this.modRecord(this.props.item)}>
                        <th>{this.props.user}</th>
                        <td>{this.props.date}</td>
                        <td>{this.props.entry}</td>
                        <td>{this.props.exit}</td>
                        <td>{this.props.time}</td>
                        <td>{this.props.reason}</td>
                        <td>{this.props.retraso}</td>
                    </tr>)
            }    
        }

    }



    modRecord(item) {
        this.tiempoEntrada = item.timeEntry.split(":")
        if (item.timeExit != null && item.timeExit != undefined) {

            this.tiempoSalida = item.timeExit.split(":")
            document.getElementById('inputTimeExit').value = this.tiempoSalida[0] + ":" + this.tiempoSalida[1] + ":" + this.tiempoSalida[2]
        } else {
            document.getElementById('inputTimeExit').value = ""
        }
        document.getElementById('inputTimeEntry').value = this.tiempoEntrada[0] + ":" + this.tiempoEntrada[1] + ":" + this.tiempoEntrada[2]
        document.getElementById('inputReason').value = item.reason
        document.getElementById('idUserRecord').value = item.iduser
        document.getElementById('idRecord').value = item.id
        document.getElementById("inputDateRecord").value = item.daterecord
        document.getElementById("inputDateRecord").readOnly = true

        if (this.props.userModifier != undefined) {
            document.getElementById('inputAdminModifier').value = this.props.userModifier
        }

        document.getElementById('inputDateModified').value = item.date_modify

        document.getElementById('inputJustificacion').value = item.tipo_retraso

        if (item.retraso) {
            document.getElementById('divJustificacion').style.display = "block";
            document.getElementById('inputRetraso').checked = true;
        } else {
            document.getElementById('divJustificacion').style.display = "none";
            document.getElementById('inputRetraso').checked = false;
        }

        if (this.props.admin == undefined || !this.props.admin.admin) {

            document.getElementById('inputTimeEntry').readOnly = true;
            document.getElementById('inputTimeExit').readOnly = true;
            document.getElementById('inputReason').readOnly = true;
            document.getElementById('idUserRecord').readOnly = true;
            document.getElementById('idRecord').readOnly = true;
            document.getElementById('inputAdminModifier').readOnly = true;
            document.getElementById('inputDateModified').readOnly = true;
            document.getElementById('inputJustificacion').disabled = true;
            document.getElementById('inputRetraso').disabled = true;
            document.getElementById('btnModifierRecord').disabled = true;
        }
        $('#modalFormRecord').modal('show')
    }


    render() {

        return(
            this.getRecord()

        );
    }

}

export default Record;
