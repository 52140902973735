import React, { Component } from "react";
import ReactDOM from 'react-dom';
import TableHorasAcumuladasDay from './formPersonalComponents/tablasForm/TablaHorasAcumuladasDay';
import $ from 'jquery';
import '../css/User.css';
import '../css/HistorialRecords.css';
import AdminPage from "./AdminPage";
import HistorialRecords from './HistorialRecords';
import Incidencias from "./Incidencias";
import User from "./User"
import Login from "./Login";
import ModalChangePasswd from './formPersonalComponents/modalesForm/ModalChangePasswd';
import ModalDailyRecord from "./formPersonalComponents/modalesForm/ModalDailyRecord";
import ModalRecord from "./formPersonalComponents/modalesForm/ModalRecord";

class HistorialCumulativeHoursUser extends Component {

    constructor(props) {
        super(props);
        this.userInfo = this.props.userInfo
        this.search = this.search.bind(this);
        this.cargarFichaje = this.cargarFichaje.bind(this);
        this.cargarLogin = this.cargarLogin.bind(this);
        this.cargarUsuario = this.cargarUsuario.bind(this);
        this.cargarHistorial = this.cargarHistorial.bind(this)
        this.showDropdown = this.showDropdown.bind(this);
        this.hideDropdown = this.hideDropdown.bind(this);
        this.cargarIncidencias = this.cargarIncidencias.bind(this);
        this.returnTipoJornada = this.returnTipoJornada.bind(this);
        this.changePasswd = this.changePasswd.bind(this);
        this.passwordChanged = this.passwordChanged.bind(this);
        this.returnLocalDate = this.returnLocalDate.bind(this);
        this.returnName = this.returnName.bind(this);
        this.crearRecord = this.crearRecord.bind(this);
        this.modifiedRecord = this.modifiedRecord.bind(this);
        this.returnJSONModalRecord = this.returnJSONModalRecord.bind(this);

        this.localDateAft = new Date()
        this.localDateBef = new Date()

        this.hlocalDate = React.createRef();
        this.dateTargetBeforeRef = React.createRef();
        this.dateTargetAfterRef = React.createRef();

    }

    componentDidMount() {
        ReactDOM.unmountComponentAtNode(document.getElementById('modalContraseña'))
        ReactDOM.render(
            <ModalChangePasswd checkPasswd={this.props.checkPasswd} isAdmin={false} />,
            document.getElementById('modalContraseña')
        );
        $('#modalFormPasswd').modal('hide')

        ReactDOM.unmountComponentAtNode(document.getElementById('modalRecords'))
        ReactDOM.render(
            <ModalDailyRecord stompClient={this.props.stompClient}/>,
            document.getElementById("modalRecords")
        )

        this.componentWillUnmount(document.getElementById('modalRecord'))
        ReactDOM.render(
            <ModalRecord adminInfo={this.props.adminInfo} stompClient={this.props.stompClient} selectUser={this.selectUser} modifiedRecord={this.modifiedRecord} deleteRecord={this.deleteRecord} doJson={this.returnJSONModalRecord} />,
            document.getElementById('modalRecord')
        );

        this.subsciptionLD_HA = this.props.stompClient.subscribe('/user/queue/localdateSubscribe', this.returnLocalDate, { id: "subsciptionLD_HA" });
        this.props.stompClient.send("/app/localdate", {});

        this.localDateBef = new Date(this.localDateBef.getFullYear() + "-" + this.localDateBef.getMonth() + "-1")
        this.localDateBef.setMonth(this.localDateBef.getMonth() + 2)
        this.localDateAft.setMonth(this.localDateAft.getMonth() + 1)

        this.dateTargetAfterRef.current.value = this.localDateAft.getFullYear() + "-" + this.localDateAft.getMonth() + "-" + this.localDateAft.getDay()
        this.dateTargetBeforeRef.current.value = this.localDateBef.getFullYear() + "-" + this.localDateBef.getMonth() + "-" + this.localDateBef.getDay()

        this.dateTargetAfterRef.current.value = this.localDateAft.getFullYear() + '-'
            + ('0' + (this.localDateAft.getMonth())).slice(-2) + '-'
            + ('0' + (this.localDateAft.getDate())).slice(-2);

        this.dateTargetBeforeRef.current.value = this.localDateBef.getFullYear() + '-'
            + ('0' + (this.localDateBef.getMonth())).slice(-2) + '-'
            + ('0' + (this.localDateBef.getDate())).slice(-2);

        if (this.userInfo.admin) {
            ReactDOM.render(
                <div class="container">
                    <button onClick={this.cargarUsuario} type="button" class="btn font-weight-bold" style={{ backgroundColor: "green", marginLeft: "20px" }}>Vista admin</button>
                    <button class="navbar-brand btn btn-danger font-weight-bold" onClick={this.cargarLogin} type="button">SALIR</button>
                    <a class="navbar-brand" onClick={this.cargarFichaje} >Fichaje</a>
                    <div class="btn-group" id="divHistorial">
                        <a class="navbar-brand" id="cargarHistorial" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown} style={{ backgroundColor: '#2F6D6C', paddingLeft: '1%', paddingRight: '1%', borderRadius: '22px' }}>Historial ☰</a>
                        <div class="dropdown-menu dropdown-menu-right" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown}>
                            <a class="dropdown-item" onClickCapture={this.cargarHistorial}>Historial</a>
                            <a class="dropdown-item" href="#">Historial h/Mensuales</a>
                        </div>
                    </div>
                    <a class="navbar-brand" onClick={this.cargarIncidencias}>Incidencias</a>
                    <a class="navbar-brand" data-toggle="modal" data-target="#modalFormChangePasswd">Cambiar Contraseña</a>
                </div>, document.getElementById("menuUser")
            );
        } else {
            ReactDOM.render(
                <div class="container">
                    <button class="navbar-brand btn btn-danger font-weight-bold" onClick={this.cargarLogin} type="button">SALIR</button>
                    <a class="navbar-brand" onClick={this.cargarFichaje}>Fichaje</a>
                    <div class="btn-group" id="divHistorial">
                        <a class="navbar-brand" id="cargarHistorial" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown} style={{ backgroundColor: '#2F6D6C', paddingLeft: '1%', paddingRight: '1%', borderRadius: '22px' }}>Historial ☰</a>
                        <div class="dropdown-menu dropdown-menu-right" onMouseOver={this.showDropdown} onMouseLeave={this.hideDropdown}>
                            <a class="dropdown-item" onClickCapture={this.cargarHistorial}>Historial</a>
                            <a class="dropdown-item" href="#">Historial h/Mensuales</a>
                        </div>
                    </div>
                    <a class="navbar-brand" onClick={this.cargarIncidencias}>Incidencias</a>
                    <a class="navbar-brand" data-toggle="modal" data-target="#modalFormChangePasswd">Cambiar Contraseña</a>
                </div>
                , document.getElementById("menuUser")
            );
        }

        if(this.userInfo.puedeCrearFichajes){
            ReactDOM.unmountComponentAtNode(document.getElementById('crearRegistro'))
            ReactDOM.render(
                <button type="button" className="btn btn-primary" onClick={this.crearRecord}>Crear</button>
                , document.getElementById("crearRegistro")
            );
        }
    }

    componentWillUnmount() {

    }

    crearRecord() {

        document.getElementById("inputDateRecord").value = ""
        document.getElementById("idRecord").value = ""
        document.getElementById("msgErrorRecordServidor").style.display = "none"
        document.getElementById('inputTimeExit').value = ""
        document.getElementById('inputTimeEntry').value = ""
        document.getElementById('inputReason').value = ""
        document.getElementById('inputAdminModifier').value = ""
        document.getElementById('inputDateModified').value = ""
        document.getElementById('inputJustificacion').value = ""
        document.getElementById('btn-deleteMVL').style.display = "none"
        document.getElementById('divJustificacion').style.display = "none";
        document.getElementById('inputRetraso').checked = false;
        document.getElementById('idUserRecord').value = this.userInfo.id    

        $('#modalFormRecord').modal('show')

    }

    returnJSONModalRecord(callbackModalRecord) {
        this.jsonModalRecord = callbackModalRecord
    }

    modifiedRecord(){
        this.Json = this.jsonModalRecord()

        if (this.Json.error == undefined) {
            this.Json.id_user_modifier = this.JsonUsers.id
            this.Json.date_modify = this.localDate;
            document.getElementById("msgErrorRecordNoValido").style.display = "none"

            if (document.getElementById("idRecord").value == undefined || document.getElementById("idRecord").value == null || document.getElementById("idRecord").value == "") {
                this.subsciptionMR_HA = this.props.stompClient.subscribe('/user/queue/createNewRecordAdminSubscribe', (msg) => {

                    if (JSON.parse(msg.body).statusCodeValue == 500) {
                        document.getElementById("msgErrorRecordServidor").style.display = "block"
                    } else {
                        document.getElementById("msgErrorRecordServidor").style.display = "none"
                        $('#modalFormRecord').modal('hide')
                    }

                }, { id: "subsciptionMR_HA" });
                this.props.stompClient.send("/app/createNewRecordAdmin", {}, JSON.stringify(this.Json));

            } else {
                this.subsciptionMR_HA = this.props.stompClient.subscribe('/user/queue/modifyRecordsSubscribe', this.returnRecordModified, { id: "subsciptionMR_HA" });
                this.props.stompClient.send("/app/modifyRecords", {}, JSON.stringify(this.Json));
            }

        } else {
            document.getElementById("msgErrorRecordNoValido").style.display = "block"
        }

    }

    async search() {
        if (this.dateTargetAfterRef.current.value === "" || this.dateTargetBeforeRef.current.value === "") {

        } else {
            ReactDOM.unmountComponentAtNode(document.getElementById('tableDailyWorked'))
            const limit = 100
            var offset = 0
            var continuar = true
            var arrayRecords = []
            var arrayTemporal = []
            do {
                await new Promise((resolve) => {
                    this.props.stompClient.send("/app/returnTableDailyHours", {}, JSON.stringify({
                        "dateBefore": this.dateTargetBeforeRef.current.value,
                        "dateAfter": this.dateTargetAfterRef.current.value,
                        "iduser": this.userInfo.id,
                        "valorInicial": offset,
                        "limit": limit,
                    }));
                    this.subsciptionTMH_GCHD = this.props.stompClient.subscribe('/user/queue/returnTableDailyHoursSubscribe', (msg) => {
                        arrayTemporal = []
                        arrayTemporal = JSON.parse(msg.body)

                        if (!arrayTemporal.length == 0) {
                            offset += arrayTemporal.length
                            if (continuar) {
                                arrayRecords = arrayRecords.concat(arrayTemporal)
                            }

                            if (arrayTemporal.length < limit) {
                                continuar = false
                                arrayTemporal = []
                            }
                        } else {
                            continuar = false
                            arrayTemporal = []
                        }

                        setTimeout(() => {
                            resolve()
                        }, this.subsciptionTMH_GCHD.unsubscribe())

                    }, { id: "subsciptionTMH_GCHD" });
                });
            } while (continuar)
            ReactDOM.unmountComponentAtNode(document.getElementById('tableDailyWorked'))
            ReactDOM.render(
                <TableHorasAcumuladasDay users={this.JsonUsers[0]} param={arrayRecords} name={this.userInfo.nombre_usuario} stompClient={this.props.stompClient} />,
                document.getElementById('tableDailyWorked')
            );
        }
    }

    hideDropdown() {
        $('.dropdown-menu').dropdown().hide()
    }

    showDropdown() {
        $('.dropdown-menu').dropdown().show()
    }

    cargarFichaje() {
        ReactDOM.render(
            <User userInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('root')
        );
    }

    cargarLogin() {
        ReactDOM.render(
            <Login showError={false} />, document.getElementById('root')
        );
    }

    cargarUsuario() {
        ReactDOM.render(
            <AdminPage userInfo={this.props.userInfo} stompClient={this.props.stompClient} />, document.getElementById('root')
        );
    }

    cargarHistorial() {
        ReactDOM.render(
            <HistorialRecords userInfo={this.userInfo} checkPasswd={this.checkPasswd} stompClient={this.props.stompClient} />,
            document.getElementById('root')
        );
    }


    returnYear() {
        let date = new Date()
        return date.getFullYear()
    }

    returnMonth() {
        let date = new Date()
        return date.getMonth() + 1
    }

    cargarIncidencias() {
        ReactDOM.render(
            <Incidencias userInfo={this.userInfo} checkPasswd={this.checkPasswd} stompClient={this.props.stompClient} />,
            document.getElementById('root')
        );
    }

    returnTipoJornada() {

        this.subsciptionRCR = this.props.stompClient.subscribe('/user/queue/getJornadaByUserSubscribe', (msg) => {

            if (document.getElementById("jornada") != null) {
                document.getElementById("jornada").innerHTML = "Jornada: " + JSON.parse(msg.body).tipo
            }

        });
        this.props.stompClient.send("/app/getJornadaByUser", {}, JSON.stringify({
            "jornada": this.userInfo.jornada,
        }));
    }

    changePasswd() {

        this.subsciptionVU_U.unsubscribe("subsciptionVU_U");
        this.subsciptionCP_U = this.props.stompClient.subscribe('/user/queue/changePasswdSubscribe', this.passwordChanged, { id: "subsciptionCP_U" });
        this.props.stompClient.send("/app/changePasswd", {}, JSON.stringify({
            "id": this.userInfo.id,
            "passwd": this.newPasswd,
        }));
    }

    passwordChanged() {
        $('#modalFormChangePasswd').modal('hide')
        this.subsciptionCP_U.unsubscribe("subsciptionCP_U");
    }

    returnLocalDate(msg) {
        this.localDate = msg.body
        this.localDate = this.localDate.replaceAll('"', "")
        this.splitDate = this.localDate.split("-")
        this.anyo = this.splitDate[0]
        this.mes = this.splitDate[1]
        this.dia = this.splitDate[2]

        this.hlocalDate.current.textContent = this.localDate

        this.dateTargetBeforeRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;
        this.dateTargetAfterRef.current.value = this.anyo + "-" + this.mes + "-" + this.dia;

        this.subsciptionLD_HA.unsubscribe("subsciptionLS_HR");

        this.subsciptionN_CR = this.props.stompClient.subscribe('/user/queue/getAllUsersNameAndIdSubscribe', this.returnName, { id: "subsciptionN_CR" });
        this.props.stompClient.send("/app/getAllUsersNameAndId", {});


    }

    returnName(msg) {
        this.JsonUsers = JSON.parse(msg.body)
        this.subsciptionN_CR.unsubscribe("subsciptionN_CR");
    }


    render() {
        return (
            <div id="generalTablHistorialRecords">
                <div id="divHeaderHistorialRecords">
                    <nav class="navbar navbar-dark bg-dark" id="menuUser">
                    </nav>
                    <div class="container">
                        <div class="form-group row">
                            <div class="col-md-4 col-form-label">
                                <h2 id="jornada" onLoad={this.returnTipoJornada()}>Jornada: </h2>
                            </div>
                            <div class="col-md-4 col-form-label">
                                <h2 id="empleado">Empleado: {this.userInfo.nombre}</h2>
                            </div>
                            <div class="col-md-4 col-form-label" id="fecha">
                                <h2 id="fecha" ref={this.hlocalDate} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" id="searchHistorialAdmin">
                    <div className="col-sm-3 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Inicio</label>
                        <input type="date" class="form-control dateSearchHistorial col-sm-6" id="dateBef" ref={this.dateTargetBeforeRef} />
                    </div>
                    <div className="col-sm-3 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Fin</label>
                        <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetAfterRef} />
                    </div>
                    <div className="col-sm-1 form-group row">
                        <button type="button" className="btn btn-primary" onClick={this.search}>Buscar</button>
                    </div>
                    <div id="crearRegistro" className="col-sm-1 form-group row">
                    </div>
                </div>
                <br></br>
                <br></br>
                <div id="tableDailyWorked">

                </div>

                <div id="totales">

                </div>
                <div id="modalContraseña">

                </div>

                <div id="modalRecords">

                </div>
                <div id="modalRecord">

                </div>

            </div>
        );
    }
}

export default HistorialCumulativeHoursUser;
