import React, { Component } from "react";
import $ from 'jquery';
import ReactDOM from 'react-dom';
import CurrentRecords from './CurrentRecords';


class DailyRecord extends React.Component {
    constructor(props) {
        super(props)
        this.calculateHours = this.calculateHours.bind(this);
        this.getRecordsByDay = this.getRecordsByDay.bind(this)
    }

    componentDidMount() {
    }

    getRecordsByDay(item){

        this.subsciptionRDS_GRBD = this.props.stompClient.subscribe('/user/queue/getRecordsByDayAndUserSubscribe', (msg) =>{
            this.subsciptionRDS_GRBD.unsubscribe()

            this.lista=msg.body
            ReactDOM.unmountComponentAtNode(document.getElementById('TableRecordsSon'))
            ReactDOM.render(
                <CurrentRecords param={JSON.parse(this.lista)} canMod={false} users={this.props.users} stompClient={this.props.stompClient} modRecord={this.modRecord} />,
                document.getElementById('TableRecordsSon')
            );    
        }, { id: "subsciptionRDS_GRBD" });
        this.props.stompClient.send("/app/getRecordsByDayAndUser", {}, JSON.stringify({
            "iduser": item.iduser,
            "daterecord": item.daterecord
          }));
        $('#modalFormRecordsDaily').modal('show')
    }

    calculateHours(minutes) {
        let negativo = false
        if (minutes < 0) {
            minutes = minutes * -1
            negativo = true
        }
        
        this.horasDiff = Math.floor(minutes / 60);
        this.minutosDiff = Math.floor(minutes % 60);
        if (this.minutosDiff <= 9) {

            if (negativo) {
                return <th>-{this.horasDiff}h 0{this.minutosDiff}m</th>
            }

            return <th>{this.horasDiff}h 0{this.minutosDiff}m</th>
        }
        if (negativo) {
            return <th>-{this.horasDiff}h {this.minutosDiff}m</th>
        }

        return <th>{this.horasDiff}h {this.minutosDiff}m</th>
    }

    render() {

        return (
            <tr onDoubleClick={() => this.getRecordsByDay(this.props.item)}>
                <th>{this.props.user}</th>
                <th>{this.props.fecha}</th>
                {this.props.hacu}
                {this.props.htrab}
                {this.props.hext}
            </tr>

        );
    }

}

export default DailyRecord;
