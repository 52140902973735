import React, { Component } from "react";
import ReactDOM from 'react-dom';
import Index from '../index';
import $ from 'jquery';

function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

function getCookie(key)  { 
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const data = cookies[i].split("=");
      if (data[0] === key) {
        return data[1];
      }
    }
    return "";
  }
  

class Login extends Component {

    constructor(props) {
        
        super(props);
        console.log(document.cookie)
        if(getCookie("pcid") == "" || getCookie("pcid") == undefined){
            console.log("Nuevo UUID")
            document.cookie = "pcid=" + uuidv4() + "; expires=Thu, 18 Dec 2030 12:00:00 UTC";
            console.log("Se ha generado un nuevo UUID")
            console.log(document.cookie)
        }else{
            document.cookie = "pcid=" + getCookie("pcid") + "; expires=Thu, 18 Dec 2040 12:00:00 UTC";
        }

        this.state = {
            userInfo: {
                name: '',
                passwd: '',
            },
            errorMsg: 'WRONG PASSWD OR NAME',
            showError: this.props.showError,
        }
    }


    // On change input value (name & password)
    onChangeValue = (e) => {
        if (e.nativeEvent.detail == undefined || e.nativeEvent.inputType == "insertFromPaste") {

            document.getElementById("userLogin").value = ""
            document.getElementById("passwordLogin").value = ""
        } else {
            this.setState({
                ...this.state,
                userInfo: {
                    ...this.state.userInfo,
                    [e.target.name]: e.target.value,
                }
            });
        }
    }

    // On Submit Login From
    submitForm = async (event) => {
        event.preventDefault();
        console.log(this.ipjson)
        //if(this.ipjson == undefined || this.ipjson == null || this.ipjson.status == 404){
        ReactDOM.render(
            <Index userInfo={this.state.userInfo} />, document.getElementById('root')
        );

        //}else{
        ReactDOM.render(
            <Index userInfo={this.state.userInfo} /*ip={this.ipjson.responseJSON.ip}*/ />, document.getElementById('root')
        );
        //}
    }

    render() {
        return (
            <div>
                <div class="login-form _loginRegister">
                    <form onSubmit={this.submitForm} >
                        <h1 class="text-center">Iniciar Sesión</h1>
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" placeholder="Ej: Nombre.Usuario" required="required" id="userLogin" onChange={this.onChangeValue} />
                        </div>
                    </form>
                    <form onSubmit={this.submitForm}>
                        <div class="form-group">
                            <input type="password" name="passwd" class="form-control" placeholder="Introduce tu contraseña" required="required" id="passwordLogin" onChange={this.onChangeValue} />
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary btn-block">Iniciar sesión</button>
                        </div>
                        <p id="msgNoAutocompletar" style={{ color: 'red', }}>*Se ha deshabilitado el autocompletado de contraseñas</p>
                    </form>
                </div>
                <div id="ModalErrorLogin">

                </div>
            </div>
        )
    }

}

export default Login;