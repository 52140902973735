import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalConfirmacionFichaje extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div class="modal fade" id="modalFormConfirmacionFichaje" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Confirmación de Fichaje</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Nombre</label>
                                <div class="col-8">
                                    <input type="text" class="form-control" id="nombre_id" readOnly />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Fecha/Hora</label>
                                <div class="col-8">
                                    <input type="text" class="form-control" id="fecha_id" readOnly />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Motivo</label>
                                <div class="col-8">
                                    <input type="text" class="form-control" id="motivo_id" readOnly />
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <div>
                                <p id="msgErrorRecordServidor2" style={{ color: 'red', display: 'none' }}>* Error, se ha producido un error en el fichaje</p>
                            </div>
                            <div>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button  type="button" class="btn btn-primary" id="botonConfirmarFichar" onClick={this.props.confirmarFichaje}>Confirmar Fichaje</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalConfirmacionFichaje;
