import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';
import TablaHorasAcumuladasDays from '../formPersonalComponents/tablasForm/TablaHorasAcumuladasDays';
import '../../css/HistorialAdmin.css';

class HistorialCumulativeHours extends Component {

    constructor(props) {
        super(props);

        this.search = this.search.bind(this);
        this.returnName = this.returnName.bind(this);

        this.printCumulativeHours = this.printCumulativeHours.bind(this);
        this.showDivCumulativeHours = this.showDivCumulativeHours.bind(this);
        this.cerrarImprimir = this.cerrarImprimir.bind(this);
        this.localDateAft = new Date()
        this.localDateBef = new Date()

        this.dateTargetBeforeRef = React.createRef();
        this.dateTargetAfterRef = React.createRef();


        this.imprimir = false

    }

    componentDidMount() {

        document.getElementById("cargarDepartamentos").style.backgroundColor = "inherit"
        document.getElementById("cargarHistorial").style.backgroundColor = "#2F6D6C"
        document.getElementById("cargarHistorial").style.borderRadius = "22px"
        document.getElementById("cargarHistorial").style.paddingLeft = "10%"
        document.getElementById("cargarHistorial").style.paddingRight = "10%"
        document.getElementById("cargarFestivos").style.backgroundColor = "inherit"
        document.getElementById("cargarPersonal").style.backgroundColor = "inherit"
        document.getElementById("cargarIncidencias").style.backgroundColor = "inherit"


        this.subsciptionN_CR = this.props.stompClient.subscribe('/user/queue/getAllUsersNameAndIdSubscribe', this.returnName, { id: "subsciptionN_CR" });
        this.props.stompClient.send("/app/getAllUsersNameAndId", {});

        this.localDateBef = new Date(this.localDateBef.getFullYear() + "-" + this.localDateBef.getMonth() + "-1")
        this.localDateBef.setMonth(this.localDateBef.getMonth() + 2)
        this.localDateAft.setMonth(this.localDateAft.getMonth() + 1)

        this.dateTargetAfterRef.current.value = this.localDateAft.getFullYear() + "-" + this.localDateAft.getMonth() + "-" + this.localDateAft.getDay()
        this.dateTargetBeforeRef.current.value = this.localDateBef.getFullYear() + "-" + this.localDateBef.getMonth() + "-" + this.localDateBef.getDay()

        this.dateTargetAfterRef.current.value = this.localDateAft.getFullYear() + '-'
             + ('0' + (this.localDateAft.getMonth())).slice(-2) + '-'
             + ('0' + (this.localDateAft.getDate())).slice(-2);

        this.dateTargetBeforeRef.current.value = this.localDateBef.getFullYear() + '-'
             + ('0' + (this.localDateBef.getMonth())).slice(-2) + '-'
             + ('0' + (this.localDateBef.getDate())).slice(-2);

    }
    componentWillUnmount() {
    }

    showDivCumulativeHours() {
        if (this.imprimir == false) {
            document.getElementById("divImprimirTabla").style.visibility = "visible"
            var divContents = document.getElementById("tableRecords").innerHTML;
            document.getElementById("tablaRecordsMensualesImprimir").innerHTML = divContents
            document.getElementById("botonImprimirDiv").onclick = this.printCumulativeHours
            document.getElementById("botonCerrarDiv").onclick = this.cerrarImprimir
        }

        this.imprimir = true
    }

    printCumulativeHours() {

        var divContents = document.getElementById("tableHorasAcumuladas").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<head>');
        a.document.write('<style>');
        a.document.write('table,th{border:1px solid black; margin-top: 10%;}');
        a.document.write('thead{color:white; background-color:black}');
        a.document.write('</style>');
        a.document.write('</head>');
        a.document.write("<body>");
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    cerrarImprimir() {
        document.getElementById("divImprimirTabla").style.visibility = "hidden"
        this.imprimir = false
    }


    returnName(msg) {
        this.JsonUsers = JSON.parse(msg.body)
        this.subsciptionN_CR.unsubscribe("subsciptionN_CR");

        this.search()
    }

    search() {
        console.log(this.dateTargetBeforeRef.current.value)
        console.log(this.dateTargetAfterRef.current.value)
        this.subsciptionTMH_HCPDL = this.props.stompClient.subscribe('/user/queue/returnTableMonthlyHoursByDaysSubscribe', (msg) => {
            this.subsciptionTMH_HCPDL.unsubscribe("subsciptionTMH_HCPDL");
            console.log(msg.body)
            ReactDOM.unmountComponentAtNode(document.getElementById('tableRecords'))
            ReactDOM.render(
                <TablaHorasAcumuladasDays param={msg.body} users={this.JsonUsers[0]} stompClient={this.props.stompClient} />,
                document.getElementById('tableRecords')
            );
        }, { id: "subsciptionTMH_HCPDL" });
        this.props.stompClient.send("/app/returnTableMonthlyHoursByDays", {}, JSON.stringify({
            "dateBefore": this.dateTargetBeforeRef.current.value,
            "dateAfter": this.dateTargetAfterRef.current.value,
        }));
    }

    returnYear() {
        let date = new Date()
        return date.getFullYear()
    }

    returnMonth() {
        let date = new Date()
        return date.getMonth() + 1
    }


    render() {
        return (
            <div>
                <div id="divImprimirTabla">
                    <div className="form-group row">
                        <div className="col-sm-3 form-group row">
                            <button type="button" className="btn btn-primary botonImprimir" id="botonImprimirDiv">Imprimir</button>

                        </div>
                        <div className="col-sm-1 form-group row">
                            <button type="button" className="btn btn-primary botonImprimir" id="botonCerrarDiv" >Cerrar</button>
                        </div>
                        <div id="tablaRecordsMensualesImprimir">
                        </div>
                    </div>
                </div>
                <h1 id="divPersonal">Historial</h1>
                <div class="form-group row" id="searchHistorialAdmin">
                    <div className="col-sm-3 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Inicio</label>
                        <input type="date" class="form-control dateSearchHistorial col-sm-6" id="dateBef" ref={this.dateTargetBeforeRef} />
                    </div>
                    <div className="col-sm-3 form-group row">
                        <label for="colFormLabelSm" class="col-sm-4 col-form-label ">Fecha Fin</label>
                        <input type="date" class="form-control dateSearchHistorial col-sm-6" ref={this.dateTargetAfterRef} />
                    </div>

                    <div className="col-sm-1 form-group row">
                        <button type="button" className="btn btn-primary" onClick={this.showDivCumulativeHours}>Imprimir</button>
                    </div>

                    <div className="col-sm-1 form-group row">
                        <button type="button" className="btn btn-primary" onClick={this.search}>Buscar</button>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div id="tableRecords">

                </div>
                <div id="modalSearch">

                </div>

            </div>
        );
    }
}

export default HistorialCumulativeHours;
