import React, { Component } from "react";
import ReactDOM from 'react-dom';
import TablaIncidencias from "./formPersonalComponents/tablasForm/TablaIncidencias";
import IncidenciasFormAdmin from "./IncidenciasFormAdmin";
import '../css/formPersonal/Incidencias.css';



class IncidenciasAdmin extends Component {

    constructor(props) {
        super(props);
        this.getIncidencias = this.getIncidencias.bind(this)
        this.modifyIncidencia = this.modifyIncidencia.bind(this)
        this.returnName = this.returnName.bind(this);

    }


    componentDidMount() {

        document.getElementById("cargarHistorial").style.backgroundColor = "inherit"
        document.getElementById("cargarIncidencias").style.backgroundColor = "#2F6D6C"
        document.getElementById("cargarIncidencias").style.borderRadius = "22px"
        document.getElementById("cargarIncidencias").style.paddingLeft = "1%"
        document.getElementById("cargarIncidencias").style.paddingRight = "1%"
        document.getElementById("cargarFestivos").style.backgroundColor = "inherit"
        document.getElementById("cargarPersonal").style.backgroundColor = "inherit"
        document.getElementById("cargarDepartamentos").style.backgroundColor = "inherit"

        this.subsciptionN_CR = this.props.stompClient.subscribe('/user/queue/getAllUsersNameAndIdSubscribe', this.returnName, { id: "subsciptionN_CR" });
        this.props.stompClient.send("/app/getAllUsersNameAndId", {});


    }

    returnName(msg) {
        this.JsonUsers = JSON.parse(msg.body)
        this.subsciptionN_CR.unsubscribe("subsciptionN_CR");
        this.getIncidencias()

    }

    modifyIncidencia(e){
        this.idIncicence = e.target.parentElement.firstElementChild.textContent

        ReactDOM.unmountComponentAtNode(document.getElementById('incidenciasAdmin'))
        ReactDOM.render(
            <IncidenciasFormAdmin idIncicence = {this.idIncicence} adminInfo={this.props.adminInfo} stompClient={this.props.stompClient}/>,
            document.getElementById('incidenciasAdmin')
        );
    }

    getIncidencias(){
        this.solvedIncidence = document.getElementById("cbSolvedIncidence").checked
        this.subsciptionGU_GAD = this.props.stompClient.subscribe('/user/queue/getIncidenciasSubscribe', (msg) => {
            this.subsciptionGU_GAD.unsubscribe("subsciptionGU_GI")

            if (msg.body != null || msg.body != undefined) {
                this.listaIncidencias = JSON.parse(msg.body)
                ReactDOM.unmountComponentAtNode(document.getElementById('tableIncidencias'))
                ReactDOM.render(
                    <TablaIncidencias param={this.listaIncidencias} modifyIncidencia={this.modifyIncidencia} users={this.JsonUsers[0]} />,
                    document.getElementById('tableIncidencias')
                );
            }

        }, { id: "subsciptionGU_GAD" });
        this.props.stompClient.send("/app/getIncidencias", {}, JSON.stringify({
            "id": this.props.adminInfo.id,
            "checked": this.solvedIncidence,
        }));

    }

    render() {
        return (
            <div id="incidenciasAdmin">
                <h1 id="divIncidencias">Incidencias</h1>
                <div class="form-group row" id="searchIncidenciasAdmin">
                </div>
                <div class="form-group row">
                    <label for="colFormLabel" class="col-sm-1">Resueltos</label>
                    <div>
                        <input type="checkbox" id="cbSolvedIncidence" onClick={this.getIncidencias} />
                    </div>
                </div>
                <div id="tableIncidencias">

                </div>

            </div>
        );
    }
}

export default IncidenciasAdmin;
