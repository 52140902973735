import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalRecord extends Component {

    constructor(props) {
        super(props);
        this.makeJSON = this.makeJSON.bind(this);
        this.checkValue = this.checkValue.bind(this);


        this.inputTimeEntry = React.createRef();
        this.inputTimeExit = React.createRef();
        this.inputReason = React.createRef();
        this.idUserRecord = React.createRef();
        this.idRecord = React.createRef();
        this.divJustificacion = React.createRef();
        this.inputRetraso = React.createRef();
        this.inputJustificacion = React.createRef();
        this.inputAdminModifier = React.createRef();
        this.inputDateModified = React.createRef();
        this.inputDateRecord = React.createRef();
    }

    componentDidMount() {
        if (typeof this.props.doJson === 'function') {
            this.props.doJson(this.makeJSON)
        }



    }
    makeJSON() {
        if (this.inputTimeEntry.current.value != "") {
            if (this.divJustificacion.current.style == "none") {
                this.inputJustificacion.current.value = "";
            }

            return this.directionInfo = {
                "id": this.idRecord.current.value,
                "iduser": this.idUserRecord.current.value,
                "timeEntry": this.inputTimeEntry.current.value,
                "timeExit": this.inputTimeExit.current.value,
                "reason": this.inputReason.current.value,
                "retraso": this.inputRetraso.current.checked,
                "tipo_retraso": this.inputJustificacion.current.value,
                "daterecord": this.inputDateRecord.current.value

            }
        } else {
            return this.error = {
                "error": "Te has dejado algun campo vacio, para realizar la tarea deben estar completos todos los campos. Vuelve a intentarlo",
            }
        }
    }

    checkValue(e) {
        if (e.target.checked == true) {
            this.divJustificacion.current.style.display = "block";
        } else {
            this.divJustificacion.current.style.display = "none";

        }
    }

    render() {
        return (
            <div class="modal fade" id="modalFormRecord" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Formulario fichado</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <input id="idItemContact" ref={this.idItemContact} hidden />
                        <div class="modal-body">
                            <input value="" id="idUserRecord" ref={this.idUserRecord} hidden />
                            <input value="" id="idRecord" ref={this.idRecord} hidden />

                            <div class="form-group row">
                                <label class="col-4 col-form-label">Día</label>
                                <div class="col-8">
                                    <input class="form-control" type="date" id="inputDateRecord" ref={this.inputDateRecord} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Hora de Entrada</label>
                                <div class="col-8">
                                    <input class="form-control" type="time" id="inputTimeEntry" ref={this.inputTimeEntry} step="2" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Hora de Salida</label>
                                <div class="col-8">
                                    <input class="form-control" type="time" id="inputTimeExit" ref={this.inputTimeExit} step="2" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Motivo</label>
                                <div class="col-8">
                                    <input class="form-control" type="text" id="inputReason" ref={this.inputReason} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Retraso</label>
                                <div class="col-2">
                                    <input class="form-check-input" type="checkbox" id="inputRetraso" onClick={this.checkValue} ref={this.inputRetraso} />
                                </div>
                                <div class="col-6" ref={this.divJustificacion} id="divJustificacion">
                                    <select class="form-control" id="inputJustificacion" ref={this.inputJustificacion}>
                                        <option value="No justificado">No Justificado</option>
                                        <option value="Justificado">Justificado</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Última Modificación</label>
                                <div class="col-4">
                                    <input class="form-control" type="text" id="inputAdminModifier" ref={this.inputAdminModifier} readOnly />
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" id="inputDateModified" ref={this.inputDateModified} readOnly />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div>
                                <p id="msgErrorRecordServidor" style={{ color: 'red', display: 'none' }}>* Error, el servidor no ha podido procesar los datos</p>
                                <p id="msgErrorRecordNoValido" style={{ color: 'red', display: 'none' }}>* Error, algunos campos están vacios, compruebe los datos</p>
                            </div>
                            <div>
                                <button type="button" id="btn-deleteMVL" class="btn btn-danger" onClick={this.props.deleteRecord}>Eliminar</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="button" class="btn btn-primary" id="btnModifierRecord" onClick={this.props.modifiedRecord}>Guardar cambios</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalRecord;
