import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class TablaIncidencias extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div class="table-wrapper" id="tableDepartamento">
                <table class="table">
                    <thead class="table-dark">
                        <tr>
                            <th>ID</th>
                            <th>Usuario</th>
                            <th>Fecha</th>
                            <th>Nombre</th>
                            <th>Estado</th>
                            <th>Prioridad</th>
                            <th>Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.param.map(item => (
                            <tr onDoubleClick={this.props.modifyIncidencia}>
                                <th style={{ width: '10%' }}>{item.id}</th>
                                <th style={{ width: '15%' }}>{this.props.users[item.iduser]}</th>
                                <th style={{ width: '15%' }}>{item.date_create}</th>
                                <td style={{ width: '15%' }}>{item.nombre}</td>
                                <td style={{ width: '15%' }}>{item.incidenceStatus}</td>
                                <td style={{ width: '15%' }}>{item.incidencePriority}</td>
                                <td style={{ width: '15%' }}>{item.incidenceType}</td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TablaIncidencias;
