import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css'

class ModalDepartamento extends Component {

    constructor(props) {
        super(props);

        this.nombreDepartamento = React.createRef()
    }
    render() {
        return (
            <div class="modal fade" id="modalFormCreateDepartamento" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Creando Nuevo departamento</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <input type="text" class="form-control" id="id_departamento" readOnly hidden />
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-sm-3 col-form-label">Nombre del departamento</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="colFormLabel" id="nameNewDepartamento" ref={this.nombreDepartamento} />
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div>
                                    <p id="msgErrorNombreRepetido" style={{ color: 'red', display: 'none' }}>* Error, Este departamento ya existe</p>
                                    <p id="msgErrorNombreDepartamento" style={{ color: 'red', display: 'none' }}>* Error, el nombre del departamento no puede estar vacío</p>
                                    <p id="msgErrorServidorDepartamento" style={{ color: 'red', display: 'none' }}>* Error, No se ha podido crear el departamento</p>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-danger" id="botonBorrarDepartamento" data-dismiss="modal" onClick={this.props.deleteDepartamento} style={{ display: 'none' }}>Borrar</button>
                                    <button type="button" class="btn btn-secundary" data-dismiss="modal">Cerrar</button>
                                    <button type="button" class="btn btn-primary" id="botonCrearDepartamento" onClick={this.props.valideAndCreateNewDepartamento}>Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalDepartamento;
