import React, { Component } from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';
import ModalDepartamento from "./modalesForm/ModalDepartamento";
import '../../css/DepartamentosAdmin.css';
import TablaDepartamentos from "./tablasForm/TablaDepartamentos";


class DepartamentosAdmin extends Component {

    constructor(props) {
        super(props);

        this.createDepartamentos = this.createDepartamentos.bind(this)
        this.valideAndCreateNewDepartamento = this.valideAndCreateNewDepartamento.bind(this)
        this.getAllDepartamentos = this.getAllDepartamentos.bind(this)
        this.modifyDepartamento = this.modifyDepartamento.bind(this)
        this.deleteDepartamento = this.deleteDepartamento.bind(this)
        this.renderizarDepartamentos = this.renderizarDepartamentos.bind(this)
    }

    componentWillUnmount() {

    }


    modifyDepartamento(item) {
        document.getElementById("botonCrearDepartamento").disabled = false

        this.renderizarDepartamentos()


        document.getElementById("nameNewDepartamento").value = item.nombre
        document.getElementById("id_departamento").value = item.id
        $('#modalFormCreateDepartamento').modal('show')

        document.getElementById("botonBorrarDepartamento").style.display = "block"

    }

    deleteDepartamento() {

        this.subsciptionGU_DD = this.props.stompClient.subscribe('/user/queue/deleteDepartamentoSubscribe', (msg) => {
            this.subsciptionGU_DD.unsubscribe("subsciptionGU_GAD")

            if (JSON.parse(msg.body).statusCodeValue == 201) {
                this.getAllDepartamentos()
            }

        }, { id: "subsciptionGU_DD" });
        this.props.stompClient.send("/app/deleteDepartamento", {}, JSON.stringify({
            "id": document.getElementById("id_departamento").value,
        }));


    }

    renderizarDepartamentos() {
        document.getElementById("msgErrorNombreRepetido").style.display = "none"
        document.getElementById("msgErrorNombreDepartamento").style.display = "none"
        document.getElementById("msgErrorServidorDepartamento").style.display = "none"
    }

    componentDidMount() {

        document.getElementById("cargarHistorial").style.backgroundColor = "inherit"
        document.getElementById("cargarDepartamentos").style.backgroundColor = "#2F6D6C"
        document.getElementById("cargarDepartamentos").style.borderRadius = "22px"
        document.getElementById("cargarDepartamentos").style.paddingLeft = "1%"
        document.getElementById("cargarDepartamentos").style.paddingRight = "1%"
        document.getElementById("cargarFestivos").style.backgroundColor = "inherit"
        document.getElementById("cargarPersonal").style.backgroundColor = "inherit"
        document.getElementById("cargarIncidencias").style.backgroundColor = "inherit"



        ReactDOM.render(
            <ModalDepartamento valideAndCreateNewDepartamento={this.valideAndCreateNewDepartamento} deleteDepartamento={this.deleteDepartamento} />,
            document.getElementById("modalDepartamento")
        )

        this.getAllDepartamentos()
    }
    componentWillUnmount() {
    }

    getAllDepartamentos() {
        this.subsciptionGU_GAD = this.props.stompClient.subscribe('/user/queue/getAllDepartmentsSubscribe', (msg) => {
            this.subsciptionGU_GAD.unsubscribe("subsciptionGU_GAD")

            if (msg.body != null || msg.body != undefined) {
                this.listaDepartamentos = JSON.parse(msg.body)
                this.componentWillUnmount(document.getElementById('tableDepartamentos'))
                ReactDOM.render(
                    <TablaDepartamentos param={this.listaDepartamentos} modifyDepartamento={this.modifyDepartamento} />,
                    document.getElementById('tableDepartamentos')
                );
            }

        }, { id: "subsciptionGU_GAD" });
        this.props.stompClient.send("/app/getAllDepartments", {}, JSON.stringify({
            "id": this.props.adminInfo.id,
        }));

    }

    createDepartamentos() {
        document.getElementById("botonCrearDepartamento").disabled = false
        this.renderizarDepartamentos()
        $('#modalFormCreateDepartamento').modal('show')
        document.getElementById("nameNewDepartamento").value = ""
        document.getElementById("id_departamento").value = ""
        document.getElementById('botonBorrarDepartamento').style.display = "none"
    }

    valideAndCreateNewDepartamento() {

        document.getElementById("botonCrearDepartamento").disabled = true

        if (document.getElementById("id_departamento").value != "") {
            if (document.getElementById("nameNewDepartamento").value == "") {
                document.getElementById("botonCrearDepartamento").disabled = false
                document.getElementById('msgErrorNombreDepartamento').style.display = "block"
            } else {
                this.subsciptionUNC_MD = this.props.stompClient.subscribe('/user/queue/modifyDepartamentoSubscribe', (msg) => {
                    this.subsciptionUNC_MD.unsubscribe("subsciptionUNC_CND")

                    if (msg.body != "null") {
                        if (JSON.parse(msg.body).statusCodeValue == 500) {
                            document.getElementById("botonCrearDepartamento").disabled = false
                            document.getElementById('msgErrorNombreRepetido').style.display = "none"
                            document.getElementById('msgErrorNombreDepartamento').style.display = "none"
                            document.getElementById('msgErrorServidorDepartamento').style.display = "block"
                        }
                        else if (JSON.parse(msg.body).statusCodeValue == 417) {
                            document.getElementById("botonCrearDepartamento").disabled = false
                            document.getElementById('msgErrorNombreRepetido').style.display = "block"
                            document.getElementById('msgErrorNombreDepartamento').style.display = "none"
                            document.getElementById('msgErrorServidorDepartamento').style.display = "none"

                        }
                        else if (JSON.parse(msg.body).statusCodeValue == 201) {
                            $('#modalFormCreateDepartamento').modal('toggle');
                            this.getAllDepartamentos()
                        }
                    } else {
                        document.getElementById("botonCrearDepartamento").disabled = false
                        document.getElementById('msgErrorNombreRepetido').style.display = "none"
                        document.getElementById('msgErrorNombreDepartamento').style.display = "block"
                        document.getElementById('msgErrorServidorDepartamento').style.display = "none"
                    }

                }, { id: "subsciptionUNC_MD" });
                this.props.stompClient.send("/app/modifyDepartamento", {}, JSON.stringify({
                    "id": document.getElementById("id_departamento").value,
                    "nombre": document.getElementById('nameNewDepartamento').value,
                }));
            }
        } else {

            if (document.getElementById('nameNewDepartamento').value == "") {
                document.getElementById("botonCrearDepartamento").disabled = false
                document.getElementById('msgErrorNombreDepartamento').style.display = "block"
                document.getElementById('msgErrorNombreRepetido').style.display = "none"
            }
            else {

                this.subsciptionUNC_CND = this.props.stompClient.subscribe('/user/queue/createNewDepartamentoByNameUser', (msg) => {
                    this.subsciptionUNC_CND.unsubscribe("subsciptionUNC_CND")


                    if (msg.body != "null") {

                        if (JSON.parse(msg.body).statusCodeValue == 500) {
                            document.getElementById("botonCrearDepartamento").disabled = false
                            document.getElementById('msgErrorNombreRepetido').style.display = "none"
                            document.getElementById('msgErrorNombreDepartamento').style.display = "none"
                            document.getElementById('msgErrorServidorDepartamento').style.display = "block"
                        }
                        else if (JSON.parse(msg.body).statusCodeValue == 417) {
                            document.getElementById("botonCrearDepartamento").disabled = false
                            document.getElementById('msgErrorNombreRepetido').style.display = "block"
                            document.getElementById('msgErrorNombreDepartamento').style.display = "none"
                            document.getElementById('msgErrorServidorDepartamento').style.display = "none"

                        }
                        else if (JSON.parse(msg.body).statusCodeValue == 201) {
                            $('#modalFormCreateDepartamento').modal('toggle');
                            this.getAllDepartamentos()
                        }
                    } else {
                        document.getElementById("botonCrearDepartamento").disabled = false

                        document.getElementById('msgErrorNombreRepetido').style.display = "none"
                        document.getElementById('msgErrorNombreDepartamento').style.display = "block"
                    }


                }, { id: "subsciptionUNC_CND" });
                this.props.stompClient.send("/app/createNewDepartamentoByName", {}, JSON.stringify({
                    "nombre": document.getElementById('nameNewDepartamento').value,
                }));
            }

        }

    }

    render() {
        return (
            <div>
                <h1 id="divDepartamentos">Departamentos</h1>
                <div class="form-group row" id="searchDepartamentosAdmin">
                    <div className="col-sm-3 form-group row">
                        <button class="btn-success btn-Form" onClick={this.createDepartamentos}>Alta</button>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div id="tableDepartamentos">

                </div>
                <div id="modalDepartamento">

                </div>

            </div>
        );
    }
}

export default DepartamentosAdmin;
