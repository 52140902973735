import React, { Component } from "react";
import '../../../css/formPersonal/DireccionUser.css';

class ModalVacaciones extends Component {

    constructor(props) {
        super(props);
        this.user = this.props.user
        this.makeJSON = this.makeJSON.bind(this);


        this.inputDateGo = React.createRef();
        this.inputDateBack = React.createRef();
        this.inputReason = React.createRef();
        this.inputDescription = React.createRef();
        this.inputValueObservacion = React.createRef();
        this.idRecord = React.createRef();

    }

    componentDidMount() {
        this.props.doJson(this.makeJSON)
    }

    makeJSON() {
        if (this.inputDateGo.current.value != "" && this.inputDateBack.current.value != "") {

            return this.contactInfo = {
                "id": null,
                "iduser": this.user.id,
                "dateFirstDay": this.inputDateGo.current.value,
                "dateLastDay": this.inputDateBack.current.value,
                "reason": this.inputReason.current.value,
                "description": this.inputDescription.current.value,
                "id_user_creator": null,
                "id_user_modifier": null,

            }
        } else {
            return this.error = {
                "error": "Te has dejado algun campo vacio, para realizar la tarea deben estar completos todos los campos. Vuelve a intentarlo"
            }
        }
    }


    render() {
        return (
            <div class="modal fade" id="modalFormVacaciones" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Formulario vacaciones</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <input value="" id="idUserRecord" ref={this.idUserRecord} hidden readOnly />
                            <input value="" id="idVacacion" ref={this.idRecord} hidden readOnly />
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Día de salida</label>
                                <div class="col-8">
                                    <input class="form-control" type="date" id="inputDateGo" ref={this.inputDateGo} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Día de reincorporación</label>
                                <div class="col-8">
                                    <input class="form-control" type="date" id="inputDateBack" ref={this.inputDateBack} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="colFormLabel" class="col-4 col-form-label">Motivo</label>
                                <div class="col-8">
                                    <select id="inputState inputReason" class="form-control" ref={this.inputReason}>
                                        <option value="Vacaciones">Vacaciones</option>
                                        <option value="Baja Laboral">Baja Laboral</option>
                                        <option value="Absencia programada">Absencia programada</option>
                                        <option value="Jordada interrumpida">Jornada interrumpida</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Descripción</label>
                                <div class="col-8">
                                    <input class="form-control" type="text" id="inputDescription" placeholder="Primera Quincena" ref={this.inputDescription} />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-time-input" class="col-4 col-form-label">Ultima Modificación</label>
                                <div class="col-4">
                                    <input class="form-control" type="text" id="inputAdminModifier" ref={this.inputAdminModifier} readOnly />
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" id="inputDateModified" ref={this.inputDateModified} readOnly />
                                </div>
                            </div>

                            <div id="tableVacacionesSon">

                            </div>

                        </div>
                        <div class="modal-footer">
                            <div>
                                <p id="msgErrorVacaciones" style={{ color: 'red', display: 'none' }}>* Error, algunos campos estan vacíos, compruebe los datos</p>
                                <p id="msgErrorServidorVacaciones" style={{ color: 'red', display: 'none' }}>* Error, no se han podido crear las vacaciones</p>
                            </div>
                            <div>
                                <button type="button" id="btn-deleteMVL" class="btn btn-danger" data-dismiss="modal" onClick={this.props.deleteVacaciones}>Eliminar</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="button" class="btn btn-primary" id="botonGuardarVacaciones" onClick={this.props.saveNew}>Guardar cambios</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalVacaciones;
